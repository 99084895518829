import React, { useState } from "react";
import axiosInstance from '../hnh/service/axiosInstance';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const PostData = async (e) => {
    e.preventDefault();

    if (!email || !phone || !password || !confirmPassword) {
      toast.error('Please fill in all fields.');
      return;
    }
    if (!/^\d{10}$/.test(phone)) {
      setPhoneError('Phone number should be 10 numeric characters long.');
      return;
    }
    if (!validatePassword(password)) {
        setPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.');
        return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    }


    try {
      const response = await axiosInstance.post('/api/change-password', {
        email,
        phone,
        password,
      });

      toast.success(response.data.message);

      setEmail('');
      setPhone('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    }
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|~\-={}[\]:;"'<>,.?\\/])(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="card shadow-lg border border-" style={{ width: '100%', maxWidth: '500px' }}>
        <div className="card-header text-center text-white py-4" style={{ background: 'linear-gradient(135deg, red, black)' }}>
          <h2>WELCOME</h2>
          <h2 className="d-">TO</h2>
          <h2 className="fw-bold">H&H Infotech</h2>
        </div>
        <div className="card-body">
          <h4 className="text-center my-4 fw-bold">Change Password</h4>
          <form onSubmit={PostData}>
            <div className="my-4 pt-4">
              <div className="input-group mb-2">
                <span className="input-group-text bg-white border-end-0"><i className="bi bi-person-fill"></i></span>
                <input
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="my-4">
              <div className="input-group mb-2">
                <span className="input-group-text bg-white border-end-0"><i className="bi bi-phone-fill"></i></span>
                <input
                  name="phone"
                  placeholder="Phone Number"
                  className={phoneError ? "form-control is-invalid" : "form-control"}
                  type="text"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setPhoneError('')
                  }}
                />
                {phoneError && <div className="text-danger">{phoneError}</div>}
              </div>
            </div>
            <div className="my-4">
              <div className="input-group mb-2">
                <span className="input-group-text bg-white border-end-0"><i className="bi bi-lock-fill"></i></span>
                <input
                  name="password"
                  placeholder="Password"
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError('')
                  }}
                />
              </div>
              {passwordError && <span className="text-danger">{passwordError}</span>}

            </div>
            <div className="mb-4">
              <div className="input-group mb-2">
                <span className="input-group-text bg-white border-end-0"><i className="bi bi-lock-fill"></i></span>
                <input
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className="form-control"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                    setConfirmPasswordError('')
                  }}
                />
              </div>
                {confirmPasswordError && <span className="text-danger">{confirmPasswordError}</span>}
            </div>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <input
                className="m-2"
                type="checkbox"
                value={showPassword}
                onChange={() => setShowPassword(prev => !prev)}
              />
              <label htmlFor="check">Show Password</label>
            </div>
            <div className="d-grid">
              <button
                type="submit"
                className="btn-button mt-3 text-center"
              >Change Password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
