import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../service/axiosInstance';
import { ExitDetailsForm } from './ExitDetailsForm';
import ExitDetailsTable from './ExitDetailsTable';

const MainComponentExit = ({ infoData }) => {
  const [FormShow, setFormShow] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const token = localStorage.getItem('jwt');
  const headers = useMemo(() => ({
    Authorization: `Bearer ${token}`
  }), [token]);
  // const { id } = useParams();

  const [resignations, setResignations] = useState([]);

  const fetchResignations = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/exit-details`, { headers });
      setResignations(response.data);
    } catch (error) {
      console.error('Error fetching resignations:', error);
    }
  },[headers]);
  
  useEffect(() => {
    fetchResignations();
  }, [fetchResignations]);

  const deleteResignation = async (value) => {
    try {
      await axiosInstance.delete(`/api/exit-details/${value}`, { headers });
      toast.success('Resignation deleted successfully');
      fetchResignations();
    } catch (error) {
      console.error('Error deleting resignation:', error);
      toast.error('Error deleting resignation');
    }
  };

  const viewResignation = (id) => {
    const resignation = resignations.find((item) => item._id === id);
    setFormData(resignation);
    setIsEdit(false);
    setFormShow(true);
  };

  const editResignation = (id) => {
    const resignation = resignations.find((item) => item._id === id);
    setFormData(resignation);
    setIsEdit(true);
    setFormShow(true);
  };

  const handleFormSubmit = () => {
    setFormShow(false);
    setFormData(null);
    setIsEdit(false);
    fetchResignations();
  };

  const handleFormOpen = () => {
    setIsEdit(false);
    setFormData(null)
    setFormShow(true)
  }

  return (
    <div className="shadow mt-4 p-3">
      <ToastContainer />
      <div className="d-flex justify-content-between">
        <h3>Exit Interview</h3>
        {!FormShow && 
          <button className='btn btn-light border' onClick={handleFormOpen}>+Form</button>
        }
        {FormShow && 
          <button className='btn btn-danger border' onClick={() => setFormShow(false)}>X</button>
        }
      </div>
      {FormShow && 
        <ExitDetailsForm
          headers={headers}
          infoData={infoData}
          formData={formData}
          isEdit={isEdit}
          onSubmit={handleFormSubmit}
        />
      }
      {!FormShow && (
        <>
          <ExitDetailsTable
            infoData={infoData}
            headers={headers}
            resignations={resignations}
            onView={viewResignation}
            onEdit={editResignation}
            onDelete={deleteResignation}
          />
        </>
      )}
    </div>
  );
};

export default MainComponentExit;
