import React from 'react';
import { FaDownload, FaEdit, FaTrash, FaFilePdf, FaFileWord, FaFileExcel, FaFileImage, FaFileAlt } from 'react-icons/fa'; // Import necessary icons
import BASE_URL from '../../service/config';

const getFileIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return <FaFilePdf className="me-2 text-danger" />;
    case 'doc':
    case 'docx':
      return <FaFileWord className="me-2 text-primary" />;
    case 'xls':
    case 'xlsx':
      return <FaFileExcel className="me-2 text-success" />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return <FaFileImage className="me-2 text-warning" />;
    default:
      return <FaFileAlt className="me-2" />;
  }
};

const FilesTable = ({ onEdit, onDelete, files }) => {

  const handleEdit = (file) => {
    onEdit(file);
  };

  const handleDelete = async (id) => {
    onDelete(id);
  };

  return (
    <div className="table-responsive">
      <h2 className="text-xl fw-semibold mb-4">Files List</h2>
      {files.length === 0 ? (
        <p className='alert alert-info' >No files found.</p>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">File Name</th>
              <th scope="col">Description</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {files.map(file => (
              <tr key={file._id}>
                <td className='text-nowrap' >
                  {getFileIcon(file.fileName)}
                  {file.fileName.split('-').slice(1).join('-')}
                </td>
                <td>{file.description}</td>
                <td className='text-nowrap' >
                  <button className="btn btn-outline-primary btn-sm me-2" onClick={() => handleEdit(file)}>
                    <FaEdit />
                  </button>
                  <a 
                    className="btn btn-success me-2" 
                    href={`${BASE_URL}/uploads/${file.fileName}`} 
                    download={file.fileName}
                    target="_blank"
                  >
                    <FaDownload />
                  </a>
                  <button className="btn btn-outline-danger btn-sm" onClick={() => handleDelete(file._id)}>
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FilesTable;
