import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ServiceCard from './ServiceCard';
import { Link } from 'react-router-dom';
import image from '../assets/img/question.png'

export const HomePage = () => {
  useEffect(() => {
    AOS.init({
      duration: 500, 
      easing: 'ease', 
    });
  }, []);
  useEffect(() => {
    document.title = 'H&H';
  }, []);
  return (
    <>
      <section id="hero">
        <div className="hero-container" >
        <h1 className='mb-4' data-aos="fade-down" data-aos-once="true" style={{fontSize:"4em" ,fontWeight: "700",lineHeight: "1"}}>WE BUILD DIGITAL EXPERIENCES</h1>
            <div className="mt-5" data-aos="fade-up">
              <h4 className='mb-3 text-white'>We Specialize in developing large scale, distributed realtime systems</h4>
              <h4 className='mb-3 text-white'>We Expertise in writing great looking, easy to use trading applications</h4>
              <h4 className='mb-3 text-white'>We provide commercial support and custom development for capital markets</h4>
              <Link to="/our-service" className="btn-get-started scrollto mt-5">See Services</Link>
            </div>
        </div>
      </section>
      <main id="main">
      <section>
        <div className="section-title my-4">
          <h3>Why choose <span>H&H</span><img src={image} width="30px" className='pb-1' alt="" /></h3>
        </div>
        <ServiceCard/>
      </section>
    </main>
  </>
  )
}
