import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image from '../assets/img/exclaimblack.png'
import axios from "axios";

export const Contact = () => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    document.title = 'H&H - Contact';
  }, []);

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const userEmail = form.current.email.value.trim();
    if (!emailRegex.test(userEmail)) {
      toast.error("Invalid email format");
      return;
    }
  
    const formData = {
      name: form.current.name.value,
      email: form.current.email.value,
      subject: form.current.subject.value,
      message: form.current.message.value
    };
    setSending(true);

    axios.post("http://13.234.136.33:5000/send", formData)
      .then((response) => {
        if (response.data.status === 'success'){
          toast.success("Message Sent Successfully."); 
          form.current.reset();
        } else if (response.data.status === 'fail'){
          toast.error("Message failed to send.");
        }
      })
      .catch(error => {
        console.error('Error sending message:', error);
        toast.error("An error occurred while sending the message.");
      })
      .finally(() => {
        setSending(false);
      });
  };
  
  return (
    <>
    <div className="parallax contact-img">
      <div className="parallax-overlay"></div>
      <div className=''>
          <h1 className="">CONTACT</h1>
      </div>
    </div>
      <section id="contact" className="contact">
        <div className="container">
          <div
            className="section-title"
            data-aos-once="true"
            data-aos="zoom-in"
          >
            {/* <h2>Contact</h2> */}
            <h3>
            Let's Start Building <span>Future Together<img src={image} width="30px" className='pb-1' alt="" /></span>
            </h3>
            <p style={{fontSize:"19px"}}>
              Whether you're looking to streamline your business operations, 
              enhance your online presence, or drive innovation through technology, 
              H&H Infotech and Consultancy Pvt Ltd is here to help. Contact us today to learn more 
              about our services and how we can help you achieve your business goals. 
              Let's embark on this journey together and unlock the full potential of your business!
            </p>
          </div>

          <div className="row mt-lg-5 mb-5">
            <div className="col-lg-6 mt-5 mt-lg-0">
              {!mapLoaded && (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                  <div className="spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              <iframe 
                style={{ border: "0", width: "100%", height: "400px", display: mapLoaded ? 'block' : 'none' }}
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=suncity%20trade%20tower+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              title="map"
              allowFullScreen
              onLoad={handleMapLoad}
              >
              </iframe>
            </div>

            <div
              className="col-lg-6 mt-5 mt-lg-0"
              data-aos-once="true"
              data-aos="fade-left"
            >
              <form ref={form} onSubmit={sendEmail} className="php-email-form" encType="multipart/form-data">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                </div>
                <div className="text-center">
                  <button className="btn-button" type="submit" disabled={sending}>
                    {sending ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Sending...
                      </>
                    ) : (
                      'Send Message'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
