import React, { useCallback, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axiosInstance from '../service/axiosInstance';

const AttendanceCalendar = () => {
  const [leaveEvents, setLeaveEvents] = useState([]);
  const fetchLeaveHistory = useCallback(() => {
    const urlID = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('jwt');
    const headers = {
      Authorization: `Bearer ${token}`
    };

    const textFormat = (leaveType) => {
      let leaveText = '';
      switch (leaveType) {
        case "casualLeave":
          leaveText = "Casual Leave";
          break;
        case "sickLeave":
          leaveText = "Sick Leave";
          break;
        default:
          break;
      }
      return leaveText || leaveType;

    }
    axiosInstance.get(`/getleave/${urlID._id}`, { headers })
      .then(response => {
        const leaveData = response.data.applications.map(application => ({
          title: textFormat(application.leaveType),
          start: application.startDate,
          end: application.endDate,
          allDay: true,
          backgroundColor: application.status === 'Rejected' ? 'red' : (application.status === 'Pending' ? '' : 'green')
        }));
        setLeaveEvents(leaveData);
      })
      .catch(error => console.error('Error fetching leave history:', error));
  }, []);

  useEffect(() => {
    fetchLeaveHistory();
  }, [fetchLeaveHistory]);

  return (
    <div>
      <div className=" my-5 d-flex justify-content-center">
        <div className="col-md-6">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={leaveEvents}
            height="600px" 
            aspectRatio={1.5}
          />
        </div>
      </div>
    </div>
  );
};

export default AttendanceCalendar;
