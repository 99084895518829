import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../service/axiosInstance';

const RegularizationRequestAdmin = ({onUpdateData}) => {
  const [requests, setRequests] = useState([]);

  
  const token = localStorage.getItem('jwt');
  const headers = useMemo(()=>({
    Authorization: `Bearer ${token}`
  }),[token]);
  
  const fetchRequests = useCallback(() => {
    axiosInstance.get('/api/api/emp-attendance/regularization-requests', { headers })
      .then(response => {
        setRequests(response.data);
      })
      .catch(error => {
        console.error('Error fetching regularization requests:', error);
      });
  },[headers]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  const handleApprove = (requestId) => {
    axiosInstance.put(`/api/emp-attendance/regularization-requests/approve/${requestId}`, { status:"approved"}, { headers })
      .then(() => {
        fetchRequests();
        onUpdateData('approved')
      })
      .catch(error => {
        console.error('Error approving regularization request:', error);
      });
  };

  const handleReject = (requestId) => {
    axiosInstance.put(`/api/emp-attendance/regularization-requests/reject/${requestId}`, { status:"rejected"}, { headers })
      .then(() => {
        fetchRequests();
        onUpdateData('rejected')
      })
      .catch(error => {
        console.error('Error rejecting regularization request:', error);
      });
  };

  return (
    <div className="mt-4">
    <h6 className="bg-dark m-0 p-3  rounded-top text-white">Regularization Requests</h6>
        <div className="table-responsive">
            <table className="table table-striped">
            <thead>
                <tr>
                <th scope="col">Employee</th>
                <th scope="col">Date</th>
                <th scope="col">CheckIn</th>
                <th scope="col">CheckOut</th>
                <th scope="col">Reason</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                {requests.map(request => (
                <tr key={request._id}>
                    <td>{request.employeeId.name}</td>
                    <td>{new Date(request.date).toLocaleDateString()}</td>
                    <td>{request.checkIn ? new Date(request.checkIn).toLocaleTimeString() : '-'}</td>
                    <td>{request.checkOut ? new Date(request.checkOut).toLocaleTimeString() : '-'}</td>
                    <td>{request.reason}</td>
                    <td>{request.adminApproval}</td>
                    <td>
                    {request.adminApproval === "pending" ? (
                        <>
                        <div className="d-flex">
                            <button className="btn btn-success me-2" onClick={() => handleApprove(request._id)} title="Approve" data-bs-toggle="tooltip" data-bs-placement="top">
                                <i className="bi bi-check"></i>
                            </button>
                            <button className="btn btn-danger" onClick={() => handleReject(request._id)} title="Reject" data-bs-toggle="tooltip" data-bs-placement="top">
                                <i className="bi bi-x"></i>
                            </button>
                        </div>
                        </>

                    ) : (
                        <span className="badge bg-secondary">{request.adminApproval}</span>
                    )}
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>
    </div>


  );
};

export default RegularizationRequestAdmin;
