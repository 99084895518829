import React, { useEffect, useState } from 'react';
import ProjectsSteps from './ProjectSteps';

const FrontEnd = () => (
    <div className="content-box">
      <h2 className='mb-4 fs-1'>Front-end</h2>
      <div className='content-flex-dynamic'>
        <ServiceBox
          title="React"
          description="React, Redux, Redux-Saga, RxJS, React-Native, Three.JS, NextJS"
          img="img/reactJs.png"
        />
        <ServiceBox
          title="JavaScript"
          description="Material, Ant Design, Apollo GraphQL, Protractor, Jasmine, Jest, Enzyme, Mocha, Electron, SSR, Lodash, Date-FNS, CSS, SASS/SCSS, LESS, Lerna, TypeScript"
          img="img/javascript.png"
        />
        <ServiceBox
          title="Angular"
          description="Angular, NgRx, NGXS, MobX, Formly, Ionic"
          img="img/angularLogo.png"
          // img="https://assets-global.website-files.com/6276a3f2ca098b5403ee35b7/627cbd6a96eb43ca400b3ecf_angular-2.gif"
        />
        <ServiceBox
          title="Ruby On Rails"
          description="Rails Engines, Microservices, SPA with rails"
          img="img/railsRed.png"
        />
      </div>
    </div>
  );
  

const BackEnd = () => (
    <div className="content-box">
      <h2 className='mb-4 fs-1'>Back-end</h2>
      <div className='content-flex-dynamic'>
        <ServiceBox
          title="Ruby"
          description="Ruby on Rails, Sinatra, Hanami, Padrino, Volt, EventMachine, Rack, Sidekiq, GraphQL, Web Socket, Swagger, JWT."
          img="img/Ruby.png"
        />
        <ServiceBox
          title="Golang"
          description="Go, gORM, Gin, protobuf, gRPC, AWS, GCP, Docker, Redis, Mongo DB, PostgreSQL"
          img="img/goLang.png"
        />
        <ServiceBox
          title="Node.js"
          description="NestJS, Koa, MongoDB, PostgreSQL, AWS"
          img="img/node.png"
        />
        <ServiceBox
          title=".Net"
          description=".NET Core, ASP.NET Core MVC, ASP.NET Web API, ASP.NET MVC, Xamarin.Forms, WPF, UWP, Stateless Architecture, Microservices, Azure Cloud"
          img="img/net.png"
        />
        <ServiceBox
          title="PHP"
          description="DUKPT, Laravel, Symfony, Yii2, WordPress, Drupal, MySQL, MongoDB, PostgreSQL, REST/SOAP/GraphQL API, OAuth, HTML, Twig, Swagger, Redis, ElasticSearch, Docker, Stripe, PayPal"
          img="img/php.jpg"
        />
      </div>
    </div>
  );

  const ServiceBox = ({ title, description, img }) => (
    <div
      className="responsive-container"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '5px',
        borderBottom: '2px solid #ccc',
        paddingBottom: '5px',
        width: 'calc(45% - 10px)',
        marginRight: '20px',
      }}
    >
      <div style={{ flex: '1', marginRight: '30px' }}> 
        <img src={img} alt="..." style={{ width: '100%', height: 'auto' }} />
      </div>
      <div style={{ flex: '1 1 60%' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h3 className='fs-3'>{title}</h3>
          <p className='fs-6'>{description}</p>
        </div>
      </div>
    </div>
  );
  
  
  const Platforms = () => (
    <div className="content-box">
      <h2 className='mb-4 fs-1'>Platforms</h2>
      <div className='content-flex-dynamic'>
        <ServiceBox
          title="AWS"
          description="Amazon Elastic Compute Cloud (EC2), Amazon Simple Storage Service (S3), Amazon Elastic Container Service (Amazon ECS), DynamoDB, Relational Database Service (RDS), AWS Lambda, ElastiCache, CloudSearch"
          img="img/aws.png"
        />
        <ServiceBox
          title="Azure"
          description="Azure Virtual Machines, Azure Web Apps, Azure Functions, Azure Blob Storage, Azure Notification Hub, Azure AI, Azure IoT Hub, Azure Event Hub, Azure Tables, Azure Queue, Azure DevOps Pipelines, Azure Content Delivery Network, Azure Application Insights, Azure Kubernetes, Azure SQL"
          img="img/azure.png"
        />
        <ServiceBox
          title="GCP"
          description="We build high-level solution architectures to be a basement of digital Google Compute Engine, Google App Engine, Google Kubernetes Engine, Google Cloud Functions, Google Cloud SQL, Google Cloud Storage, Google Cloud Datastore, Google Cloud Bigtable"
          img="img/gcp.png"
        />
      </div>
    </div>
  );

  const PeopleOperations = () => (
    <div className="content-box">
      <h2 className='mb-4 fs-1'>People Operations</h2>
      <div className='content-flex-dynamic'>
        <ServiceBox
          title="Asana"
          description="Project Management"
          img="img/asana.svg"
        />
        <ServiceBox
          title="Slack"
          description="Real-Time Collaboration"
          img="img/slack.png"
        />
        <ServiceBox
          title="Github"
          description="Git Repository"
          img="img/pngwing.png"
        />
        <ServiceBox
          title="Google Meet"
          description="Meetings and Conferencing"
          img="img/meet.png"
        />
      </div>
    </div>
  );

const TechnoTab = () => {
  const [activeTab, setActiveTab] = useState('front-end');

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    document.title = 'H&H - Technology';
  }, []);

  return (
    <>
    <div className="parallax techno-img" >
      <div className="parallax-overlay"></div>
      <div className=''>
          <h1 className="">OUR EXPERTISE</h1>
      </div>
    </div>
    <section id="team">
      <div className="section-title" data-aos-once="true" data-aos="zoom-in">
        {/* <h2>Our Expertise</h2> */}
        <h3>Tools & Tech Stack</h3>
      </div>
      {/* <TechnoTab/> */}
    <div className="tabs-container">
      <div className="tabs-sidebar">
        <div className="tab-sideBorder">
            <div className={`cursor-pointer mt- ${activeTab === 'front-end' ? "active" : ""}`} onClick={() => handleTabChange('front-end')}>
            Front-end
            </div>
            <div className={`cursor-pointer mt- ${activeTab === 'back-end' ? "active" : ""}`} onClick={() => handleTabChange('back-end')}>
            Back-end
            </div>
            <div className={`cursor-pointer mt- ${activeTab === 'platforms' ? "active" : ""}`} onClick={() => handleTabChange('platforms')}>
            Platforms
            </div>
            <div className={`cursor-pointer mt- ${activeTab === 'peopleOperation' ? "active" : ""}`} onClick={() => handleTabChange('peopleOperation')}>
            People Operation
            </div>
        </div>
      </div>

      <div className="tabs-content mb-0 pb-0">
          {activeTab === 'front-end' && <FrontEnd />}
          {activeTab === 'back-end' && <BackEnd />}
          {activeTab === 'platforms' && <Platforms />}
          {activeTab === 'peopleOperation' && <PeopleOperations />}
      </div>
    </div>
    </section>
    <section id="" className="services section-with-title-padding">
    <div className="parallax techno-img" >
      <div className="parallax-overlay"></div>
      <div className=''>
          <h1 className="">OUR EXECUTION PROCESS</h1>
      </div>
    </div>
    <div className="section-title" data-aos-once="true" data-aos="zoom-in">
        </div>
      <ProjectsSteps/>
    </section>
    </>

  );
};

export default TechnoTab;
