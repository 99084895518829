import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../service/axiosInstance';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    // margin: 10,
    // padding: 10,
    flexGrow: 1
  },
  table: { width: '100%', marginBottom: 20 },
  tableRow: { flexDirection: 'row' },
  tableCell: { 
    padding: 5, 
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    fontSize: 12 
  }, 
});

const AdminAttendance = ({ employeesData }) => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const [checkInDate, setCheckInDate] = useState(null);
  const token = localStorage.getItem('jwt');
  const headers = useMemo(()=>({
    Authorization: `Bearer ${token}`
  }),[token]);

  
  const fetchAttendanceByEmployeeAndDate = useCallback( async (employeeId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/all-emp-attendance`, {
        params: {
          employeeId,
          startDate,
          endDate,
          checkInDate,
          // isRegularized: false,
          adminApproval: "approved"
        },
        headers
      });
      setAttendanceData(response.data);
    } catch (error) {
      console.error('Error fetching attendance by employee ID and date:', error);
    } finally {
      setLoading(false);
    }
  },[checkInDate, endDate, headers, startDate]);
  
  useEffect(() => {
    if (employeesData && employeesData.length > 0) {
      fetchAttendanceByEmployeeAndDate(employeesData[0]._id);
    }
  }, [employeesData, fetchAttendanceByEmployeeAndDate]);

  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
    // fetchAttendanceByEmployeeAndDate(event.target.value);
  };
  
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setError('');
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setError('');
  };

  const handleCheckInDateChange = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(0, 0, 0, 0);
    const isoDate = adjustedDate.toISOString();
    setCheckInDate(isoDate);
    setError('');
  };
  
  

  const validateFilter = () => {
    // if (!selectedEmployee) {
    //   setError('Please select the employee.');
    //   return false;
    // } else if (!startDate || !endDate) {
    //   setError('Please select both start and end dates.');
    //   return false;
    // } else if (new Date(startDate) > new Date(endDate)) {
    //   setError('End date must be after start date.');
    //   return false;
    // }
    // if (!checkInDate) {
    //   setError('Check-in date must be before end date.');
    //   return false;
    // }
    return true;
  };

  const handleFilter = () => {
    if (validateFilter()) {
      fetchAttendanceByEmployeeAndDate(selectedEmployee);
    }
  };

  const calculateTotalTimeWorked = (checkInTime, checkOutTime) => {
    if (checkInTime) {
      const now = new Date();
      const totalTimeInSeconds = Math.floor((checkOutTime ? new Date(checkOutTime) : now) - new Date(checkInTime)) / 1000;
      const hours = Math.floor(totalTimeInSeconds / 3600);
      const minutes = Math.floor((totalTimeInSeconds % 3600) / 60);
      const seconds = Math.floor(totalTimeInSeconds % 60);
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return 'N/A';
    }
  };

  const handleDownload = () => {
    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Employee Name Section */}
          <View style={styles.section}>
            {/* <Text style={{ marginBottom: 10, fontSize: 16 }}>Employee Name: {selectedEmployee.name}</Text> */}
            {/* <Text style={{ fontSize: 14 }}>Start Date: {startDate.toLocaleDateString()}</Text>
            <Text style={{ fontSize: 14 }}>End Date: {endDate.toLocaleDateString()}</Text> */}
          </View>
    
          {/* Table Section */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell, { width: '25%' }]}>
                <Text>Name</Text>
              </View>
              <View style={[styles.tableCell, { width: '25%' }]}>
                <Text>Date</Text>
              </View>
              <View style={[styles.tableCell, { width: '25%' }]}>
                <Text>Check-in Time</Text>
              </View>
              <View style={[styles.tableCell, { width: '25%' }]}>
                <Text>Check-out Time</Text>
              </View>
              <View style={[styles.tableCell, { width: '25%' }]}>
                <Text>Total Time Worked</Text>
              </View>
            </View>
            {attendanceData.map((attendance, index) => (
              <View key={index} style={styles.tableRow}>
                {/* attendance?.employeeId?.name */}
                <View style={[styles.tableCell, { width: '25%' }]}>
                  <Text>{attendance?.employeeId?.name}</Text>
                </View>
                <View style={[styles.tableCell, { width: '25%' }]}>
                  <Text>{new Date(attendance.checkIn).toLocaleDateString()}</Text>
                </View>
                <View style={[styles.tableCell, { width: '25%' }]}>
                  <Text>{attendance.checkIn ? new Date(attendance.checkIn).toLocaleTimeString() : 'N/A'}</Text>
                </View>
                <View style={[styles.tableCell, { width: '25%' }]}>
                  <Text>{attendance.checkOut ? new Date(attendance.checkOut).toLocaleTimeString() : 'N/A'}</Text>
                </View>
                <View style={[styles.tableCell, { width: '25%' }]}>
                  <Text>{calculateTotalTimeWorked(attendance.checkIn, attendance.checkOut)}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
  
  
    );
    

    // Render the PDF document for download
    const fileName = 'attendance_report.pdf';
    return (
      <PDFDownloadLink document={<MyDocument />} className='btn btn-dark border ' fileName={fileName}>
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download PDF'
        }
      </PDFDownloadLink>
    );
  };

  return (
    <div className="container mt-5">
      <div className='alert alert-danger'>
        <h4 className="mb-4">Admin Attendance</h4>
        <hr />
        <div className="container mt-5">
      <div className="row d-flex justify-content-between">
        <div className="col-lg-4 mb-3 mb-lg-0">
          <h5>Employee List</h5>
          <select className="form-select" onChange={handleEmployeeChange} value={selectedEmployee}>
            <option value="">All</option>
            {employeesData.map((employee, index) => (
              <option key={index} value={employee._id}>{employee.name}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-4 mb-3 mb-lg-0">
          <h5>Date Range Filter</h5>
          <div className="row align-items-center">
            <div className="col-lg-5 mb-3 mb-lg-0">
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                className="form-control cursor-pointer"
                id="startDate"
                placeholderText='Start Date'
                required
              />
            </div>
            <div className='col-lg-2 text-center d-none'>-</div>
            <div className="col-lg-5">
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                className="form-control cursor-pointer"
                id="endDate"
                placeholderText='End Date'
                required
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <h5>Check-in Date</h5>
          <DatePicker
            selected={checkInDate}
            onChange={handleCheckInDateChange}
            className="form-control cursor-pointer"
            id="checkInDate"
            placeholderText='Check-in Date'
            required
          />
        </div>
      </div>
    </div>

        {error && <div className="text-danger">{error}</div>}
        <div className="d-flex justify-content-between m-3">
          <button className="btn btn-danger" onClick={handleFilter}>Filter</button>
          {/* <button className="btn btn-dark mt-3"> */}
            {attendanceData.length > 0 ? handleDownload() : 'No Data to Download'}
          {/* </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Date</th>
                  <th scope="col">Check-in Time</th>
                  <th scope="col">Check-out Time</th>
                  <th scope="col">Reason</th>
                  <th scope="col">Total Time Worked</th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.map((attendance, index) => (
                  <tr key={index}>
                    <td>{attendance?.employeeId?.name}</td>
                    <td>{new Date(attendance.checkIn).toLocaleDateString()}</td>
                    <td>{attendance.checkIn ? new Date(attendance.checkIn).toLocaleTimeString() : 'N/A'}</td>
                    <td className={attendance.checkOut ? "" : "bg-danger"}>{attendance.checkOut ? new Date(attendance.checkOut).toLocaleTimeString() : 'N/A'}</td>
                    <td>{attendance.reason}</td>
                    <td>{calculateTotalTimeWorked(attendance.checkIn, attendance.checkOut)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  
  );
};

export default AdminAttendance;
