import React, { useState, useEffect } from 'react';
import axiosInstance from '../../service/axiosInstance';
import FilesTable from './FilesTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';

const AdminFilesUpload = () => {
  const [fileId, setFileId] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [description, setDescription] = useState('');
  const [viewEmployee, setViewEmployee] = useState(true);
  const [viewManager, setViewManager] = useState(false);
  const [downloadEmployee, setDownloadEmployee] = useState(true);
  const [downloadManager, setDownloadManager] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [files, setFiles] = useState([]);

  const token = localStorage.getItem('jwt');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axiosInstance.get('/api/admin/files', { headers });
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    formData.append('viewEmployee', viewEmployee ? 'on' : 'off');
    formData.append('viewManager', viewManager ? 'on' : 'off');
    formData.append('downloadEmployee', downloadEmployee ? 'on' : 'off');
    formData.append('downloadManager', downloadManager ? 'on' : 'off');

    try {
      if (editMode) {
        await axiosInstance.put(`/api/admin/files/${fileId}`, formData, {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data'
          },
        });
        toast.success("Form updated successfully")
      } else {
        await axiosInstance.post('/api/admin/upload', formData, {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data'
          },
        });
        toast.success("Form saved successfully")
      }

      resetForm();
      toggleOffcanvas();
      fetchFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error("Error uploading file");
    }
  };

  const resetForm = () => {
    setFileId(null);
    setFile(null);
    setFileName(''); 
    setDescription('');
    setViewEmployee(true);
    setViewManager(false);
    setDownloadEmployee(true);
    setDownloadManager(false);
    setEditMode(false);
  };

  const handleView = (file) => {
    setFileId(file._id);
    setFile(null);
    setFileName(file.fileName); 
    setDescription(file.description);
    setViewEmployee(file.permissions.view.employee);
    setViewManager(file.permissions.view.manager);
    setDownloadEmployee(file.permissions.download.employee);
    setDownloadManager(file.permissions.download.manager);
    setEditMode(false);
    toggleOffcanvas();
  };

  const handleEdit = (file) => {
    setFileId(file._id);
    setFile(null);
    setFileName(file.fileName);
    setDescription(file.description);
    setViewEmployee(file.permissions.view.employee);
    setViewManager(file.permissions.view.manager);
    setDownloadEmployee(file.permissions.download.employee);
    setDownloadManager(file.permissions.download.manager);
    setEditMode(true);
    toggleOffcanvas();
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/admin/files/${id}`, { headers });
      setFiles(files.filter(file => file._id !== id));
      fetchFiles()
      toast.success("File deleted successfully");
    } catch (error) {
      console.error('Error deleting file:', error);
      toast.error("Error deleting file")
    }
  };

  const toggleOffcanvas = () => {
    const offcanvas = document.getElementById('offcanvasForm');
    const backdrop = document.getElementById('offcanvasBackdrop');
    if (offcanvas.classList.contains('show')) {
      offcanvas.classList.remove('show');
      backdrop.classList.remove('show');
      document.body.classList.remove('offcanvas-backdrop');
    } else {
      offcanvas.classList.add('show');
      backdrop.classList.add('show');
      document.body.classList.add('offcanvas-');
    }
  };

  return (
    <>
      <div className="shadow mt-4">
        <ToastContainer/>
        <button className="btn btn-light m-4 border" onClick={toggleOffcanvas}>
          Upload file
        </button>
        <hr className='m-0 p-0' />
        <div className="p-2">
          <FilesTable
            files={files}
            onEdit={handleEdit}
            onView={handleView}
            onDelete={handleDelete}
          />
        </div>
      </div>

      <div className="offcanvas w-50 offcanvas-end offcanvas-custom" id="offcanvasForm" tabIndex="-1">
        <div className="offcanvas-header">
          <h2 className="text-xl fw-semibold">{editMode ? 'Edit File' : 'Add File'}</h2>
          <button type="button" className="btn-close" onClick={toggleOffcanvas}></button>
        </div>
        <div className="offcanvas-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">File Name</label>
              {editMode ? (
                <input type="text" className="form-control" value={fileName.split('-').slice(1).join('-')} readOnly />
              ) : (
                <input type="file" className="form-control" onChange={(e) => setFile(e.target.files[0])} />
              )}
            </div>

            <div className="mb-4">
              <label className="form-label">Description</label>
              <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>

            <div className="mb-4 p-4 bg-light rounded">
              <h3 className="text-lg fw-semibold mb-2">File Permissions</h3>
              <p className="text-muted mb-4">
                To hide view for employee or reporting manager, uncheck the corresponding box. To restrict downloading the file for employee or reporting manager, uncheck the corresponding box.
              </p>
              <div className="mb-4">
                <label className="form-label">To view:</label>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="viewEmployee"
                    checked={viewEmployee}
                    onChange={() => setViewEmployee(!viewEmployee)}
                  />
                  <label className="form-check-label ms-2" htmlFor="viewEmployee">Employee</label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="viewManager"
                    checked={viewManager}
                    onChange={() => setViewManager(!viewManager)}
                  />
                  <label className="form-check-label ms-2" htmlFor="viewManager">Reporting Manager</label>
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label">To download:</label>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="downloadEmployee"
                    checked={downloadEmployee}
                    onChange={() => setDownloadEmployee(!downloadEmployee)}
                  />
                  <label className="form-check-label ms-2" htmlFor="downloadEmployee">Employee</label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="downloadManager"
                    checked={downloadManager}
                    onChange={() => setDownloadManager(!downloadManager)}
                  />
                  <label className="form-check-label ms-2" htmlFor="downloadManager">Reporting Manager</label>
                </div>
              </div>
            </div>
            
            <div className="d-flex gap-3">
              <button type="submit" className="btn btn-button">
                {editMode ? 'Update' : 'Submit'}
              </button>
              {/* <button type="button" className="btn btn-secondary" onClick={resetForm}>
                Cancel
              </button> */}
            </div>
          </form>
        </div>
      </div>

      <div className="offcanvas-backdrop-custom" id="offcanvasBackdrop" onClick={toggleOffcanvas}></div>
    </>
  );
};

export default AdminFilesUpload;
