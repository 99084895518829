import React, { useEffect, useState } from 'react';
import logo from '../assets/img/hnh-logo.png';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('home');

  const handleLinkClick = (link) => {
    setActiveLink(link);
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarToggler && navbarCollapse) {
      navbarToggler.classList.add('collapsed');
      navbarCollapse.classList.remove('show');
    }
  };

  useEffect(() => {
    const pathname = location.pathname.substring(1);
    setActiveLink(pathname || 'home');
  }, [location.pathname]);

  return (
  <nav className="navbar navbar-expand-lg navbar-light bg-dark sticky-top w-100">
    <div className="container-fluid">
      <Link className="navbar-brand text-white" to='/'>
        <img src={logo} alt="logo" width="60" height="60" className="d-inline-block align-top" />
        H&H
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">
          <i className="bi bi-list fs-2 text-white"></i>
        </span>
      </button>
      <div className="collapse navbar-collapse justify-content-end p-2" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <Link to="/" onClick={() => handleLinkClick('home')} className={`navbar-link text-capitalize ${activeLink === "home" ? "active" : "" }`}>Home</Link>
          <Link to="/about-us" onClick={() => handleLinkClick('about-us')} className={`navbar-link text-capitalize ${activeLink === "about-us" ? "active" : "" }`}>About Us</Link>
          <Link to="/our-service" onClick={() => handleLinkClick('our-service')} className={`navbar-link text-capitalize ${activeLink === "our-service" ? "active" : "" }`}>Services</Link>
          {/* <Link to="/case-study" onClick={() => handleLinkClick('case-study')} className={`navbar-link text-capitalize ${activeLink === "case-study" ? "active" : "" }`}>case-study</Link> */}
          <Link to="/technology" onClick={() => handleLinkClick('technology')} className={`navbar-link text-capitalize ${activeLink === "technology" ? "active" : "" }`}>Technology</Link>
          <Link to="/careers" onClick={() => handleLinkClick('careers')} className={`navbar-link text-capitalize ${activeLink === "careers" ? "active" : "" }`}>Careers</Link>
          <Link to="/contact" onClick={() => handleLinkClick('contact')} className={`navbar-link text-capitalize ${activeLink === "contact" ? "active" : "" }`}>Contact</Link>
        </div>
      </div>
    </div>
  </nav>
  );
}

export default Header;