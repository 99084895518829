import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CoffeeKnowledgeForm = ({ onSubmit, currentSession, employeesData }) => {
  const [topic, setTopic] = useState("");
  const [date, setDate] = useState(null);
  const [link, setLink] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");

  useEffect(() => {
    if (currentSession) {
      setTopic(currentSession.topic);
      setDate(new Date(currentSession.date));
      setLink(currentSession.link);
      setSelectedEmployee(currentSession.sessionBy._id);
    }
  }, [currentSession]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ topic, sessionBy: selectedEmployee, date, link });
    setTopic("");
    setDate(null);
    setLink("");
    setSelectedEmployee("");
  };

  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  return (
    <div className="mb-3">
      <form onSubmit={handleSubmit} className="container">
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label>Topic Name:</label>
              </div>
              <div className="col-lg-8">
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  required
                  className="form-control-announce"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label>Session By:</label>
              </div>
              <div className="col-lg-8">
                <select
                  className="form-control-announce bg-white"
                  onChange={handleEmployeeChange}
                  value={selectedEmployee}
                  required
                >
                  <option value="">Select Employee</option>
                  {employeesData.map((employee) => (
                    <option key={employee._id} value={employee._id}>
                      {employee.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label>Date:</label>
              </div>
              <div className="col-lg-8">
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  required
                  className="form-control-announce"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label>Link:</label>
              </div>
              <div className="col-lg-8">
                <input
                  type="text"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                //   required
                  className="form-control-announce"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="text-center">
            <button type="submit" className="btn btn-button">
              {currentSession ? "Update" : "Add"} Session
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CoffeeKnowledgeForm;
