import axiosInstance from '../service/axiosInstance';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FaDownload, FaLock } from 'react-icons/fa';
import BASE_URL from '../service/config';

const FileDisplay = () => {
    const [filePaths, setFilePaths] = useState({});
    const { id } = useParams();
    const token = localStorage.getItem('jwt');
    const headers = useMemo(()=>({
      Authorization: `Bearer ${token}`
    }),[token]);

    const fetchFiles = useCallback(() => {
        axiosInstance.get(`/files/${id}`, { headers })
            .then(response => {
                setFilePaths(response.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    console.log('Files not found yet');
                    setFilePaths({});
                } else {
                    console.error('Error:', error);
                }
            });
            
    },[headers,id]);

    const renderFileCard = (filePath, fileName, cardTitle) => (
        <div className="col-md-6 mb-4">
            <div className="card no-hover-card">
                <div className="card-body">
                    <h5 className="card-title">{cardTitle}</h5>
                    <button className="d-flex btn border w-100" onClick={() => downloadFile(filePath, fileName)}>
                        <span className="me-2"><FaDownload /></span>
                        <span className="file-name ms-2">{fileName}</span>
                    </button>
                </div>
            </div>
        </div>
    );

    const renderImageCard = (filePath, fileName, cardTitle) => (
        <div className="col-md-6 mb-4">
            <div className="card no-hover-card">
                <div className="card-body">
                    <h5 className="card-title">{cardTitle}</h5>
                    <img
                        src={`${BASE_URL}/${filePath}`}
                        alt={cardTitle}
                        className="img-fluid mb-2"
                        style={{ cursor: 'pointer' }}
                        // onClick={() => handleImageClick(filePath, cardTitle)}
                    />
                    <button className="d-flex btn border w-100" onClick={() => downloadFile(filePath, fileName)}>
                        <span className="me-2"><FaDownload /></span>
                        <span className="file-name ms-2">{fileName}</span>
                    </button>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    const downloadFile = (fileUrl, fileName) => {
        axiosInstance.get(fileUrl, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data]);
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = fileName;

                anchor.click();
                window.URL.revokeObjectURL(anchor.href);
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    };   
    

    return (
        <div className="container mb-5">
            <div className="d-flex justify-content-between fw-bold fs-4 mb-2 align-items-center mt-5">
                <div>Employees Documents <span className="text-danger fs-4"><FaLock/></span></div>
            </div>
            {/* <h1 className="mt-5 mb-4">Employee Files</h1> */}
            <div className="mb-5">
                {Object.keys(filePaths).length > 0 ? (
                    <div className="row border p-4 shadow">
                        {filePaths.offerLetter && renderFileCard(filePaths.offerLetter, filePaths.offerLetter.split("-").pop(), "Offer Letter")}
                        {filePaths.appointmentLetter && renderFileCard(filePaths.appointmentLetter, filePaths.appointmentLetter.split("-").pop(), "Appointment Letter")}
                        {filePaths.employeeHandbook && renderFileCard(filePaths.employeeHandbook, filePaths.employeeHandbook.split("-").pop(), "Employee Handbook")}
                        {filePaths.kra && renderFileCard(filePaths.kra, filePaths.kra.split("-").pop(), "KRA")}
                        {filePaths.salarySlips && renderFileCard(filePaths.salarySlips, filePaths.salarySlips.split("-").pop(), "Salary Slips")}
                        {filePaths.agreement && renderFileCard(filePaths.agreement, filePaths.agreement.split("-").pop(), "Agreement")}
                        {filePaths.aadhaar && renderImageCard(filePaths.aadhaar, filePaths.aadhaar.split("-").pop(), "Aadhaar")}
                        {filePaths.panCard && renderImageCard(filePaths.panCard, filePaths.panCard.split("-").pop(), "PAN Card")}
                    </div>
                ) : (
                    <div className="alert alert-info">
                        No files to display
                    </div>
                )}
            </div>
        </div>
    );

};

export default FileDisplay;
