import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircle } from '@fortawesome/free-solid-svg-icons';

const Steps = [
    { title: 'Discovery Workshop', description: 'Here, we understand the business, goals, pain points and priorities. These multiple brainstorming sessions help us to map out the existing workflows and the future goals.' },
    { title: 'Strategy and Solution Design', description: 'At this stage, once we gather all the requirements in a whiteboard session, we begin designing, prototyping, and user testing for platform or product.' },
    { title: 'Development', description: 'Programming and coding based on the designs approved by you takes place in this stage. Our development lifecycle consists of 3-stages: Alpha phase, Beta phase and Release phase.' },
    { title: 'Testing and QA', description: 'We continue our data-derived validation process, carry out in-depth QA testing, for each release of your product or platform to ensure that the final product, that is ready for the market.' },
    { title: 'Launch and Maintenance', description: 'Here, your product or platform goes LIVE. On your approval, our devs publish your product in live environments. We exclusively take care of cloud and on-premise hosting.' },
    { title: 'Support and Customer Service', description: 'To ensure continuous improvement, we perform ongoing product and platform maintenance and optimization, deploy market strategies, and provide real/fix support.' }
];

const ProjectsSteps = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <div className={`container mt-5 text-center ${screenWidth < 776 ? 'text-center' : ''}`}>
            {Steps.map((step, index) => (
                <div key={index} className={`row ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`} data-aos-once="true" data-aos="zoom-in">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className={`p-3 mb-4 custom-icon position-relative ${index % 2 === 0 ? 'left-section-border' : 'ml-md-2 right-section-border'} ${screenWidth < 776 ? 'rounded-10' : ''}`}>
                            <div className={`row col justify-content-center ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
                                <div className="col-xl-10 col-lg-9 col-md-10">
                                    <h4 className='mb-0' >{step.title}</h4>
                                    <p className='mb-0' style={{fontSize:"14px"}} >{step.description}</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 d-flex align-items-center justify-content-center">
                                    <i className={`bx bx-fingerprint icon-border fs-1`}></i>
                                </div>
                                {screenWidth >= 992 && (
                                    <div className={`col-lg-2 col-md-2 col-sm-2 d-flex align-items-center justify-content-center arrow-container ${index % 2 === 0 ? 'right-arrow' : 'left-arrow'}`}>
                                        <FontAwesomeIcon icon={faCircle} className="dot-icon" />
                                        <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProjectsSteps;
