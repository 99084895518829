import React from 'react';
import uploadimages from '../../../assets/img/user.png';
// import { Resizable, ResizableBox } from 'react-resizable';
import { FaEdit, FaEye, FaUserCog } from 'react-icons/fa';

const EmployeeList = ({
  employeesData,
  showOffcanvas,
  selectedItem,
  openOffcanvas,
  closeOffcanvas,
  entitlement,
  handleChange,
  handleUpdateSubmit,
  setCandidateDetails,
  openEditForm, 
  openViewForm
}) => {

  return (
    <div className="table-responsive p-2">
    <table className="table">
      <thead>
        <tr>
          <th className="text-nowrap">Image</th>
          <th className="text-nowrap">Name</th>
          <th className="text-nowrap ">Email</th>
          <th className="text-nowrap">Department</th>
          <th className="text-nowrap">PAN Number</th>
          <th className="text-nowrap">Aadhar Number</th>
          {/* <th className="text-nowrap">DOB</th> */}
          <th className="text-nowrap text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        {employeesData.map((item, index) => (
          <tr key={index} className='align-middle' style={{fontSize:"14px"}}>
            <td>
              <div>
                <img src={item?.image || uploadimages} alt="Employee" className="rounded-circle" width={40} height={40} />
              </div>
            </td>
            <td className='text-nowrap'>{item?.name}</td>
            <td>{item?.email}</td>
            <td>{item?.department}</td>
            <td>{item?.panNumber}</td>
            <td>{item?.aadhaarNumber}</td>
            {/* <td className='text-nowrap'>{item?.dateOfBirth && item.dateOfBirth.split('T')[0]}</td> */}
            <td className=' text-nowrap'>
              {/* <button onClick={() => openOffcanvas(item)} className="btn border" style={{fontSize:"14px"}}>Entitlement</button> */}
              <button onClick={() => openViewForm(item)} className=" border me-2"   title="View Candidate">
                <FaEye className="me-1" /> 
              </button>
              <button onClick={() => openEditForm(item)} className=" border me-2"   title="Edit Candidate Details">
                <FaEdit className="me-1" /> 
              </button>
              <button onClick={() => openOffcanvas(item)} className=" border"   title="Add Entitlement" >
                <FaUserCog className="me-1" /> 
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>


      {/* Offcanvas for showing employee details and entitlement update form */}
      {showOffcanvas && selectedItem && (
        <div className="offcanvas offcanvas-end show" tabIndex="-1">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title">Employee Details</h5>
            <button type="button" className="btn-close" onClick={closeOffcanvas}></button>
          </div>
          <div className="offcanvas-body">
            <div>
              <h5>Name: {selectedItem.name}</h5>
              <p>Email: {selectedItem.email}</p>
              <p>Sick Leave Entitlement: {selectedItem.sickLeaveEntitlement}</p>
              <p>Casual Leave Entitlement: {selectedItem.casualLeaveEntitlement}</p>
              <p>Annual Leave Entitlement: {selectedItem.annualLeaveEntitlement}</p>
              <form onSubmit={handleUpdateSubmit}>
                <div className="mb-3">
                  <label htmlFor="annualLeaveEntitlement" className="form-label">Annual Leave Entitlement:</label>
                  <input 
                    type="number"
                    className="form-control"
                    id="annualLeaveEntitlement"
                    name="annualLeaveEntitlement"
                    value={entitlement.annualLeaveEntitlement}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="sickLeaveEntitlement" className="form-label">Sick Leave Entitlement:</label>
                  <input 
                    type="number"
                    className="form-control"
                    id="sickLeaveEntitlement"
                    name="sickLeaveEntitlement"
                    value={entitlement.sickLeaveEntitlement}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="casualLeaveEntitlement" className="form-label">Casual Leave Entitlement:</label>
                  <input 
                    type="number"
                    className="form-control"
                    id="casualLeaveEntitlement"
                    name="casualLeaveEntitlement"
                    value={entitlement.casualLeaveEntitlement}
                    onChange={handleChange}
                  />
                </div>
                <button type="submit" className="btn btn-primary">Update</button>
              </form>
            </div>
          </div>
        </div>
      )}

      {showOffcanvas && selectedItem && (
        <div className="offcanvas-backdrop show" onClick={closeOffcanvas}></div>
      )}
    </div>
  );
};

export default EmployeeList;
