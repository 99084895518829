import React, { useState, useEffect, useCallback } from 'react';

import 'react-calendar/dist/Calendar.css';
import axiosInstance from '../service/axiosInstance';
import { useParams } from 'react-router-dom';

import { FaStopwatch } from 'react-icons/fa';
import RegularizationRequestEmployee from './RegularizationRequestEmployee';
import WeekCalendar from './week';
import { toast } from 'react-toastify';
import moment from 'moment';

const AttendanceCalendar = () => {
  const userId = useParams();
  const employeeId = userId.id;
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [, setElapsedTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateId, setSelectedDateId ] = useState(null);
  
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState('');
  
  const fetchAttendance = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('jwt');
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await axiosInstance.get(`/api/emp-attendance/${employeeId}`, { headers });
      const { data } = response;      
      
      const attendanceRecord = data.find(record => {
        const datePart = new Date(record.date).toLocaleDateString();
        return datePart === selectedDate && record.adminApproval === "approved";
      });
      
      if (attendanceRecord) {
        
        setCheckInTime(attendanceRecord.checkIn ? new Date(attendanceRecord.checkIn) : null);
        
        setCheckOutTime(attendanceRecord.checkOut ? new Date(attendanceRecord.checkOut) : null);
        setSelectedDateId(attendanceRecord._id)
        console.log('selectedDateId', attendanceRecord._id)

        if (attendanceRecord.checkIn && !attendanceRecord.checkOut) {
          startTimer();
        } else {
          clearInterval(intervalId);
        }
      } else {
        
        setCheckInTime(null);
        setCheckOutTime(null);
        clearInterval(intervalId);
      }
    } catch (error) {
      console.error('Error fetching attendance:', error);
    } finally {
      setLoading(false); 
    }
  },[employeeId, selectedDate, intervalId]);
  
  useEffect(() => {
    if (selectedDate) {
      fetchAttendance();
    }
  }, [selectedDate]);
  const handleCalendarDataId = (calendarDataId) => {

    setSelectedDateId(calendarDataId)
  };
  
  const handleDayClick = (date) => {
    setSelectedDate(date);
    setCheckInTime(null);
    setCheckOutTime(null);
    
    clearInterval(intervalId);
  };

  const startTimer = () => {
    const id = setInterval(() => {
      setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
    }, 1000);
    setIntervalId(id);
  };

  const [clicked, setclicked] = useState('')

  const handleCheckIn = async () => {
    setLoading(true);
    const checkInTime = new Date();
    const selectedDate = new Date();
    const formattedDate = selectedDate.toISOString();
    try {
      const token = localStorage.getItem('jwt');
      const headers = {
        Authorization: `Bearer ${token}`
      };
      
      await axiosInstance.post('/api/emp-attendance/check-in', {
        employeeId,
        date: formattedDate,
        checkIn: checkInTime.toISOString()
      }, { headers });
      setCheckInTime(checkInTime);
      startTimer();
      setclicked('check-in');
      fetchAttendance();
    } catch (error) {
      console.error('Error checking in:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCheckOut = async () => {
    // setLoading(true); 
    const checkOutTime = new Date();

    if (!reason.trim()) {
      toast("Please enter your end of the status.");
      return;
    }
    // const [day, month, year] = selectedDate.split('/');
    
    const formattedDate = moment(selectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const selectedFormattedDate = formattedDate + 'T00:00:00.000Z';
    try {
      const token = localStorage.getItem('jwt');
      const headers = {
        Authorization: `Bearer ${token}`
      };
      await axiosInstance.put(`/api/emp-attendance/check-out/${selectedDateId}`, {
        employeeId,
        date: selectedFormattedDate,
        reason: reason
      }, { headers });
      setCheckOutTime(checkOutTime);
      clearInterval(intervalId);
      setclicked('check-out');
      setReason('')
      setShowModal(false);
    } catch (error) {
      console.error('Error checking out:', error);
      toast.error(error.message);
    } 
    finally {
      setclicked('check-out');
      setLoading(false); 
      setShowModal(false);
    }
  };  

  const calculateTimeDifference = (start, end) => {
    const diff = Math.abs(end - start) / 1000;
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    const seconds = Math.floor(diff % 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  
  
  const [, setShowOffcanvas] = useState(false); 
  const toggleOffcanvas = () => {
    setShowOffcanvas(prevState => !prevState);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setReason('');
  };

  const isWeekend = date => {
    const parsedDate = moment(date, 'DD/MM/YYYY').toDate();
    const day = parsedDate.getDay();
    return day === 0 || day === 6;
  };

  return (
    <div className='my-4 shadow p-2'>
      <div className="d-flex justify-content-between">
        <h3>Attendance</h3>
        <div>
          <button className="bg-primary text-white p-1 border " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={toggleOffcanvas}>Regularise Attendance</button>

          <div className="offcanvas offcanvas-end"style={{ width: '50%', margin: 'auto' }} tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header">
              <h5 id="offcanvasRightLabel">Regularize Attendance</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleOffcanvas}></button>
            </div>
            <div className="offcanvas-body">
              <RegularizationRequestEmployee onClose={toggleOffcanvas} clicked={clicked}/>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center my-4" style={{minHeight:"60px"}}>
        <div className="">
          {loading ? ( 
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              {selectedDate ? (
                <div className='d-flex align-items-center justify-content-between'>
                  <h4>Date: {selectedDate}</h4>
                  {/* {currentDate.toLocaleDateString().split('T')[0] === selectedDate && !checkInTime && (
                    <button className='btn btn-success p-2 fs-5' onClick={handleCheckIn}><span><FaStopwatch/></span>Check In 00:00:00</button>
                  )} */}
                  {currentDate.toLocaleDateString().split('T')[0] === selectedDate && !checkInTime && !isWeekend(selectedDate) && (
                    <button className='btn btn-success p-2 fs-5' onClick={handleCheckIn}><span><FaStopwatch /></span> Check In 00:00:00</button>
                  )}
                  {checkInTime ? (
                    <>
                      {
                        !checkOutTime && 
                        <div>
                          <button className='btn btn-danger fs-5' onClick={() => setShowModal(true)}>
                            <FaStopwatch/><span> </span>Check Out {calculateTimeDifference(checkInTime, new Date())}
                          </button>
                        </div>
                      }
                      {showModal && (
                        <div className="modal fade show" id="checkoutModal" tabIndex="-1" role="dialog" aria-labelledby="checkoutModalLabel" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                          <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                              <div className="modal-header justify-content-between">
                                <h5 className="modal-title" id="checkoutModalLabel">EOD Status</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <textarea className="form-control" rows="3" value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Please enter your end of the status"></textarea>
                              </div>
                              <div className="modal-footer justify-content-between">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseModal}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleCheckOut}>Checkout</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    </>
                  ) : (
                    <p className={`${ ( currentDate.toLocaleDateString().split('T')[0] === selectedDate ) ? 'd-none' : ''}`}>No check-in recorded for this date.</p>
                  )}
                  {checkInTime && checkOutTime && (
                    <p className='text-dark btn btn-info fs-5' style={{cursor:"none", pointerEvents:"none"}}>Total Time Worked: {calculateTimeDifference(checkInTime, checkOutTime)}</p>
                  )}
                </div>
              ) : (
                <div className='text-center mt-5'>
                  <p className='fs-3 fw-bold text-muted'>Please select a date to view attendance details.</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    <WeekCalendar onClickDay={handleDayClick} clicked={clicked} onClickCalendarDataId={handleCalendarDataId}/>

    </div>
  );
};

export default AttendanceCalendar;
