import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../service/axiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import BASE_URL from '../service/config';

const EmployeePayFormFiles = () => {
  const { id: employeeId } = useParams();
  const [form16Rows, setForm16Rows] = useState([]);
  const [payslipRows, setPayslipRows] = useState([]);
  const token = localStorage.getItem('jwt');
  const headers = useMemo(() => ({
    Authorization: `Bearer ${token}`
  }), [token]);

  useEffect(() => {
    const fetchForm16Files = async () => {
      if (employeeId) {
        try {
          const response = await axiosInstance.get(`/api/form16/${employeeId}`, { headers });
          setForm16Rows(response.data.filter(file => file.viewToEmployee));
        } catch (error) {
          console.error('Error fetching Form 16 files:', error);
        }
      }
    };

    const fetchPayslipFiles = async () => {
      if (employeeId) {
        try {
          const response = await axiosInstance.get(`/api/payslip/${employeeId}`, { headers });
          setPayslipRows(response.data.filter(file => file.viewToEmployee));
        } catch (error) {
          console.error('Error fetching payslip files:', error);
        }
      }
    };

    fetchForm16Files();
    fetchPayslipFiles();
  }, [employeeId, headers]);

  return (
    <div className="container my-3">
      <div className="accordion-item" id="fileAccordion">
        <div className="card">
          <div className="card-header" id="headingForm16">
            <h2 className="mb-0">
              <button
                className="border border-0 bg-light"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseForm16"
                aria-expanded="true"
                aria-controls="collapseForm16"
              >
                Form 16 Files
              </button>
            </h2>
          </div>
          <div
            id="collapseForm16"
            className="collapse show"
            aria-labelledby="headingForm16"
            data-bs-parent="#fileAccordion"
          >
            <div className="card-body">
              <table className="table table-responsive text-nowrap" style={{ fontSize: '13px' }}>
                <thead>
                  <tr>
                    <th>File</th>
                    <th>View to Employee</th>
                    <th>Description</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {form16Rows.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <a href={`${BASE_URL}/${row.filePath}`} target="_blank" rel="noopener noreferrer" >
                          {row.filePath.split('-')[1]}
                        </a>
                      </td>
                      <td>{row.viewToEmployee ? 'Yes' : 'No'}</td>
                      <td>{row.description}</td>
                      <td>
                        <a href={`${BASE_URL}/${row.filePath}`} target="_blank" rel="noopener noreferrer" download className="btn btn-sm btn-primary">
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingPayslip">
            <h2 className="mb-0">
              <button
                className="border border-0 bg-light collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsePayslip"
                aria-expanded="false"
                aria-controls="collapsePayslip"
              >
                Payslip Files
              </button>
            </h2>
          </div>
          <div
            id="collapsePayslip"
            className="collapse"
            aria-labelledby="headingPayslip"
            data-bs-parent="#fileAccordion"
          >
            <div className="card-body">
              <table className="table table-responsive text-nowrap" style={{ fontSize: '13px' }}>
                <thead>
                  <tr>
                    <th>File</th>
                    <th>View to Employee</th>
                    <th>Month</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {payslipRows.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <a href={`${BASE_URL}/${row.filePath}`} target="_blank" rel="noopener noreferrer">
                          {row.filePath.split('-')[1]}
                        </a>
                      </td>
                      <td>{row.viewToEmployee ? 'Yes' : 'No'}</td>
                      <td>{row.month}</td>
                      <td>
                        <a href={`${BASE_URL}/${row.filePath}`} target="_blank" rel="noopener noreferrer" download className="btn btn-sm btn-primary">
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeePayFormFiles;
