import React, { useState } from 'react';
import axiosInstance from '../../service/axiosInstance';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';

const ResignationForm = ({ infoData, headers, fetchResignations }) => {
  const { id } = useParams()
  const [dateOfRelieving, setDateOfRelieving] = useState('');
  const [lastWorkingDay, setLastWorkingDay] = useState(null);
  const [finalSettlementDate, setFinalSettlementDate] = useState(null);
  const [reason, setReason] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const errors = {};
    if (!dateOfRelieving) {
        errors.dateOfRelieving = 'Date of Relieving is required';
    }
    if (!reason.trim()) {
        errors.reason = 'Reason for Resignation is required';
    }
    return errors;
};
const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await axiosInstance.post('/api/api/resignations', {
        employeeId: id,
        dateOfRelieving,
        lastWorkingDay,
        finalSettlementDate,
        reason,
        isAccepted,
      }, {headers});
      fetchResignations()
      toast.success("Form submitted successfully")
      // setEmployeeId('');
      setDateOfRelieving('');
      setLastWorkingDay(null);
      setFinalSettlementDate(null);
      setReason('');
      setIsAccepted(false);
      setErrors({});
      console.log(response.data);
    } catch (error) {
      console.error('Error submitting resignation:', error);
      toast.error('Error submitting resignation:');
    }
  };

  return (
    <div className="shadow mt-5">
      <h3 className='p-2'>Resignation Form</h3>
      <div className="p-4">
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-xl-6">
            <div className="row mb-3">
              <div className='col-lg-4 d-flex align-items-end'>
                <label htmlFor="dateOfRelieving" className="form-label">Date of Relieving</label>
              </div>
              <div className="col-lg-8">
                <DatePicker
                  selected={dateOfRelieving}
                  onChange={(date) => setDateOfRelieving(date)}
                  className={`form-control-announce ${errors.dateOfRelieving ? 'is-invalid' : ''}`}
                  dateFormat="yyyy-MM-dd"
                  required
                />
              </div>
              {errors.dateOfRelieving && <div className="invalid-feedback">{errors.dateOfRelieving}</div>}
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-12">
            <div className="row mb-3">
              <div className='col-lg-2 d-flex align-items-end'>
                <label htmlFor="lastWorkingDay" className="form-label">Notice period</label>
              </div>
              <div className="col-lg-4">
                <DatePicker
                  selected={lastWorkingDay}
                  onChange={(date) => setLastWorkingDay(date)}
                  className="form-control-announce"
                  dateFormat="yyyy-MM-dd"
                  placeholderText='From'
                  required
                />
              </div>
            {/* </div>
          </div>
          <div className="col-lg-6">
            <div className="row"> */}
              {/* <div className='col-lg-4 d-flex align-items-end'>
                <label htmlFor="finalSettlementDate" className="form-label"></label>
              </div> */}
              <div className="col-lg-4">
                <DatePicker
                  selected={finalSettlementDate}
                  onChange={(date) => setFinalSettlementDate(date)}
                  className="form-control-announce"
                  dateFormat="yyyy-MM-dd"
                  placeholderText='To'
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <div className="row">
              <div className='col-lg-2 d-flex align-items-end'>
                <label htmlFor="reason" className="form-label">Reason for Resignation</label>
              </div>
              <div className="col-lg-7">
                <textarea
                  className={`form-control-announce ${errors.reason ? 'is-invalid' : ''}`}
                  id="reason"
                  rows="1"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                ></textarea>
              </div>
              {errors.reason && <div className="invalid-feedback">{errors.reason}</div>}
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-12 d-flex align-items-center">
            <input
              type="checkbox"
              className="form-check-input me-2"
              id="isAccepted"
              checked={isAccepted}
              onChange={(e) => setIsAccepted(e.target.checked)}
            />
            <label htmlFor="isAccepted" className="form-check-label">Resignation Accepted?</label>
          {/* </div>
          <div className="col-lg-7"> */}
          </div>
        </div>

        <button type="submit" className="btn btn-button">Submit</button>
      </form>

      </div>
    </div>
  );
};

export default ResignationForm;
