import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../service/axiosInstance';
import { toast } from 'react-toastify';

const ExitManagement = ({ resignationId, headers }) => {
  const [actions, setActions] = useState({
    tasks: [],
    checklists: [],
    customFunctions: [],
  });
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [currentArrayName, setCurrentArrayName] = useState('');
  const [newItemStatus, setNewItemStatus] = useState('Pending');

const fetchActions = useCallback(async () => {
  try {
    const response = await axiosInstance.get(`/api/api/resignations/${resignationId}`, {headers});
    setActions({
      tasks: response.data.tasks,
      checklists: response.data.checklists,
      customFunctions: response.data.customFunctions,
      });
    } catch (error) {
      console.error('Error fetching actions:', error);
    }
  }, [headers, resignationId]);
  
  useEffect(() => {
    if (resignationId) {
      fetchActions();
    }
  }, [resignationId, fetchActions]);

  const handleAddItem = async (arrayName) => {
    const newItem = { name: newItemName, status: newItemStatus };
    try {
      const response = await axiosInstance.post(`/api/api/resignations/${resignationId}/${arrayName}`, newItem, {headers});
      console.log("handleAddItem",response.data);
      setActions((prevActions) => ({
        ...prevActions,
        [arrayName]: response.data[arrayName],
      }));
      setShowAddItemModal(false);
      setNewItemName('');
      setNewItemStatus('Pending');
      toast.success(`Successfully added resignation for ${newItemName}`);
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('Error adding resignation');
    }
  };
  
  const handleRemoveItem = async (arrayName, itemId) => {
    try {
      await axiosInstance.delete(`/api/api/resignations/${resignationId}/${arrayName}/${itemId}`, {headers});
      setActions((prevActions) => ({
        ...prevActions,
        [arrayName]: prevActions[arrayName].filter(item => item._id !== itemId),
      }));
      toast.success('Successfully removed resignation');
    } catch (error) {
      console.error('Error removing item:', error);
      toast.error('Error removing resignation');
    }
  };
  
  const handleStatusChange = async (arrayName, itemId, currentStatus) => {
    const newStatus = currentStatus === 'Pending' ? 'Completed' : 'Pending';
    try {
      const response = await axiosInstance.put(`/api/api/resignations/${resignationId}/${arrayName}/${itemId}`, { status: newStatus }, {headers});
      setActions((prevActions) => ({
        ...prevActions,
        [arrayName]: response.data[arrayName],
      }));
      toast.success(`Successfully updated status to ${newStatus}`);
    } catch (error) {
      console.error('Error updating item status:', error);
      toast.error('Error updating status');
    }
  };
  

  const handleOpenAddItemModal = (arrayName) => {
    setCurrentArrayName(arrayName);
    setShowAddItemModal(true);
  };

  const handleCloseAddItemModal = () => {
    setShowAddItemModal(false);
    setNewItemName('');
    setNewItemStatus('Pending');
  };

  return (
    <div className=" p-4 bg-light text-dark shadow-sm rounded">
      <div className="-body">
        <h5 className="-title mb-4">Actions</h5>
        <div id="accordion">
          {Object.keys(actions).map((arrayName, index) => (
            <div className="accordion-item border p-2" key={index}>
              <h4 className="accordion-header p-0 m-0" id={`heading${index}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="d-flex align-items-center"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                  >
                    <span className='fs-6'>{arrayName.charAt(0).toUpperCase() + arrayName.slice(1)}</span>
                  </div>
                  <button className="btn btn-danger p-1 px-2" onClick={() => handleOpenAddItemModal(arrayName)}>
                    + Add
                  </button>
                </div>
              </h4>



              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse show"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <ul className="list-group list-group-flush">
                    {actions[arrayName].map((item) => (
                      <li key={item._id} className="fs-6 list-group-item fs-6 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={item.status === 'Completed'}
                            onChange={() => handleStatusChange(arrayName, item._id, item.status)}
                            id={`status-${item._id}`}
                            />
                            <span className='fs-6'>{item.name}</span>
                        </div>
                        <button className="bg-light btn-light border" onClick={() => handleRemoveItem(arrayName, item._id)}>
                          🗑️
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for Adding Item */}
      {showAddItemModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add {currentArrayName.charAt(0).toUpperCase() + currentArrayName.slice(1)}</h5>
                <button type="button" className="btn-close" onClick={handleCloseAddItemModal}></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="itemName" className="form-label">
                    Item Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="itemName"
                    value={newItemName}
                    onChange={(e) => setNewItemName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Status</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="status"
                      checked={newItemStatus === 'Completed'}
                      onChange={(e) => setNewItemStatus(e.target.checked ? 'Completed' : 'Pending')}
                    />
                    <label className="form-check-label" htmlFor="status">
                      {newItemStatus === 'Completed' ? 'Completed' : 'Pending'}
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => handleAddItem(currentArrayName)}>
                  Save
                </button>
                <button type="button" className="btn btn-secondary" onClick={handleCloseAddItemModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExitManagement;
