import React from 'react';
import image from '../../assets/img/birthday.png';

const UpcomingBirthdays = ({ employeesData }) => {
  const upcomingBirthdays = employeesData.filter(item => {
    const today = new Date();
    const birthday = new Date(item.dateOfBirth);
    const nextWeek = new Date(today.getTime() + 20 * 24 * 60 * 60 * 1000);

    const birthdayMonth = birthday.getMonth();
    const birthdayDate = birthday.getDate();
    const todayMonth = today.getMonth();
    const todayDate = today.getDate();

    return (
      (birthdayMonth === todayMonth && birthdayDate >= todayDate) ||
      (nextWeek.getMonth() === birthdayMonth && birthdayDate <= nextWeek.getDate())
    );
  });


  return (
    <div className=' p-3' style={{ minHeight: '400px', position: 'relative' }}>
      <h4>Upcoming Birthdays </h4>
      <hr />
      {upcomingBirthdays.length > 0 ? (
        <div className="overlay" >
          <table className="table">
            <thead>
              {/* <tr>
                <th>Name</th>
                <th>Date of Birth</th>
              </tr> */}
            </thead>
            <tbody>
              {upcomingBirthdays.map((item, index) => (
                <tr key={index}>
                  <td>🎉 {item.name}</td>
                  <td>{new Date(item.dateOfBirth).toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <img src={image} alt="Placeholder" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
      )}
    </div>
  );
};

const TodayBirthdays = ({ employeesData }) => {
  const today = new Date();
  const todayBirthdays = employeesData.filter(item => {
    const birthday = new Date(item.dateOfBirth);

    const birthdayMonth = birthday.getMonth();
    const birthdayDate = birthday.getDate();
    const todayMonth = today.getMonth();
    const todayDate = today.getDate();

    return birthdayMonth === todayMonth && birthdayDate === todayDate;
  });

  return (
    <div className=' p-3' style={{ minHeight: '400px', position: '' }}>
      <h4>Today's Birthdays</h4>
      <hr />
      {todayBirthdays.length > 0 ? (
        <div className="overlay" style={{ position: '', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date of Birth</th>
              </tr>
            </thead>
            <tbody>
              {todayBirthdays.map((item, index) => (
                <tr key={index}>
                  <td>🎉 {item.name}</td>
                  {/* <td>{new Date(item.dateOfBirth).toLocaleDateString()}</td> */}
                  <td>{new Date(item.dateOfBirth).toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <img src={image} alt="Placeholder" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
      )}
    </div>
  );
};

const BirthdayDisplay = ({ employeesData, today }) => {
  return (
    <div>
      {today ? 
        <TodayBirthdays employeesData={employeesData} />
        :
        <UpcomingBirthdays employeesData={employeesData} />
      }
    </div>
  );
};

export default BirthdayDisplay;
