import React, { useState } from "react";
import Form16AndPayslipForm from "./Form16AndPayslipForm";
import Form16Upload from "./Form16Upload";
import PayslipUpload from "./PayslipUpload";
import { ToastContainer } from "react-toastify";

export const PayrollComp = ({employeesData}) => {

    const [isFormOpen, setIsFormOpen] = useState(false);
    const toggleForm = () => {
      setIsFormOpen(!isFormOpen);
    };

    const [selectedEmployee, setSelectedEmployee] = useState('');
    const handleEmployeeChange = (e) => {
        setSelectedEmployee(e.target.value);
      };
  return (
    <>
    <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="mb-0">Payroll</h4>
        <button className="btn btn-primary" onClick={toggleForm}>
            Upload Form
        </button>
    </div>
    <ToastContainer/>
    <hr />
      <Form16AndPayslipForm
        isOpen={isFormOpen}
        toggle={toggleForm}
        infoData={employeesData}
      />
      <div className="row mt-4">
        <div className="mb-3">
          <select
            name="empData"
            id="employeesData"
            className="form-select"
            value={selectedEmployee}
            onChange={handleEmployeeChange}
          >
            <option value="">Select Employee</option>
            {employeesData.map((item) => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        {selectedEmployee ? (

        <div className="accordion" id="uploadAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="form16Header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#form16Collapse"
                aria-expanded="true"
                aria-controls="form16Collapse"
              >
                Form 16 Upload
              </button>
            </h2>
            <div
              id="form16Collapse"
              className="accordion-collapse collapse show"
              aria-labelledby="form16Header"
              data-bs-parent="#uploadAccordion"
            >
              <div className="accordion-body">
                <Form16Upload employeeId={selectedEmployee} />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="payslipHeader">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#payslipCollapse"
                aria-expanded="false"
                aria-controls="payslipCollapse"
              >
                Payslip Upload
              </button>
            </h2>
            <div
              id="payslipCollapse"
              className="accordion-collapse collapse"
              aria-labelledby="payslipHeader"
              data-bs-parent="#uploadAccordion"
            >
              <div className="accordion-body">
                <PayslipUpload employeeId={selectedEmployee} />
              </div>
            </div>
          </div>
        </div>
        ) : (
          <div className="alert alert-info ">Please select employee to see the data</div>
        )}
      </div>
    </>
  );
};
