import React from "react";
import AdminAnnouncement from "./AdminAnnouncement";
import UpcomingHolidays from "../employee/UpcomingHolidays";
import BirthdayDisplay from "../component/BirthdayDisplay";
import RemoveEmployeeData from "./RemoveEmployeeData";

const MyDashboard = ({ employeesData, setemployeeAdded, onUpdateData }) => {
  return (
    <>
      <div className="mt-4">
        <h2 className="mb-4">My Dashboard</h2>
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-3">
          <div className="col-md-6 col-xl-4">
            <div className="shadow">
              <AdminAnnouncement />
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="shadow">
              <BirthdayDisplay employeesData={employeesData} />
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="shadow">
              <UpcomingHolidays />
            </div>
          </div>
            <div className="col-md-6 col-xl-4">
                <div className="shadow">
                    <RemoveEmployeeData employeesData={employeesData} setemployeeAdded={setemployeeAdded} onUpdateData={onUpdateData} />
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default MyDashboard;
