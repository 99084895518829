import React, { useEffect } from "react";
import image from "../assets/img/exclaimblack.png";
import CareerAccordion from "./CareerAccordian";
import "./css/application.css";

const Careers = () => {
  useEffect(() => {
    document.title = 'H&H - Career';
  }, []);
  return (
    <>
      {/* <section className="m-0 p-0">
        <div className="Partners_container__WvWta parallax career-img mb-5 ">
          <div className="Partners_wrapper__G4bTB mb-5">
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex justify-content-center">
                  <h1 className="">CAREERS</h1>
                </div>
              </div>
              <div className="row col-md-6 d-flex justify-content-center ">
                <div className="col-md-12 text-white">
                  <h4 style={{fontSize:"2em"}}>Join Our Team at the Forefront of Trading Technology!</h4> 
                  <p className="text-white pt-2">Start your career at H&H today and let’s unleash your full potential. Become part of our vanguard pushing boundaries and shaping the digital landscape of tomorrow.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="parallax career-img">
        <div className="parallax-overlay"></div>
        <div className=''>
            <h1 className="">CAREERS</h1>
        </div>
      </div>
      <section id="careers" className="services ">
        <div className="container">
          <div
            className="section-title"
            data-aos-once="true"
            data-aos="zoom-in"
          >
            <h3>
              Browse our open positions to get started on your dream{" "}
              <span>
                career
                <img src={image} width="30px" className="pb-1" alt="" />
              </span>
            </h3>
          </div>
          <CareerAccordion />
        </div>
      </section>
    </>
  );
};

export default Careers;
