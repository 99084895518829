import React, { useState, useEffect, memo, useRef, useCallback, useMemo } from 'react';
import { BsSearch, BsFillHeartFill, BsChatFill } from 'react-icons/bs';
import axiosInstance from '../service/axiosInstance';
import profileImage from '../../assets/img/user.png';
import BASE_URL from '../service/config'
import { toast } from 'react-toastify';
import { GrAnnounce } from 'react-icons/gr';

const AnnouncementComponent = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [newAnnouncement, setNewAnnouncement] = useState({ title: '', message: '', image: null });

  const [newComment, setNewComment] = useState('');
  const [commentedAnnouncementId, setCommentedAnnouncementId] = useState(null);

  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showDataModal, setShowDataModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState('');

  
  const token = localStorage.getItem('jwt');
  const headers = useMemo(()=>({
    Authorization: `Bearer ${token}`
  }),[token]);
  const fetchAnnouncements = useCallback(() => {
    setLoading(true);
    axiosInstance.get('/api/adminAnnounce', {headers})
    .then(response => {
      // console.log("response", response.data);
      setAnnouncements(response.data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching announcements:', error);
      setLoading(false);
    });
  },[headers]);
  
  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddAnnouncement = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (event) => {
    if (event.target.name === 'image') {
      setNewAnnouncement({ ...newAnnouncement, [event.target.name]: event.target.files[0] });
    } else {
      setNewAnnouncement({ ...newAnnouncement, [event.target.name]: event.target.value });
    }
  };

  const admin = localStorage.getItem('user')
  const userId = JSON.parse(admin)?._id

  const handleLike = (announcementId) => {
    axiosInstance.post(`/api/likeAnnouncement/${announcementId}`)
      .then((response) => {
        const updatedAnnouncement = response.data;
        axiosInstance.get(`/api/adminAnnounce/${updatedAnnouncement._id}`, {headers})
        .then((response) => {
          const updatedAnnouncementWithComments = response.data;
          setSelectedAnnouncement(updatedAnnouncementWithComments);
          setNewComment('');
          // fetchAnnouncements();
        })
        .catch((error) => {
          console.error('Error fetching updated announcement data:', error);
        });
      })
      .catch((error) => {
        console.error('Error liking announcement:', error);
      });
  };
  
  const handleAddComment = (announcementId) => {
    // console.log('Adding comment...');
    axiosInstance.post(`/api/commentAnnouncement/${announcementId}`, { userId: userId, text: newComment })
    .then((response) => {
      const updatedAnnouncement = response.data;
      axiosInstance.get(`/api/adminAnnounce/${updatedAnnouncement._id}`, {headers})
      .then((response) => {
        const updatedAnnouncementWithComments = response.data;
        setSelectedAnnouncement(updatedAnnouncementWithComments);
        setNewComment('');
        fetchAnnouncements();
      })
      .catch((error) => {
        console.error('Error fetching updated announcement data:', error);
      });
    })
    .catch((error) => {
      console.error('Error adding comment:', error);
    });
  };
  
  const handleCloseDataModal = () => {
    setShowDataModal(false);
    // fetchAnnouncements();
  };
  

  const handleDeleteAnnouncement = (announcementId, announcementTitle) => {
    axiosInstance.delete(`/api/adminAnnounce/${announcementId}`, {headers})
      .then(() => {
        fetchAnnouncements();
        toast.success(`${announcementTitle} announcement has been deleted`);
      })
      .catch((error) => {
        console.error('Error deleting announcement:', error);
        toast.error(`${announcementTitle} announcement has been deleted`);
      });
  };
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('title', newAnnouncement.title);
      formData.append('message', newAnnouncement.message);
      formData.append('image', newAnnouncement.image);

      await axiosInstance.post('/api/adminAnnounceImage', formData, {headers});

      setShowModal(false);
      setNewAnnouncement({ title: '', message: '', image: null });
      fetchAnnouncements();
    } catch (error) {
      console.error('Error adding announcement:', error);
    }
  };

  const filteredAnnouncements = announcements.filter((announcement) =>
    announcement.title.toLowerCase().includes(searchTerm)
  );

  function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  }

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [selectedAnnouncement]);

  return (
    <div className="p-2" style={{ overlay:"auto",minHeight:"400px"}}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="input-group w-50">
          <span className="input-group-text"><BsSearch /></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search announcements"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <button className="btn btn-primary" onClick={handleAddAnnouncement}>Add Announcement</button>
      </div>
    <hr />
      {showModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="custom-modal" tabIndex="-1" role="dialog">
            <div className="modal-content">
              <div className="modal-header justify-content-between">
                <h5 className="modal-title">Add Announcement</h5>
                <button type="button" className="btn-close bg-white" onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      value={newAnnouncement.title}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    {/* <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows="3"
                      value={newAnnouncement.message}
                      onChange={handleChange}
                      required
                    ></textarea> */}
                    <textarea className="form-control" name="message" rows="3" value={newAnnouncement.message} required
                    onChange={handleChange} placeholder="Please enter announcement message"></textarea>

                  </div>
                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">Image</label>
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      name="image"
                      accept="image/*"
                      onChange={handleChange}
                    />
                    {newAnnouncement.image && (
                      <img src={URL.createObjectURL(newAnnouncement.image)} alt="Preview" className="img-fluid mt-2" width={200} height={200}/>
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-button">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mt-2" style={{ overflow: 'auto', minHeight: '200px' }}>
      {loading ? (
        <div className="text-center">
          {/* Skeleton UI */}
          <div className="skeleton-card mb-3"></div>
          <div className="skeleton-card mb-3"></div>
          <div className="skeleton-card mb-3"></div>
          <div className="loading-animation"></div>

        </div>
      ) : (
        announcements.length === 0 ? (
          <div className="alert alert-danger text-center d-flex flex-column align-items-center">
            <span>No announcements available</span>
            <GrAnnounce style={{ fontSize: '3rem' }} />
          </div>
        ) : (
        <div style={{ overflow: 'auto', maxHeight:"292px" }}>
          {filteredAnnouncements.map((announcement) => (
            <div key={announcement._id} className="border rounded mb-1">
              {showConfirmation === announcement._id ? (
                <div className="d-flex justify-content-between">
                  <div className="text-danger fw-bold p-2 rounded-start cursor-pointer" onClick={()=>handleDeleteAnnouncement(announcement._id, announcement.title)}>Remove Announcement</div>
                  <div className="bg-danger text-light p-2 rounded-end cursor-pointer" onClick={() => setShowConfirmation('')}>X</div>
                </div>
              ):(
                <>
                  <div className="card-body ps-2 pe-1 d-flex justify-content-between">
                    <div>
                      <h5
                        className="card-title py-2 text-uppercase cursor-pointer"
                        onClick={() => {
                          setSelectedAnnouncement(announcement);
                          setShowDataModal(true);
                        }}
                        style={{ fontSize: "16px" }}
                      >
                        {announcement.title}
                      </h5>
                      <p className="card-text text-muted" style={{ fontSize: "13px" }}>
                        {formatDate(announcement.date)}
                      </p>
                    </div>
                    <div className="d-flex align-items-center" style={{ fontSize: "15px" }}>
                      <span className="me-3">
                        <BsFillHeartFill /> {announcement.likes}
                      </span>
                      <span onClick={() => setCommentedAnnouncementId(announcement._id)}>
                        <BsChatFill /> {announcement.comments.length}
                      </span>
                      <span
                        className='bi bi-trash ps-2 pt-1 text-danger cursor-pointer position-relative'
                        onClick={() => setShowConfirmation(announcement._id)}
                      >
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        )
      )}

      {showDataModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="custom-modal" tabIndex="-1" role="dialog">
            <div className="modal-content">
              <div className="modal-header justify-content-between">
                <h5 className="modal-title fw-bold">{selectedAnnouncement.title}</h5>
                <button type="button" className="btn-close bg-white" onClick={handleCloseDataModal}></button>
              </div>
              <div className="modal-body mt-2">
                {/* <p style={{fontSize:"15px"}}>{selectedAnnouncement.message}</p> */}
                <textarea
                  ref={textareaRef}
                  className='border-0'
                  style={{ fontSize: '15px', width: '100%', height: 'auto', resize: 'none' }}
                  value={selectedAnnouncement.message}
                  readOnly
                />
                {selectedAnnouncement.image && (
                  <div className="mb-3">
                    <img src={`${BASE_URL}/${selectedAnnouncement.image}`} alt="Announcement" className="img-fluid" style={{ maxWidth: '200px' }} />
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <span className="me-3 text-danger cursor-pointer" onClick={() => handleLike(selectedAnnouncement._id)}><BsFillHeartFill /> {selectedAnnouncement.likes}</span>
                  <button className="d-flex align-items-center gap-2" onClick={() => setCommentedAnnouncementId(selectedAnnouncement._id)}>
                    <BsChatFill />
                    <span>Comment</span>
                    <span>({selectedAnnouncement.comments.length})</span>
                  </button>
                </div>
                {commentedAnnouncementId === selectedAnnouncement._id && (
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      rows="2"
                      placeholder="Add a comment"
                      style={{ resize: 'vertical' }}
                    ></textarea>
                    <button
                      className="btn btn-button mt-2"
                      onClick={() => handleAddComment(selectedAnnouncement._id)}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Add Comment
                    </button>
                  </div>
                )}

                <div className="border p-2 mt-3" style={{ overflow: 'auto', maxHeight: '300px' }}>
                  <h6 className="mb-3">Comments:</h6>
                  {selectedAnnouncement.comments.slice().reverse().map((comment, index) => (
                    <div key={index} className="mb-1 border p-1">
                      <div className="d-flex align-items-start">
                        <img
                          src={comment.user && comment.user.image ? comment.user.image : profileImage}
                          alt={comment.user && comment.user.name}
                          className="avatar rounded-circle me-2"
                          style={{ width: '40px', height: '40px' }}
                        />
                        <div className="position-relative flex-grow-1">
                          <div className="col">
                            <span><strong>{comment.user && comment.user.name}</strong></span>
                            <small className="text-muted ms-2">{formatDate(comment.date)}</small>
                          </div>
                          <p className="mb-0" style={{ fontSize:"13px" }}>{comment.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      </div>
    </div>
  );
};

export default memo(AnnouncementComponent);
