import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { BsSearch, BsFillHeartFill, BsChatFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import axiosInstance from '../service/axiosInstance';
import profileImage from '../../assets/img/user.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrAnnounce } from "react-icons/gr";
import BASE_URL from '../service/config';
import { formatDistance, subDays } from 'date-fns'

const EmployeeAnnouncement = () => {
  const { id } = useParams();
  const [announcements, setAnnouncements] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDataModal, setShowDataModal] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [showComment, setShowComment] = useState(false);
  const [loading, setLoading] = useState(false)

  const token = localStorage.getItem('jwt');
  const headers = useMemo(()=>({
    Authorization: `Bearer ${token}`
  }),[token]);
  const fetchAnnouncements = useCallback(() => {
    setLoading(true)
    axiosInstance.get('/api/adminAnnounce', {headers},{headers})
      .then(response => {
        setAnnouncements(response.data);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching announcements:', error);
        setLoading(false)
      });
  },[headers]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

    const handleLike = (announcementId) => {
      axiosInstance.post(`/api/likeAnnouncement/${announcementId}`, { userId: id,})
        .then((response) => {
          const updatedAnnouncement = response.data;
          axiosInstance.get(`/api/adminAnnounce/${updatedAnnouncement._id}`, {headers})
          .then((response) => {
            const updatedAnnouncementWithComments = response.data;
            setAnnouncements(updatedAnnouncementWithComments);
            setSelectedAnnouncement(updatedAnnouncementWithComments);
            setNewComment('');
          })
          .catch((error) => {
            console.error('Error fetching updated announcement data:', error);
          });
        })
        .catch((error) => {
          console.error('Error liking announcement:', error);
        });
    };
    
    const handleAddComment = (announcementId) => {
      console.log('Adding comment...');
      axiosInstance.post(`/api/commentAnnouncement/${announcementId}`, { userId: id, text: newComment })
      .then((response) => {
        toast.success("Comment added successfully");
        const updatedAnnouncement = response.data;
        axiosInstance.get(`/api/adminAnnounce/${updatedAnnouncement._id}`,{headers})
        .then((response) => {
          const updatedAnnouncementWithComments = response.data;
          setAnnouncements(updatedAnnouncementWithComments);
          setSelectedAnnouncement(updatedAnnouncementWithComments);
          setNewComment('');
        })
        .catch((error) => {
          console.error('Error fetching updated announcement data:', error);
        });
      })
      .catch((error) => {
        console.error('Error adding comment:', error);
        toast.error("Failed to add comment");
      });
  };

  function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  }

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [selectedAnnouncement]);

  return (
    <div className="p-2" style={{ overflow: 'auto', minHeight: '400px' }}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="" style={{fontSize:"1.5em"}}>Announcements</div>
        <div className="input-group w-50">
          <span className="input-group-text"><BsSearch /></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search Announcements"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
    <hr />
      <div className="mt-2" style={{ overflow: 'auto', maxHeight: '310px' }}>
      {loading ? (
        <div className="text-center">
          <div className="skeleton-card mb-3"></div>
          <div className="skeleton-card mb-3"></div>
          <div className="skeleton-card mb-3"></div>
          <div className="loading-animation"></div>
        </div>
      ) : (announcements.length === 0 ? (
            <div className="alert alert-danger text-center d-flex flex-column align-items-center">
              <span>No announcements available</span>
              <GrAnnounce style={{ fontSize: '3rem' }} />
            </div>
          ) : (
          announcements.map((announcement) => (
            <div key={announcement._id} className="card mb-2 cursor-pointer" onClick={() => { setSelectedAnnouncement(announcement); setShowDataModal(true); }}>
              <div className="px-2 pt-2 d-flex justify-content-between">
                <div>
                  <h5 className="card-title text-uppercase" style={{fontSize:"15px"}} >{announcement.title}</h5>
                  <p className="card-text text-muted" style={{fontSize:"13px"}} >{formatDate(announcement.date)}</p>
                </div>
                <div className="d-flex align-items-center" style={{fontSize:"15px"}}>
                <span className={`me-3 ${announcement.likedBy.includes(id) ? 'text-danger' : ''}`}><BsFillHeartFill /> {announcement.likes}</span>
                  <span><BsChatFill /> {announcement.comments.length}</span>
                </div>
              </div>
            </div>
          ))
        )
      )}

        {showDataModal && (
          <>
          <div className="modal-backdrop fade show"></div>
          <div className="custom-modal" tabIndex="-1" role="dialog">
            <div className="modal-content">
              <div className="modal-header justify-content-between">
                <h5 className="modal-title fw-bold">{selectedAnnouncement.title}</h5>
                <button type="button" className="btn-close bg-white" onClick={() => { setShowDataModal(false); setShowComment(false); }}></button>
              </div>
              <div className="modal-body">
                {/* <p style={{ fontSize: "15px" }}>{selectedAnnouncement.message}</p> */}
                <textarea
                  ref={textareaRef}
                  className='border-0'
                  style={{ fontSize: '15px', width: '100%', height: 'auto', resize: 'none' }}
                  value={selectedAnnouncement.message}
                  readOnly
                />
                {selectedAnnouncement.image && (
                  <div className="mb-3">
                    <img src={`${BASE_URL}/${selectedAnnouncement.image}`} alt="Announcement" className="img-fluid" style={{ maxWidth: '200px' }} />
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <span className={`me-3 cursor-pointer ${selectedAnnouncement.likedBy.includes(id) ? 'text-danger' : ''}`} onClick={() => handleLike(selectedAnnouncement._id)}><BsFillHeartFill /> {selectedAnnouncement.likes}</span>
                  <button className="d-flex align-items-center gap-2" onClick={() => setShowComment(true)}>
                    <BsChatFill />
                    <span>Comment</span>
                    <span>({selectedAnnouncement.comments.length})</span>
                  </button>
                </div>
                {showComment && (
                  <div className="mb-3 border p-1">
                    <textarea
                      className="form-control"
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      rows="2"
                      placeholder="Add a comment"
                    ></textarea>
                    <button
                      className="btn btn-primary mt-2"
                      onClick={() => handleAddComment(selectedAnnouncement._id)}
                    >
                      Add Comment
                    </button>
                  </div>
                )}
                <div className="border p-3 mt-3" style={{ overflow: 'auto', maxHeight: '230px' }}>
                  <h6 className="mb-3">Comments:</h6>
                  {selectedAnnouncement && selectedAnnouncement.comments.slice().reverse().map((comment, index) => (
                    <div key={index} className="mb-3 border p-1">
                      <div className="d-flex align-items-start">
                        <img
                          src={comment.user.image ? comment.user.image : profileImage}
                          alt={comment.user.name}
                          className="avatar rounded-circle me-2"
                          style={{ width: '40px', height: '40px' }}
                        />
                        <div className="position-relative flex-grow-1">
                          <div className="col">
                            <span><strong>{comment.user.name}</strong></span>
                            <small className="text-muted ms-2">
                              {/* {formatDate(comment.date)} */}
                              {formatDistance(subDays(comment.date, 0), new Date(), { addSuffix: true })}
                            </small>
                          </div>
                          <p className="mb-0" style={{ fontSize: "13px" }}>{comment.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeAnnouncement;
