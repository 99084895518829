import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axiosInstance from '../../service/axiosInstance';
import CoffeeKnowledgeForm from './CoffeeKnowledgeForm';
import CoffeeKnowledgeTable from './CoffeeKnowledgeTable';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CoffeeKnowledgeApp = ({ employeesData }) => {
  const [sessions, setSessions] = useState([]);
  const [currentSession, setCurrentSession] = useState(null);

  const token = localStorage.getItem('jwt');
  const headers = useMemo(() => ({
    Authorization: `Bearer ${token}`
  }), [token]);

  const fetchSessions = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/coffeeKnowledge', { headers });
      setSessions(response.data);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      toast.error('Error fetching sessions');
    }
  }, [headers]);

  useEffect(() => {
    fetchSessions();
  }, [headers, fetchSessions]);

  const handleAddSession = (session) => {
    if (currentSession !== null) {
      axiosInstance.put(`/api/coffeeKnowledge/${sessions[currentSession]._id}`, session, { headers })
        .then(response => {
          const updatedSessions = sessions.map((s, index) =>
            index === currentSession ? response.data : s
          );
          setSessions(updatedSessions);
          fetchSessions()
          setCurrentSession(null);
          toast.success('Session updated successfully');
        })
        .catch(error => {
          console.error('Error updating session:', error);
          toast.error('Error updating session');
        });
    } else {
      axiosInstance.post('/api/coffeeKnowledge', session, { headers })
        .then(response => {
          setSessions([...sessions, response.data]);
          fetchSessions()
          toast.success('Session added successfully');
        })
        .catch(error => {
          console.error('Error adding session:', error);
          toast.error('Error adding session');
        });
    }
  };

  const handleEditSession = (index) => {
    setCurrentSession(index);
  };

  const handleDeleteSession = (sessionId) => {
    axiosInstance.delete(`/api/coffeeKnowledge/${sessionId}`, { headers })
      .then(() => {
        toast.success('Session deleted successfully');
        setSessions(sessions.filter(session => session._id !== sessionId));
      })
      .catch(error => {
        console.error('Error deleting session:', error);
        toast.error('Error deleting session');
      });
  };

  return (
    <>
      <div className='shadow mt-4 p-4'>
        <ToastContainer/>
        <h4>Coffee and Knowledge Form</h4>
        <hr />
        <CoffeeKnowledgeForm
          onSubmit={handleAddSession}
          currentSession={sessions[currentSession]}
          employeesData={employeesData}
        />
      </div>
      <div className='shadow mt-4 p-4'>
        <h4>Coffee and Knowledge Sessions</h4>
        <hr />
        <CoffeeKnowledgeTable
          sessions={sessions}
          onEdit={handleEditSession}
          onDelete={handleDeleteSession}
        />
      </div>
    </>
  );
};

export default CoffeeKnowledgeApp;