import React, { useEffect } from "react";
import afxImg from '../assets/img/case-study/afx.jpg'
import apacImg from '../assets/img/case-study/apac.png'
import materialImg from '../assets/img/case-study/material.jpg'
import teraImg from '../assets/img/case-study/teraExchange.png'
import { Link } from "react-router-dom";

export const CaseStudy = () => {
  useEffect(() => {
    document.title = 'H&H - Case-study';
  }, []);
  return (
    <>
    <div className="parallax case-study-img" >
      <div className="parallax-overlay"></div>
      <div className=''>
          <h1 className="">Case-Study</h1>
      </div>
    </div>
    <div className="container" data-aos-once="true" data-aos="fade-up">
        <div className="row mx-1 col-xl-8 d-flex justify-content-center">
            <h1 className=" my-5 text-danger heading-size">Exchanges Made Easy for Today’s Trading Enterprises</h1>
            <p className="fs-5">Discover real examples of how Connamara Technologies’ advanced exchange platform and matching engine has empowered companies of all shapes and sizes to launch and manage their exchange.</p>
        </div>
    </div>
      <div className="container my-5">
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-5">
          <div className="col" data-aos-once="true" data-aos="zoom-in">
            <div className="card h-100 w-100 rounded-5 p-4" style={{width: "18rem"}}>
              <img
                src={materialImg}
                className="card-img-top rounded-4"
                alt="...1"
                style={{height: "200px"}}
              />
              <div className="card-body d-flex flex-column justify-content-between m-0 p-0">
                <h5 className="card-title my-3">NEW EXCHANGES AND MARKETPLACES</h5>
                <h6 className="card-subtitle mb-5 text-muted">MaterialsXchange</h6>
                <Link to={'/case-study/material-exchange'} className="btn-button  align-self-end">
                  Read
                </Link>
              </div>
            </div>
          </div>

          <div className="col" data-aos-once="true" data-aos="zoom-out">
            <div className="card h-100 w-100 rounded-5 p-4" style={{width: "18rem"}}>
              <img
                src={afxImg}
                className="card-img-top rounded-4"
                alt="...2"
                style={{height: "200px"}}
              />
              <div className="card-body d-flex flex-column justify-content-between m-0 p-0">
                <h5 className="card-title my-3">ESTABLISHED EXCHANGES</h5>
                <h6 className="card-subtitle mb-5 text-muted">
                  American Financial Exchange (AFX)
                </h6>
                <Link to={'/case-study/AFX'} className="btn-button align-self-end">
                  Read
                </Link>
              </div>
            </div>
          </div>

          <div className="col" data-aos-once="true" data-aos="fade-right">
            <div className="card h-100 w-100 rounded-5 p-4" style={{width: "18rem"}}>
              <img
                src={teraImg}
                className="card-img-top rounded-4"
                alt="...3"
                style={{height: "200px"}}
              />
              <div className="card-body d-flex flex-column justify-content-between m-0 p-0">
                <h5 className="card-title my-3">NEW EXCHANGES AND MARKETPLACES</h5>
                <h6 className="card-subtitle mb-5 text-muted">
                  TeraExchange Leverages EP3 Platform to Power Swap Execution
                  Facility
                </h6>
                <Link to={'/case-study/Tera-Exchange'} className="btn-button align-self-end">
                  Read
                </Link>
              </div>
            </div>
          </div>

          <div className="col" data-aos-once="true" data-aos="fade-left">
            <div className="card h-100 w-100 rounded-5 p-4" style={{width: "18rem"}}>
              <img
                src={apacImg}
                className="card-img-top rounded-4"
                alt="...4"
                style={{height: "200px"}}
              />
              <div className="card-body d-flex flex-column justify-content-between m-0 p-0">
                <h5 className="card-title my-3">EMERGING MARKETS</h5>
                <h6 className="card-subtitle mb-5 text-muted">
                  APAC Bourse Utilizes EP3’s Flexibility to Deploy On-Prem
                </h6>
                <Link to={'/case-study/APACBourse'} className="btn-button align-self-end">
                  Read
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
