import React, { useEffect, useState } from 'react';
import axiosInstance from '../service/axiosInstance';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEdit, FaEye, FaLock, FaMinus } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import DatePicker from 'react-datepicker';

function EmployeeDetails({ infoData }) {
  const [candidateData, setCandidateData] = useState( infoData );
  const [workExperience, setWorkExperience] = useState( [])
  const [checked, setChecked] = useState(false)
  const [editable, setEditable] = useState(false)
  
  const { id } = useParams();
  var token = localStorage.getItem('jwt');
  
  useEffect(() => {
    // console.log("workExperience", workExperience)
    const fetchData = () => {
      axiosInstance.get(`/api/profile-details/${id}`, { 
        headers: {
          Authorization: `Bearer ${token}`
        }
       })
      .then(response => {
        // console.log(response.data)
          setCandidateData(response.data);
          setWorkExperience(response.data.workExperiences);
      })
      .catch(error => {
          console.error('Error fetching candidate data:', error.message);
      });
    };
  
    fetchData();
  }, [id, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
      setCandidateData({ ...candidateData, [name]: value });
  };


  const handleWorkChange = (e, index) => {
    const { name, value } = e.target;
    setWorkExperience(prevWorkExperience => {
      const updatedExperience = [...prevWorkExperience];
      updatedExperience[index] = {
        ...updatedExperience[index],
        [name]: value
      };
      return updatedExperience;
    });
  };
  const addRow = () => {
    setWorkExperience([...workExperience, {
      companyName: '',
      jobTitle: '',
      fromDate: '',
      toDate: '',
      jobDescription: '',
      relevant: true 
    }]);
  };

  const removeRow = (indexToRemove) => {
    setWorkExperience(prevWorkExperience => {
      const updatedExperience = prevWorkExperience.filter((experience, index) => index !== indexToRemove);
      return updatedExperience;
    });
  };
  
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!/^[0-9]{6}$/.test(candidateData.presentPostalCode)) {
      validationErrors.presentPostalCode = "Please enter a valid 6-digit postal code.";
    }
    if (!/^[0-9+.]*$/.test(candidateData.experience)) {
      validationErrors.experience = "Please enter a valid number for experience.";
    }
    // if (!/^\d*\.?\d*$/.test(candidateData.currentSalary)) {
    //   validationErrors.currentSalary = "Please enter a valid number for current salary.";
    // }
    if (!candidateData.firstName || !/^[a-zA-Z]+$/.test(candidateData.firstName)) {
      validationErrors.firstName = 'First name should contain only letters';
    }
    
    if (!candidateData.lastName || !/^[a-zA-Z]+$/.test(candidateData.lastName)) {
      validationErrors.lastName = 'Last name should contain only letters';
    }      
    
    if (!candidateData.panNumber && candidateData.panNumber.length !== 10) {
    validationErrors.panNumber = 'PAN number should be 10 characters long';
    }
    
    if (!candidateData.aadhaarNumber && !/^\d{16}$/.test(candidateData.aadhaarNumber)) {
      validationErrors.aadhaarNumber = 'Aadhaar number should be 12 numeric characters long';
    }
    if (!candidateData.phone || !/^\d{10}$/.test(candidateData.phone)) {
      validationErrors.phone = 'Phone number should be 10 numeric characters long';
    }
    setErrors(validationErrors);

    const formData = {
      ...candidateData,
      workExperiences: workExperience,
      name: candidateData.firstName + " " + candidateData.lastName
    }

    if (Object.keys(validationErrors).length === 0) {
      axiosInstance.put(`/api/profile-details/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          toast.success('Form submitted successfully')
        })
        .catch(error => {
          if (error.response) {
            console.error('Error submitting candidate form:', error.response.data);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error sending request:', error.message);
          }
        });
      };
  };


  const handleCheckChange = () => {
    setChecked(!checked);
    if (!checked) {
      setCandidateData(prevDetails => ({
        ...prevDetails,
        permanentAddress: candidateData.presentAddress,
        permanentState: candidateData.presentState,
        permanentPostalCode: candidateData.presentPostalCode
      }));
    }
  }

  const handleToggleEditMode = () => {
    setEditable(!editable)
  }

  return (
    <>
    <div className="container d-flex justify-content-between fw-bold fs-4 align-items-center my-4">
      <div>Personal Information <span className="text-danger fs-4"><FaLock/></span></div>
      <button className="btn btn-danger" onClick={handleToggleEditMode}>
        {editable ? <FaEye /> : <FaEdit />}
      </button>
    </div>
    <div className="container p-2 shadow input-field gap-3">

    {candidateData && 
      <form onSubmit={handleSubmit}>
        <div className="bg-light d-flex justify-content-between align-items-center">
          <div className="my-3 ms-3 fw-bold fs-4">Candidate Details</div>
          {editable && 
            <button type="submit" className='btn btn-button'>Submit</button>
          }
        </div>
        <hr className='mt-0' />
        <div className="p-4 row row-cols-lg-2 row-cols-md-2">
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="firstName" className="form-label font-sub-title mb-0">First Name</label>
            </div>
            <div className="col-lg-8">
              <input 
                // className='form-control-announce'
                className={candidateData.firstName && !/^[a-zA-Z]+$/.test(candidateData.firstName) ? "form-control is-invalid" : "form-control-announce"} 
                pattern="^[a-zA-Z]+$" 
                title="First name should contain only letters."
                type="text" name="firstName" placeholder='First Name' value={candidateData.firstName} onChange={handleInputChange} readOnly={!editable} />
              {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="lastName" className="form-label font-sub-title mb-0">Last Name</label>
            </div>
            <div className="col-lg-8">
              <input
                // className='form-control-announce'
                className={candidateData.lastName && !/^[a-zA-Z]+$/.test(candidateData.lastName) ? "form-control is-invalid" : "form-control-announce"} 
                pattern="^[a-zA-Z]+$" 
                title="Last name should contain only letters."
                type="text" name="lastName" placeholder='Last Name' value={candidateData.lastName} onChange={handleInputChange} readOnly={!editable} />
                {errors.lastName && <div className="text-danger">{errors.lastName}</div>}

            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="email" className="form-label font-sub-title mb-0">Email</label>
            </div>
            <div className="col-lg-8">
              <input className='form-control-announce' type="email" name="email" placeholder='Email' value={candidateData.email} onChange={handleInputChange} readOnly/>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="phone" className="form-label font-sub-title mb-0">Phone</label>
            </div>
            <div className="col-lg-8">
              <input 
                // className='form-control-announce'
                className={candidateData.phone && !/^\d{10}$/.test(candidateData.phone) ? "form-control is-invalid" : "form-control-announce"} 
                pattern="^\d{10}$" 
                title="Phone number should be 10 numeric characters long."
                type="tel" name="phone" placeholder='Phone' value={candidateData.phone} onChange={handleInputChange} readOnly={!editable} />
              {errors.phone && <div className="text-danger">{errors.phone}</div>}

            </div>
          </div>
          <div>
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label htmlFor="aadhaarNumber" className="form-label font-sub-title mb-0">Aadhaar</label>
              </div>
              <div className="col-lg-9">
                <input className='form-control-announce' type="text" name="aadhaarNumber" placeholder='Aadhaar Number' value={candidateData.aadhaarNumber} onChange={handleInputChange} readOnly/>
                {errors.aadhaarNumber && <div className="text-danger">{errors.aadhaarNumber}</div>}

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label htmlFor="panNumber" className="form-label font-sub-title mb-0">PAN Number</label>
              </div>
              <div className="col-lg-9">
                <input className='form-control-announce' type="text" name="panNumber" placeholder='PAN Number' value={candidateData.panNumber} onChange={handleInputChange} readOnly/>
                {errors.panNumber && <div className="text-danger">{errors.panNumber}</div>}
              </div>
            </div>
            {/* dateOfBirth */}
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label htmlFor="dateOfBirth" className="form-label font-sub-title mb-0">Date Of Birth</label>
              </div>
              <div className="col-lg-9">
                <DatePicker
                  selected={candidateData.dateOfBirth}
                  onChange={(dateOfBirth) => handleInputChange({ target: { name: 'dateOfBirth', value: dateOfBirth } })}
                  className="form-control-announce w-auto cursor-pointer"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  placeholderText='Date Of Birth'
                  required
                  readOnly={!editable}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
        </div>
          <hr className='mb-0' />
        <div className="bg-light d-flex justify-content-between align-items-center">
          <div className="my-3 ms-3 fw-bold fs-4">Address Details</div>
          {editable && 
            <button type="submit" className='btn btn-button'>Submit</button>
          }
        </div>
        <hr className='mt-0' />
        <div className="p-4">

          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="presentAddress" className="form-label font-sub-title mb-0">Present Address</label>
            </div>
            <div className="col-lg-6">
              <input className='form-control-announce' type="text" name="presentAddress" placeholder='Present Address' value={candidateData.presentAddress} onChange={handleInputChange} required readOnly={!editable}/>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="presentState" className="form-label font-sub-title mb-0"></label>
            </div>
            <div className="col-lg-6">
              <input className='form-control-announce' type="text" name="presentState" placeholder='Present State' value={candidateData.presentState} onChange={handleInputChange} required readOnly={!editable} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="presentPostalCode" className="form-label font-sub-title mb-0"></label>
            </div>
            <div className="col-lg-6">
              <input 
                // className='form-control-announce' 
                type="text" 
                name="presentPostalCode" 
                placeholder='Enter Present Postal Code' 
                value={candidateData.presentPostalCode} 
                onChange={handleInputChange} 
                required 
                readOnly={!editable}
                pattern="[0-9]{6}" 
                title="Please enter a valid 6-digit postal code."
                className={candidateData.presentPostalCode && !/^[0-9]{6}$/.test(candidateData.presentPostalCode) ? "form-control is-invalid" : "form-control-announce"}
              />
            </div>
            {errors.presentPostalCode && <div className="invalid-feedback">{errors.presentPostalCode}</div>}
          </div>


            <div className="row mb-3">
              <div className="col-lg-2 d-flex align-items-center">
                <label htmlFor="permanentAddress" className="form-label font-sub-title mb-0">Permanent Address</label>
              </div>
              <div className="col-lg-6">
                <div className="d-flex">
                  <input className='' type="checkbox" name="permanentAddress" placeholder='Permanent Address' checked={checked} onChange={handleCheckChange} />
                  <p className='mb-0 ps-2'>Same as present Address</p>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 d-flex align-items-center">
                <label htmlFor=""></label>
              </div>
              <div className="col-lg-6">
                <input
                  name="permanentAddress"
                  placeholder="Permanent Address"
                  className='form-control-announce'
                  value={checked ? candidateData.presentAddress : candidateData.permanentAddress} 
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 d-flex align-items-center">
                <label htmlFor=""></label>
              </div>
              <div className="col-lg-6">
                <input
                  name="permanentState"
                  placeholder="Permanent State"
                  className='form-control-announce'
                  value={checked ? candidateData.presentState : candidateData.permanentState} 
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 d-flex align-items-center">
                <label htmlFor=""></label>
              </div>
              <div className="col-lg-6">
                <input
                  name="permanentPostalCode"
                  className='form-control-announce'
                  placeholder="Permanent Postal Code"
                  value={checked ? candidateData.presentPostalCode : candidateData.permanentPostalCode} 
                  onChange={handleInputChange}
                />
              </div>
            </div>





          {/* </div> */}
        </div>
        <hr className='mb-0' />
        <div className="bg-light d-flex justify-content-between align-items-center">
          <div className="my-3 ms-3 fw-bold fs-4">Professional Details</div>
          {editable && 
            <button type="submit" className='btn btn-button'>Submit</button>
          }
        </div>
        <hr className='mt-0' />
        <div className="p-4 row row-cols-lg-2 row-cols-md-2">

          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="experience" className="form-label font-sub-title mb-0">Experience</label>
            </div>
            <div className="col-lg-7">
              <input type="text" name="experience" placeholder='Experience' value={candidateData.experience} onChange={handleInputChange} 
                className={candidateData.experience && !/^[0-9]*$/.test(candidateData.experience) ? "form-control is-invalid" : "form-control-announce"} 
                pattern="[0-9]*" 
                title="Please enter a valid number for experience."
                required 
                readOnly={!editable}
              />
            </div>
            {errors.experience && <div className="invalid-feedback">{errors.experience}</div>}
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="sourceOfHire" className="form-label font-sub-title mb-0">Source Of Hire</label>
            </div>
            <div className="col-lg-7">
              <select className='form-control-announce bg-white' name="sourceOfHire" id="sourceOfHire" value={candidateData.sourceOfHire} onChange={handleInputChange} required readOnly={!editable}>
                <option value="">Please select</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Naukri">Naukri</option>
                <option value="Reference">Reference</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="highestQualification" className="form-label font-sub-title mb-0">Highest Qualification</label>
            </div>
            <div className="col-lg-7">
              <input className='form-control-announce' type="text" name="highestQualification" placeholder='Highest Qualification' value={candidateData.highestQualification} onChange={handleInputChange} required readOnly={!editable} />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="currentSalary" className="form-label font-sub-title mb-0">Department</label>
            </div>
            <div className="col-lg-7">
              <select className='form-control-announce bg-white' value={candidateData.department} name="department" id="department" onChange={handleInputChange} readOnly disabled required>
                <option value="">Please select</option> 
                <option value="HR Management">HR Management</option>
                <option value="Top Level Management">Top Level Management</option>
                <option value="Quality">Quality</option>
                <option value="Project Management">Project Management</option>
                <option value="Full Stack Developer">Full Stack Developer</option>
                <option value="Frontend Developer">Frontend Developer</option>
                <option value="Backend Developer">Backend Developer</option>
                <option value="DevOps">DevOps</option>
                <option value="Admin">Admin</option>
                <option value="Developer">Developer</option>
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="location" className="form-label font-sub-title mb-0">Location</label>
            </div>
            <div className="col-lg-7">
              <input className='form-control-announce' type="text" name="location" placeholder='Location' value={candidateData.location} onChange={handleInputChange} required readOnly={!editable} />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="department" className="form-label font-sub-title mb-0">Designation</label>
            </div>

            <div className="col-lg-7">
              <select className='form-control-announce bg-white' value={candidateData.currentSalary} name="currentSalary" id="currentSalary" onChange={handleInputChange} readOnly disabled required>
                <option value="">Please select</option> 
                <option value="HR Manager">HR Manager</option>
                <option value="Director">Director</option>
                <option value="Quality/Tester">Quality/Tester</option>
                <option value="Project Manager">Project Manager</option>
                <option value="Junior DevOps">Junior DevOps</option>
                <option value="Serior DevOps">Serior DevOps</option>
                <option value="Senior Solution Architect">Senior Solution Architect</option>
                <option value="Solution Architect">Solution Architect</option>
                <option value="Senior Software Engineer">Senior Software Engineer</option>
                <option value="Software Engineer">Software Engineer</option>
              </select>
            </div>
          </div>
          <div className="row row-cols-1 mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="skillSet" className="form-label font-sub-title mb-0">Skill Set</label>
            </div>
            <div className="col-lg-7">
              <textarea className='form-control-announce' type="text" name="skillSet" placeholder='Skill Set' value={candidateData.skillSet} onChange={handleInputChange} required readOnly={!editable} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4 mt-1 d-flex align-items-start">
              <label htmlFor="employeeType" className="form-label font-sub-title mb-0">Employee Type</label>
            </div>
            <div className="col-lg-7 d-flex align-items-start">
              <select className='form-control-announce bg-white' value={candidateData.employeeType} name="employeeType" id="employeeType" onChange={handleInputChange} readOnly disabled required>
                <option value="">Please select</option> 
                <option value="Permanent Employee">Permanent Employee</option>
                <option value="Contractual Employee">Contractual Employee</option>
              </select>
            </div>
          </div>
        </div>
        <div className="ms-3 fw-bold fs-4">Others</div>
        <div className="p-4">

          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="tentativeJoiningDate" className="form-label font-sub-title mb-0">Tentative Joining Date</label>
            </div>
            <div className="col-lg-6">
              <DatePicker
                  selected={candidateData.tentativeJoiningDate ? new Date(candidateData.tentativeJoiningDate) : new Date()}
                  onChange={(tentativeJoiningDate) => handleInputChange({ target: { name: 'tentativeJoiningDate', value: tentativeJoiningDate } })}
                  className="form-control-announce w-auto cursor-pointer"
                  id="tentativeJoiningDate"
                  name="tentativeJoiningDate"
                  placeholderText='Date Of Birth'
                  required
                  readOnly={!editable}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
            </div>
          </div>
        </div>

        <hr className='mb-0' />
        <div className="bg-light d-flex justify-content-between align-items-center">
          <div className="my-3 ms-3 fw-bold fs-4">Work Experience</div>
          {editable &&
            <div className='btn border' onClick={addRow}><IoAdd /> Add Row</div>
          }
        </div>
        <hr className='mt-0' />
        <div className="d-flex justify-content-end">
        </div>
        <div className='table-responsive border border-dark'>
          <table className='border-0'>
            <thead>
              <tr className='fw-bold'>
                <td className='text-nowrap'>Company Name</td>
                <td className='text-nowrap'>Job Title</td>
                <td className='text-nowrap'>From Date</td>
                <td className='text-nowrap'>To Date</td>
                <td className='text-nowrap'>Job Description</td>
                <td className='text-nowrap'>Relevant</td>
              </tr>
            </thead>
            <tbody>
            {workExperience?.map((experience, index) => (
              <tr key={index}>
                <td className='text-nowrap'><input readOnly={!editable} type="text" name="companyName" className='form-control-announce' value={experience.companyName} onChange={(e) => handleWorkChange(e, index)} /></td>
                <td className='text-nowrap'><input readOnly={!editable} type="text" name="jobTitle" className='form-control-announce' value={experience.jobTitle} onChange={(e) => handleWorkChange(e, index)} /></td>
                <td className='text-nowrap'><input readOnly={!editable} type="date" name="fromDate" className='form-control-announce' value={experience.fromDate && experience.fromDate.split('T')[0]} onChange={(e) => handleWorkChange(e, index)} /></td>
                <td className='text-nowrap'><input readOnly={!editable} type="date" name="toDate" className='form-control-announce' value={experience.toDate && experience.toDate.split('T')[0]} onChange={(e) => handleWorkChange(e, index)} /></td>
                <td className='text-nowrap'><textarea readOnly={!editable} name="jobDescription" className='form-control-announce' value={experience.jobDescription} onChange={(e) => handleWorkChange(e, index)} cols="30" rows="1"></textarea></td>
                <td className='text-nowrap'>
                  <select readOnly={!editable} name="relevant" className='form-control-announce' value={experience.relevant} onChange={(e) => handleWorkChange(e, index)}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </td>
                {editable &&
                  <td className='text-nowrap'><div className='text-danger cursor-pointer' onClick={() => removeRow(index) }><FaMinus /></div></td>
                }
              </tr>
            ))}
            </tbody>
          </table>

        </div>
          
          {editable && 
            <div className="d-flex justify-content-center">
              <button type="submit" className='btn btn-button'>Submit</button>
            </div>
          }
      </form>
}
    </div>
    </>
  );
}

export default EmployeeDetails;

