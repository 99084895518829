import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "./css/application.css";
import jobPhoto from '../assets/img/career/Job-application1.jpg'
import axios from "axios";

const ApplicationForm = ({ datas, job }) => {
  const [sending, setSending] = useState(false);

  const [formData, setFormData] = useState({
    job: "",
    name: "",
    email: "",
    file: null,
    linkedin: "",
    phone: ""
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    file: "",
    phone: ""
  });

  var textInput = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      job: job || "",
    }));
    if(job){
      setTimeout(() => {
        textInput.current?.focus();
      },100);
    }
  }, [job]);

  const handleChange = useCallback((e) => {
    const { name, value, type } = e.target;
    const val = type === 'file' ? e.target.files[0] : value;
    
    if (type === 'file') {
      const maxSizeInBytes = 1000 * 1024; // 1000 KB

      if (val && val.size > maxSizeInBytes) {
        setErrors({ ...errors, [name]: "File size exceeds the maximum limit (1 MB)" });
        return;
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }

    // setFormData({ ...formData, [name]: val });
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: val,
    }));
  }, [errors]);

  const handlereset = () => {
    setFormData({
      job: "",
      name: "",
      email: "",
      file: null,
      linkedin: "",
      phone: ""
    });
    document.getElementById("career-form").reset(); 
    
  }


  const handleSubmit = (e) => {
    e.preventDefault();
  
    let errors = {};

    if (!formData.job) {
      errors = { ...errors, job: "Please select" };
    }
  
    if (!formData.name) {
      errors = { ...errors, name: "Name is required" };
    } else if (!/^[a-zA-Z ]+$/.test(formData.name)) {
      errors = { ...errors, name: "Name should only contain letters and spaces" };
    }
  
    if (!formData.phone) {
      errors = { ...errors, phone: "Phone number is required" };
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors = { ...errors, phone: "Phone number should be 10 digits" };
    }
  
    if (!formData.email) {
      errors = { ...errors, email: "Email is required" };
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors = { ...errors, email: "Invalid email format" };
    }
  
    if (!formData.file) {
      errors = { ...errors, file: "Please upload your resume" };
    } else if (formData.file.size > 1000 * 1024) { // 1000 KB
      errors = { ...errors, file: "File size should not exceed 1 MB" };
    }
  
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {  
      setSending(true);
    
      axios.post("http://13.234.136.33:5000/career", formData,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.status === 'success'){
          toast.success("Message Sent."); 
          handlereset();
        } else if (response.data.status === 'fail'){
          toast.error("Message failed to send.");
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400 && error.response.data.error === 'Email already exists') {
          toast.error("Email already exists.");
          handlereset();

        } else {
          console.error('Error sending message:', error);
          toast.error("An error occurred while sending the message."+error);
        }
      })
      .finally(() => {
        setSending(false);
      });
      setErrors({});
    }
  };  

  return (
    <>
      <div id="form" className="application-form-container my-5">
        <div
          className="position-relative"
          style={{
            height: "275px",
            overflow: "hidden",
          }}
        >
          <div
            className="position-absolute w-100 h-100"
            style={{
              backgroundImage: `url(${jobPhoto})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              transform: "translateY(-20%)",
              zIndex: "-1",
              backgroundAttachment: "fixed"
            }}
          ></div>
        </div>
        <h2 className="text-center fw-bold fs-1 mb-4">Fill out your details</h2>
        <Form id="career-form" onSubmit={handleSubmit} data-aos-once="true" data-aos="zoom-in">
          <Form.Group controlId="job">
            <Form.Label className="mt-1">APPLY FOR</Form.Label>
            <Form.Select
              name="job"
              value={ formData.job }
              onChange={handleChange}
              className="shadow-none"              
            >
              <option value="">Please Select</option>
              {datas.map((data, index) => {
                return <option key={index} value={data.title}>{data.title}</option>;
              })}
            </Form.Select>
            {errors.job && <small className="text-danger">{errors.job}</small>}
          </Form.Group>
          <Form.Group controlId="name" className="mt-2">
            <Form.Label className="mt-1">Name*</Form.Label>
            <Form.Control
              className="mb-0 shadow-none"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              ref={textInput}
              
            />
            {errors.name && <small className="text-danger">{errors.name}</small>}
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label className="mt-1">Email*</Form.Label>
            <Form.Control
              className="mb-0 shadow-none"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              
            />
            {errors.email && <small className="text-danger">{errors.email}</small>}
          </Form.Group>
          <Form.Group controlId="file">
            <Form.Label className="mt-1">Upload file(.docx,.pdf)*</Form.Label>
            <Form.Control
              className="mb-0 shadow-none"
              type="file"
              name="file"
              ref={fileInputRef}
              accept=".docx,.pdf"
              onChange={handleChange}
            />
            {errors.file && <small className="text-center text-danger">{errors.file}</small>}
          </Form.Group>
          <Form.Group controlId="linkedin">
            <Form.Label className="mt-1">LinkedIn Profile</Form.Label>
            <Form.Control
              className="mb-0 shadow-none"
              type="text"
              name="linkedin"
              placeholder="LinkedIn Profile URL"
              value={formData.linkedin}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label className="mt-1">Phone*</Form.Label>
            <Form.Control
              className="mb-0 shadow-none"
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              
            />
            {errors.phone && <small className="text-danger">{errors.phone}</small>}
          </Form.Group>
          <div className="text-center">
            <button
              variant="primary"
              className="btn-button mt-3 text-center"
              type="submit"
              disabled={sending}
            >
              {sending ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Sending...
                </>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ApplicationForm;
