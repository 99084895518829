import React from 'react'
import image1 from '../assets/img/error/error.jpg'
export const Error = () => {
  return (
    <>
    <div className="container mb-5 pb-5">
        <div className="error-container mb-5">
        <img src={image1} alt="Error Illustration" className="error-image"/>
        <h1 className="error-heading">404 - Page Not Found</h1>
        <p className="error-message">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        <a href="/" className="btn btn-button">Go to Homepage</a>
        {/* <form action="/search" method="get" className="mt-3">
            <input type="text" name="q" className="form-control" placeholder="Search..."/>
            <button type="submit" className="btn btn-primary mt-2">Search</button>
        </form> */}
        {/* <p className="mt-3">Contact <a href="/support">support</a> for assistance.</p> */}
        </div>
    </div>
    </>
  )
}
