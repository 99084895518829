import React, { useState } from 'react';
import axiosInstance from '../../service/axiosInstance';
import { Table, Button, Offcanvas, Tabs, Tab } from 'react-bootstrap';
import ExitManagement from './ExitManagement';
import { FaEye, FaTrash } from 'react-icons/fa'; 
import EditResignationForm from './EditResignationForm';
import { toast } from 'react-toastify';

const ResignationTable = ({ infoData, headers, resignations, fetchResignations }) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectedResignationId, setSelectedResignationId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const [key, setKey] = useState('exitManagement');


  const handleViewExitManagement = (resignation) => {
    setSelectedResignationId(resignation._id);
    setEditFormData(resignation);
    setKey('exitManagement');
    setShowOffcanvas(true);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
    setSelectedResignationId(null);
    setEditFormData(null);
  };

  const handleDeleteResignation = async (resignationId) => {
    try {
      await axiosInstance.delete(`/api/api/resignations/${resignationId}`, {headers});
      toast.success("Resignation deleted successfully")
      fetchResignations();
    } catch (error) {
      console.error('Error deleting resignation:', error);
      toast.error("Error deleted successfully")
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="ms-3 mt-3">
      <h4>Resignation Table</h4>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th className='text-nowrap'>#</th>
            <th className='text-nowrap'>Employee Name</th>
            <th className='text-nowrap'>Date of Relieving</th>
            <th className='text-nowrap'>Reason</th>
            <th className='text-nowrap'>Notice from</th>
            <th className='text-nowrap'>Notice to</th>
            <th className='text-nowrap'>Accepted?</th>
            <th className='text-nowrap text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
          {resignations.map((resignation, index) => (
            <tr key={resignation._id}>
              <td>{index + 1}</td>
              <td>{resignation.employeeId.name}</td>
              <td>{formatDate(resignation?.dateOfRelieving)}</td>
              <td>{resignation.reason}</td>
              <td className='text-nowrap'>{formatDate(resignation?.lastWorkingDay)}</td>
              <td className='text-nowrap'>{formatDate(resignation?.finalSettlementDate)}</td>
              <td>{resignation.isAccepted ? 'Yes' : 'No'}</td>
              <td className='d-flex justify-content-start gap-2'>
                <Button
                  variant="info"
                  onClick={() => handleViewExitManagement(resignation)}
                >
                  <FaEye/>
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteResignation(resignation._id)}
                >
                  <FaTrash />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end" className="offcanvas-half w-50">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {key === 'exitManagement' ? 'Exit Management' : 'Edit Resignation'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            <Tab eventKey="exitManagement" title={<span className={key === 'exitManagement' ? 'text-danger' : 'text-dark'}>Exit Management</span>}>
              {selectedResignationId && <ExitManagement resignationId={selectedResignationId} headers={headers}/>}
            </Tab>
            <Tab eventKey="editForm" title={<span className={key === 'editForm' ? 'text-danger' : 'text-dark'}>Edit Resignation</span>}>
              {editFormData && (
                <EditResignationForm
                  resignation={editFormData}
                  infoData={infoData}
                  onClose={handleCloseOffcanvas}
                  fetchResignations={fetchResignations}
                  headers={headers}
                />
              )}
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ResignationTable;
