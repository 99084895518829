import React, { useState } from 'react';
import axiosInstance from '../service/axiosInstance';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegularizationRequestEmployee = ({ onClose }) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    date: null,
    checkIn: null,
    checkOut: null,
    reason: '',
    isRegularized: true,
    adminApproval: 'pending'
  });  

  const handleChange = (date, name) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: date
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (!formData.date || !formData.checkIn || !formData.checkOut || !formData.reason) {
        return toast.error('Please fill in all fields');
      }
  
      const token = localStorage.getItem('jwt');
      const headers = {
        Authorization: `Bearer ${token}`
      };
  
      const checkInDate = new Date(formData.checkIn);
      const checkOutDate = new Date(formData.checkOut);
      if (checkInDate.getDate() !== checkOutDate.getDate() || 
          checkInDate.getMonth() !== checkOutDate.getMonth() || 
          checkInDate.getFullYear() !== checkOutDate.getFullYear()) {
        return toast.error('Check-in and Check-out must be on the same day');
      }
  
      axiosInstance.post('/api/emp-attendance/regularization-request', {
        ...formData,
        employeeId: id
      }, { headers })
        .then(() => {
          setFormData({
            date: null,
            checkIn: null,
            checkOut: null,
            reason: '',
            isRegularized: true,
            adminApproval: 'pending'
          });
          onClose();
          toast.success('Regularization request submitted successfully');
        })
        .catch(error => {
          console.error('Error submitting regularization request:', error);
          toast.error('Failed to submit regularization request');
        });
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to submit regularization request');
    }
  };
  

  return (
    <div className="container border mt-4">
      <h2 className="text-center  my-4">Request Regularization</h2>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-3 d-flex align-items-center">
            <label htmlFor="date" className="form-label">Date:</label>
          </div>
          <div className="col-md-9">
            <DatePicker
              selected={formData.date}
              onChange={(date) => handleChange(date, 'date')}
              className="form-control-announce w-auto cursor-pointer"
              id="date"
              name="date"
              placeholderText='Date'
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3 d-flex align-items-center">
            <label htmlFor="checkIn" className="form-label">Check-in:</label>
          </div>
          <div className="col-md-9">
            <DatePicker
              selected={formData.checkIn}
              onChange={(date) => handleChange(date, 'checkIn')}
              className="form-control-announce w-auto cursor-pointer"
              id="checkIn"
              name="checkIn"
              placeholderText='Check-In'
              showTimeSelect
              required
              dateFormat="MM/dd/yyyy h:mm aa"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3 d-flex align-items-center">
            <label htmlFor="checkOut" className="form-label">Check-out:</label>
          </div>
          <div className="col-md-9">
            <DatePicker
              selected={formData.checkOut}
              onChange={(date) => handleChange(date, 'checkOut')}
              className="form-control-announce w-auto cursor-pointer"
              id="checkOut"
              name="checkOut"
              placeholderText='Check-Out'
              showTimeSelect
              required
              dateFormat="MM/dd/yyyy h:mm aa"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3 d-flex align-items-center">
            <label htmlFor="reason" className="form-label">Reason:</label>
          </div>
          <div className="col-md-9">
            <textarea
              className="form-control-announce"
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={(e) => handleChange(e.target.value, 'reason')}
              required
            ></textarea>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 mx-auto">
            <button type="submit" className="btn btn-button ">Submit Request</button>
          </div>
        </div>
      </form>
    </div>

  );
};

export default RegularizationRequestEmployee;
