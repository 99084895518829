import React, { useState, useEffect, useCallback, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../service/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import { FaCheck, FaTimes } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';

function LeaveForm({ employeesData, onUpdateData }) {
  const [adminLeaves, setAdminLeaves] = useState([]);
  const [newLeave, setNewLeave] = useState({
    empName: '',
    leaveType: '',
    startDate: '',
    endDate: '',
    numberOfDays: 0,
    reason: ''
  });

  const [employeeFilter, setEmployeeFilter] = useState('');
  const [leaveTypeFilter, setLeaveTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('Pending');
  const [selectedYear, setSelectedYear] = useState('');
  const [employeesLeaveData, setEmployeesLeaveData] = useState([]);
  const token = localStorage.getItem('jwt');
  const headers = useMemo(()=>({
    Authorization: `Bearer ${token}`
  }),[token]);

  
  const fetchAdminLeaves = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/adminLeaves', {headers});
      setAdminLeaves(response.data);
    } catch (error) {
      console.error('Error fetching admin leaves:', error);
    }
  },[headers]);

  const fetchEmployeesData = useCallback(async () =>{
    try {
      const response = await axiosInstance.get('/users-admin', {headers})
      setEmployeesLeaveData(response.data);
      
    } catch (error) {
      console.error('Error fetching admin leaves:', error);
    }
  },[headers])

  useEffect(() => {
    fetchEmployeesData()
  }, [fetchEmployeesData])

  const getLeaveEntitlement = (type, empId) => {

    const leaveRecord = employeesLeaveData.find(leave => leave._id === empId);
    if (leaveRecord) {
        switch (type) {
            case 'annualLeave':
                return leaveRecord.annualLeaveEntitlement;
            case 'sickLeave':
                return leaveRecord.sickLeaveEntitlement;
            case 'casualLeave':
              return leaveRecord.casualLeaveEntitlement;
            case 'Half Day':
              return leaveRecord.casualLeaveEntitlement * 2;
            default:
                return null; 
        }
    } else {
        return null;
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newLeave.leaveType || !newLeave.startDate || !newLeave.endDate || !newLeave.reason) {
      toast.error('Please fill all required fields.');
      return;
    }

    if (['sickLeave', 'casualLeave', 'annualLeave', 'Half Day'].includes(newLeave.leaveType)) {
        if (newLeave.numberOfDays <= 0 || newLeave.numberOfDays > getLeaveEntitlement(newLeave.leaveType, newLeave.empName)) {
            toast.error('Invalid number of days for the selected leave type.');
            return;
        }
    }
    
    try {
      await axiosInstance.post(`/api/admin/update-entitlement-and-create-leave/${newLeave.empName}`, newLeave, {headers});
      // await axiosInstance.post('/api/adminLeaves', newLeave, {headers});
      fetchAdminLeaves();
      setNewLeave({
        empName: '',
        leaveType: '',
        startDate: '',
        endDate: '',
        numberOfDays: 0,
        reason: ''
      });
      toast.success('Leave request submitted successfully');
    } catch (error) {
      console.error('Error creating admin leave:', error);
      toast.error('Failed to submit leave request');
    }
  };
  
  useEffect(() => {
    fetchAdminLeaves();
  }, [fetchAdminLeaves]);

  const handleStartDateChange = (date) => {
    const startDate = date;
    const endDate = newLeave.endDate;
    const numberOfDays = calculateNumberOfDays(startDate, endDate);
    setNewLeave({ ...newLeave, startDate, endDate, numberOfDays });
  };
  
  const handleEndDateChange = (date) => {
    const endDate = date;
    const startDate = newLeave.startDate;
    const numberOfDays = calculateNumberOfDays(startDate, endDate);
    setNewLeave({ ...newLeave, startDate, endDate, numberOfDays });
  };
  
  
  const calculateNumberOfDays = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.ceil(differenceInDays + 1);
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); 
    const day = ('0' + date.getDate()).slice(-2);
    
    const formattedDate = `${year}-${month}-${day}`;
    
    return formattedDate;
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const filteredAdminLeaves = adminLeaves.filter((leave) => {
    if (employeeFilter && leave.postedBy.name !== employeeFilter) return false;
    if (leaveTypeFilter && leave.leaveType !== leaveTypeFilter) return false;
    if (statusFilter && leave.status !== statusFilter) return false;
    if (selectedYear && new Date(leave.startDate).getFullYear() !== parseInt(selectedYear)) return false;
    return true;
  });

  const getTotalDaysForEmployeeAndLeaveType = (empName, leaveType) => {
    return filteredAdminLeaves.reduce((totalDays, leave) => {
      if (leave.empName === empName && leave.leaveType === leaveType) {
        return totalDays + leave.numberOfDays;
      }
      return totalDays;
    }, 0);
  };

  const handleDeleteLeave = (leave) => {
    axiosInstance
      .delete(`/api/adminLeaves/${leave._id}`, {headers})
      .then(() => {
        fetchAdminLeaves();
        toast.success('Leave deleted successfully');
      })
      .catch((error) => {
        console.error('Error deleting leave:', error);
        toast.error('Failed to delete leave');
      });
  };    
  const handleChangeStatus = (leave, newStatus) => {

    updateLeaveAndEntitlement( leave._id, newStatus, leave.leaveType, leave.startDate, leave.endDate, leave.postedBy._id )

  };

  const updateLeaveAndEntitlement = (leaveId, newStatus, leaveType, startDate, endDate, postedBy) => {
    const numberOfDays = calculateNumberOfDays(startDate, endDate);
    updateEntitlement(leaveId, newStatus, leaveType, numberOfDays)
};

  const updateEntitlement = (employeeId, status, leaveType, numberOfDays) => {
    axiosInstance.put(`/api/update-leave-entitlement/${employeeId}`, { status, leaveType, numberOfDays }, { headers })
    .then(()=>{
      fetchAdminLeaves()
      toast.success(`Leave ${status}`)
      onUpdateData('Updated leave')
    })
    .catch(err => {
      toast.error(`Please try again later`)
    })
  };
  const formatLeaveType = (leaveType) => {
    switch (leaveType) {
        case 'sickLeave':
            return 'Sick Leave';
        case 'casualLeave':
            return 'Casual Leave';
        case 'annualLeave':
            return 'Annual Leave';
        default:
            return leaveType;
    }
  };

  return (
    <div className="mt-4">
      <ToastContainer/>
      <div className="w-100 shadow p-4 rounded">
        <h1 className="mb-4">Leave</h1>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-3 d-flex align-items-center">
              <label htmlFor="empName" className="form-label font-sub-title mb-0">Employee Name</label>
            </div>
            <div className="col-md-9">
              <select className="form-control-announce bg-white" id="empName" value={newLeave.empName} onChange={(e) => setNewLeave({ ...newLeave, empName: e.target.value })} required>
                <option value="">Select Employee</option>
                {employeesData.map(employee => (
                  <option key={employee._id} value={employee._id}>{employee.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3 d-flex align-items-center">
              <label htmlFor="leaveType" className="form-label font-sub-title mb-0">Leave Type</label>
            </div>
            <div className="col-md-9">
              <select className="form-control-announce bg-white" id="leaveType" value={newLeave.leaveType} onChange={(e) => setNewLeave({ ...newLeave, leaveType: e.target.value })} required>
                <option value="">Select Leave Type</option>
                <option value="Leave Without Pay">Leave Without Pay</option>
                <option value="Half Day">Half Day</option>
                <option value="sickLeave">Sick Leave</option>
                <option value="casualLeave">Casual Leave</option>
                <option value="Paternity">Paternity</option>
                <option value="Comp Off">Comp Off</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3 d-flex align-items-center">
              <label htmlFor="startDate" className="form-label font-sub-title mb-0">Date</label>
            </div>
            <div className="col-md-4 w-auto">
              <DatePicker
                selected={newLeave.startDate}
                onChange={handleStartDateChange}
                className="form-control-announce w-100 cursor-pointer"
                id="startDate"
                placeholderText='From'
                required
              />
            </div>
            <div className="col-md-5">
              <DatePicker
                selected={newLeave.endDate}
                onChange={handleEndDateChange}
                className="form-control-announce w-100 cursor-pointer"
                placeholderText='To'
                id="endDate"
                required
              />
            </div>
          </div>



          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="numberOfDays" className="form-label font-sub-title mb-0">Number of Days</label>
            </div>
            <div className="col-md-9">
              <input type="number" className="form-control-announce" id="numberOfDays" value={newLeave.numberOfDays} readOnly />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="reason" className="form-label">Reason</label>
            </div>
            <div className="col-md-9">
              <textarea className="form-control-announce" id="reason" rows="3" value={newLeave.reason} onChange={(e) => setNewLeave({ ...newLeave, reason: e.target.value })} required></textarea>
            </div>
          </div>
          <div className="text-center">
            {/* <button type="submit" className="btn btn-button">Submit</button> */}
            <button 
                type="submit" 
                className="btn btn-button" 
                disabled={
                    !newLeave.leaveType || 
                    !newLeave.startDate || 
                    !newLeave.endDate || 
                    !newLeave.numberOfDays ||
                    (['sickLeave', 'casualLeave', 'annualLeave', 'Half Day'].includes(newLeave.leaveType) && (newLeave.numberOfDays <= 0 || newLeave.numberOfDays > getLeaveEntitlement(newLeave.leaveType, newLeave.empName)))
                }
            >
                Submit
            </button>
          </div>
        </form>
      </div>

        <div className="mt-5">
          <div className="alert alert-danger">
            <h1 className="fs-4 border-bottom border-dark mb-3 pb-2">Filter Leave History</h1>
            <div className="row">
                <div className="col-md-3 mb-3 fs-6">
                    <label htmlFor="empNameFilter" className="form-label">Employee Name</label>
                    <select className="form-select" id="empNameFilter" value={employeeFilter} onChange={(e) => setEmployeeFilter(e.target.value)}>
                        <option value="">All</option>
                        {employeesData.map(employee => (
                        <option key={employee._id} value={employee.name}>{employee.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 mb-3 fs-6">
                    <label htmlFor="leaveTypeFilter" className="form-label">Leave Type</label>
                    <select className="form-select" id="leaveTypeFilter" value={leaveTypeFilter} onChange={(e) => setLeaveTypeFilter(e.target.value)}>
                      <option value="">All</option>
                      <option value="Leave Without Pay">Leave Without Pay</option>
                      <option value="Half Day">Half Day</option>
                      <option value="sickLeave">Sick Leave</option>
                      <option value="casualLeave">Casual Leave</option>
                      {/* <option value="Annual Leave">Annual Leave</option> */}
                      <option value="Paternity Leave">Paternity Leave</option>
                      <option value="Comp Off">Comp Off</option>
                    </select>
                </div>
                <div className="col-md-3 mb-3 fs-6">
                  <label htmlFor="statusFilter" className="form-label">Status</label>
                  <select className="form-select" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                    <option value="">All</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
                <div className="col-md-3 mb-3 fs-6">
                  <label htmlFor="yearFilter" className="form-label">Year</label>
                  <select className="form-select" id="yearFilter" value={selectedYear} onChange={handleYearChange}>
                    <option value="">All</option>
                    {adminLeaves.map(leave => new Date(leave.startDate).getFullYear()).filter((value, index, self) => self.indexOf(value) === index).map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
            </div>
        </div>
        <hr />
        <div className="alert-info table-responsive">
          <table className="table">
              <thead>
              <tr>
                  <th className='text-nowrap' scope="col">Employee Name</th>
                  <th className='text-nowrap' scope="col">Leave Type</th>
                  <th className='text-nowrap' scope="col">Start Date</th>
                  <th className='text-nowrap' scope="col">End Date</th>
                  <th className='text-nowrap' scope="col">Number of Days</th>
                  <th className='text-nowrap' scope="col">Reason</th>
                  <th className='text-nowrap' scope="col">Status</th>
              </tr>
              </thead>
              <tbody>
              {filteredAdminLeaves.map(leave => (
                  <tr key={leave._id}>
                      <td><div className="text-danger cursor-pointer fw-bold" type="div" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">{leave.postedBy.name}</div></td>
                      <td>{formatLeaveType(leave.leaveType)}</td>
                      <td>{formatDate(leave.startDate)}</td>
                      <td>{formatDate(leave.endDate)}</td>
                      <td>{leave.numberOfDays}</td>
                      <td>{leave.reason}</td>
                      <td>{leave.status}</td>
                      {/* <td>
                          <button className="btn btn-danger btn-sm" onClick={() => handleDeleteLeave(leave)}>
                          <FaTrash />
                          </button>
                      </td> */}
                      <td className='d-flex'>
                        
                          {leave.postedBy && leave.status === 'Pending' ? (
                              <>
                                  <button className="btn btn-success btn-sm me-1" onClick={() => handleChangeStatus(leave, 'Approved')}>
                                      <FaCheck />
                                  </button>
                                  <button className="btn btn-danger btn-sm me-1" onClick={() => handleChangeStatus(leave, 'Rejected')}>
                                      <FaTimes />
                                  </button>
                              </>
                          ) : (
                            <button className="btn btn-danger btn-sm me-1" onClick={() => handleDeleteLeave(leave)}>
                                <RiDeleteBinLine />
                            </button>
                          )}
                      </td>
                      {/* <td> */}
                      {/* <div> */}
                          <div className="offcanvas offcanvas-end"style={{ width: '50%', margin: 'auto' }} tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                            <div className="offcanvas-header">
                              <h5 id="offcanvasRightLabel">Regularize Attendance</h5>
                              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                              <ul>
                                <li>Sick Leave Entitlement: {leave.postedBy.sickLeaveEntitlement}</li>
                                <li>Casual Leave Entitlement: {leave.postedBy.casualLeaveEntitlement}</li>
                                {/* <li>Annual Leave Entitlement: {leave.postedBy.annualLeaveEntitlement}</li> */}
                              </ul>
                              {/* <RegularizationRequestEmployee onClose={toggleOffcanvas} clicked={clicked}/> */}
                            </div>
                          </div>
                        {/* </div> */}
                      {/* </td> */}
                  </tr>
              ))}
              {employeeFilter && (
                <tr>
                  {/* <td colSpan="4">Total for Employee: <span className='text-primary'>{employeeFilter}</span></td> */}
                  <td className='text-end' colSpan={12}>
                      <span className='border-start border-4 fw-bold border-primary border-2 p-2 text-primary'>LWP: {getTotalDaysForEmployeeAndLeaveType(employeeFilter, 'Leave Without Pay')}</span>
                      <span className='border-start border-4 fw-bold border-danger border-2 p-2 text-danger'>Half Day: {getTotalDaysForEmployeeAndLeaveType(employeeFilter, 'Half Day')}</span>
                      <span className='border-start border-4 fw-bold border-success border-2 p-2 text-success'>Sick: {getTotalDaysForEmployeeAndLeaveType(employeeFilter, 'sickLeave')}</span>
                      <span className='border-start border-4 fw-bold border-secondary border-2 p-2 text-secondary'>Casual: {getTotalDaysForEmployeeAndLeaveType(employeeFilter, 'casualLeave')}</span>
                      <span className='border-start border-4 fw-bold border-info border-2 p-2 text-info'>Paternity: {getTotalDaysForEmployeeAndLeaveType(employeeFilter, 'Paternity Leave')}</span>
                      <span className='border-start border-4 fw-bold border-warning border-2 p-2 text-warning'>Comp Off: {getTotalDaysForEmployeeAndLeaveType(employeeFilter, 'Comp Off')}</span>
                  </td>
                </tr>
              )}
              </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LeaveForm;
