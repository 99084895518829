import React from "react";
import teraImg from '../../assets/img/case-study/afx.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const AFX = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  return (
    <div className="mt-5 m-3">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="mb-5">
            <div className="card-body">
              <h1 className="card-title text-danger heading-size text-center">American Financial Exchange (AFX)</h1>
              <h4 className="card-text text-center">Connamara Provides Key Technology and On-going Support to American Financial Exchange</h4>
            </div>
          </div>

          <img src={teraImg} className="object-fit-cover w-100 img-fluid rounded-5" style={{height: "35rem"}} alt="" />

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Client</h1>
              <p className="card-text fs-5">The American Financial Exchange (AFX) is a self-regulated exchange that provides a transparent, centralized, and rules-based electronic platform for direct interbank lending and borrowing for American financial institutions.</p>
              <p className="card-text fs-5">AFX was launched in 2015 and offers a suite of products — such as overnight and 30-day unsecured loans, 7-day secured loans, and demand deposits — that improve transparency and efficiency in the interbank loans marketplace. The exchange has more than 150 members in 49 U.S. states and territories and several hundred additional participants through the correspondent bank market.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Challenge</h1>
              <p className="card-text fs-5">AFX, as an early-stage startup, needed a technology partner that could deliver a production-ready, secure exchange platform within constraints of time, budget, and evolving requirements.</p>
              <p className="card-text fs-5">While AFX had a solid understanding of its vision and strategy, it required help in focusing that vision into an actionable, “get-to-the-first-trade” plan. Connamara’s twenty years of experience in capital markets software delivery and nearly ten years of experience designing and building customizable matching engine and exchange solutions made us a definitive choice during AFX’s vendor selection process.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Solution</h1>
              <p className="card-text fs-5">During an initial engagement with the AFX product team, Connamara was able to bring that experience to the forefront and implement a strategy that included the requirements for a minimally viable exchange platform, road map for further enhancements, and a post-launch support and maintenance program.</p>
              <p className="card-text fs-5">Key to any successful implementation is clarity in the features and requirement for the initial deployment. AFX engaged Connamara to articulate the features and requirements that the exchange platform would need in order to launch. The two teams collaborated on defining and documenting those features, and Connamara delivered several artifacts, including a prioritized feature list focusing on the launch. A Product Roadmap was created that listed delivery milestones — complete with time and cost estimates — to be accomplished leading up to the launch, as well as milestones to be implemented once there were customers on the exchange and trading.</p>
            </div>
          </div>

          <div className="my-5">
            <div className="card-body border-top">
              <h1 className="mt-5">The Results</h1>
              <p className="card-text mb-2 fs-5">Connamara, through its experience and technology, helped AFX achieve its two strategic objectives:</p>
              <ul>
                <li className="fs-5">Establishing an organized, centralized marketplace that enables efficient “price discovery” of interbank lending rates while minimizing transaction costs.</li>
                <li className="fs-5">Establishing new interest rate benchmarks relevant to the unique needs of the small, medium, and regional banks across America.</li>
              </ul>
            </div>
          </div>
          <div className="prev-next my-5 d-flex justify-content-between px-3 align-items-center">
            <Link to="/case-study/material-exchange" className="prev-icon d-flex align-items-center" onClick={scrollToTop}>
                <FontAwesomeIcon icon={faChevronLeft} className="border rounded-5 fs-3 p-2" />
                <span className="ms-2">Previous</span>
            </Link>
            <Link to="/case-study/Tera-Exchange" className="next-icon d-flex align-items-center" onClick={scrollToTop}>
                <span className="me-2">Next</span>
                <FontAwesomeIcon icon={faChevronRight} className="border rounded-5 fs-3 p-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AFX;
