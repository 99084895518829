import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ResignationForm from './ResignationForm';
import ResignationTable from './ResignationTable';
import { ToastContainer } from 'react-toastify';
import axiosInstance from '../../service/axiosInstance';

const MainComponent = ({ infoData }) => {
  const [FormShow, setFormShow] = useState(false);
  const token = localStorage.getItem('jwt');
  const headers = useMemo(()=>({
    Authorization: `Bearer ${token}`
  }),[token]);

  const [resignations, setResignations] = useState([]);
  
  const fetchResignations = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/api/resignations', {headers});
      setResignations(response.data);
    } catch (error) {
      console.error('Error fetching resignations:', error);
    }
  }, [headers]);
  
  useEffect(() => {
    fetchResignations();
  }, [fetchResignations]);

  return (
    <div className="shadow mt-4 p-3">
      <ToastContainer/>
      <div className="d-flex justify-content-between">
        <h3>Employee Resignation</h3>
        {!FormShow && 
          <button className='btn btn-light border' onClick={()=>setFormShow(true)}>+ Resign</button>
        }
        {FormShow && 
          <button className='btn btn-danger border' onClick={()=>setFormShow(false)}>X</button>
        }
      </div>
      {FormShow && 
        <ResignationForm headers={headers} fetchResignations={fetchResignations} infoData={infoData} onSubmit={() => window.location.reload()} />
      }
      {!FormShow && (
        <>
          <ResignationTable infoData={infoData} headers={headers} resignations={resignations} fetchResignations={fetchResignations} />
        </>
      )}
    </div>
  );
};

export default MainComponent;
