import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../service/axiosInstance';

const RemoveEmployeeData = ({ employeesData, setemployeeAdded, onUpdateData }) => {
  const [userId, setUserId] = useState('');

  const token = localStorage.getItem('jwt');
  const headers = {
    Authorization: `Bearer ${token}`
  };
  
  const handleRemoveUser = async () => {
    try {
      const response = await axiosInstance.patch(`/api/remove-user/${userId}`, null, { headers });
      toast.success(response.data.message);
      setemployeeAdded('success')
      setUserId('')
      onUpdateData('removed user')
    } catch (error) {
      console.error('Error removing user:', error);
      toast.error('An error occurred while removing the user');
    }
  };  

  return (
    <div className='p-3'>
      <h4>Remove Employee Data</h4>
      <hr />
      <div className="my-4">
        <select className='form-control-announce bg-white' name="removeEmployee" id="removeEmployee" onChange={(e)=> {setUserId(e.target.value)}}>
          <option value="">Select employee</option>
          {employeesData.map((item, index)=> {
            return(
              <option key={ index } value={item._id}>{item.name}</option>
            )
          })}
        </select>
      </div>
      <div className="text-center">
        <button className='btn-button' onClick={handleRemoveUser}>Remove Employee</button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RemoveEmployeeData;