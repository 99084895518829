import React, { useEffect, useState } from 'react';
import axiosInstance from '../service/axiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaDownload, FaFilePdf, FaFileWord, FaFileExcel, FaFileImage, FaFileAlt } from 'react-icons/fa'; // Import necessary icons
import BASE_URL from '../service/config';

const getFileIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return <FaFilePdf className="me-2 text-danger" />;
    case 'doc':
    case 'docx':
      return <FaFileWord className="me-2 text-primary" />;
    case 'xls':
    case 'xlsx':
      return <FaFileExcel className="me-2 text-success" />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return <FaFileImage className="me-2 text-warning" />;
    default:
      return <FaFileAlt className="me-2" />;
  }
};

const EmployeeFiles = () => {
  const [files, setFiles] = useState([]);

  const token = localStorage.getItem('jwt');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axiosInstance.get('/api/admin/files', { headers });
      const employeeFiles = response.data.filter(file => file.permissions.view.employee);
      setFiles(employeeFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  return (
    <div className="container shadow p-4 table-responsive">
      <h4 className="text-xl fw-semibold mb-4">Employee Files</h4>
      {files.length === 0 ? (
        <p className='alert alert-info' >No files available for employee view.</p>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">File Name</th>
              <th scope="col">Description</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {files.map(file => (
              <tr key={file._id}>
                <td className='text-nowrap' >
                  {getFileIcon(file.fileName)}
                  {file.fileName.split('-').slice(1).join('-')}
                </td>
                <td>{file.description}</td>
                <td className='text-nowrap'>
                  {file.permissions.download.employee && (
                    <a 
                      className="btn btn-secondary" 
                      href={`${BASE_URL}/uploads/${file.fileName}`} 
                      download={file.fileName}
                      target="_blank"
                    >
                      <FaDownload className="me-2" /> Download
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EmployeeFiles;
