import React, { useEffect, useState } from "react";
import image from "../assets/img/question.png";
import "./css/serviceTab.css";

const DedicatedTeamContent = () => (
  <div className="content-box">
    <h2 className="mb-4 fs-1">Dedicated Team</h2>
    <div className="content-flex-dynamic">
      <ServiceBox
        title="Front-end developers"
        description="Empower your digital presence with our adept front-end developers pioneering the latest tech trends."
      />
      <ServiceBox
        title="Back-end developers"
        description="We bring together top-notch back-end experts with deep knowledge of the server-side development process"
      />
      <ServiceBox
        title="Mobile developers"
        description="Unlock the potential of native and cross-platform mobile applications with our seasoned development team"
      />
      <ServiceBox
        title="UI/UX designers"
        description="Elevate user engagement through inspired UI/UX designs crafted by our proficient team"
      />
      {/* <ServiceBox
        title="3D designers"
        description="Visualize and animate your ideas in 3D with the help of our creative team"
      /> */}
      <ServiceBox
        title="QA engineers"
        description="Ensure product perfection with our meticulous QA engineers proficient in both automation and manual testing"
      />
      <ServiceBox
        title="DevOps engineers"
        description="Optimize development pipelines and accelerate time-to-market with our adept DevOps engineers"
      />
      <ServiceBox
        title="Business analysts"
        description="Drive project success with our insightful BAs, guiding optimal technical solutions for seamless workflows"
      />
    </div>
  </div>
);

const CustomSoftwareDevelopmentContent = () => (
  <div className="content-box">
    <h2 className="mb-4 fs-1">Software Development</h2>
    <div className="content-flex-dynamic">
      <ServiceBox
        title="Web Development"
        description="We develop and implement custom web-based solutions tailored to particular business and technological requirements. Innowise offers web application development services bringing in vast technology and industry expertise"
      />
      <ServiceBox
        title="Cloud"
        description="We develop cloud-based apps and seamlessly migrating client-server software to cloud environments"
      />
      <ServiceBox
        title="Mobile Application"
        description="Top-notch iOS & Android apps incorporating the latest technology advancements and global trends"
      />
      {/* <ServiceBox
        title="Blockchain"
        description="Pioneer custom blockchain applications advancing digital services and shaping the future landscape"
      /> */}
      <ServiceBox
        title="Data engineering"
        description="Transform data into actionable insights, enhancing business strategies and performance through our expert data engineering services"
      />
    </div>
  </div>
);

const ServiceBox = ({ title, description }) => (
  <div
    data-aos="fade-up"
    data-aos-once="true"
    data-aos-anchor-placement="top-bottom"
    style={{
      flex: "0 0 calc(50% - 10px)",
      // marginBottom: "20px",
      borderBottom: "2px solid #ccc",
      // paddingBottom: "20px",
    }}
  >
    <h3 className="text-capitalize fs-4">{title}</h3>
    <p className="fs-6">{description}</p>
  </div>
);

const ITConsultingSupportContent = () => (
  <div className="content-box">
    <h2 className="mb-4 fs-1">IT Consulting & Support</h2>
    <div className="content-flex-dynamic">
      {/* <ServiceBox
        title="IT audit"
        description="We analyze businesses and projects to identify weaknesses and plan optimization"
      /> */}
      <ServiceBox
        title="IT consulting"
        description="Our IT experts can help you optimize your business and maximize business value"
      />
      <ServiceBox
        title="Solution architecture"
        description="We build high-level solution architectures to be a basement of digital systems"
      />
      <ServiceBox
        title="R&D"
        description="Our team develops innovative solutions using the latest technology findings"
      />
      <ServiceBox
        title="Prototyping"
        description="We deliver highly detailed prototypes under tight deadlines"
      />
      <ServiceBox
        title="Maintenance"
        description="Ensure software security and performance through comprehensive maintenance services"
      />
    </div>
  </div>
);

const ServiceData = [
  {
    title: "Trading Applications", animateStyle: "fade-up", id: "01",
    description: "Our team of skilled developers specializes in creating robust and scalable trading applications that cater to the demands of modern financial markets. From algorithmic trading platforms to high-frequency trading systems, we leverage the latest technologies to deliver solutions that provide real-time market data, advanced trading functionalities, and seamless execution capabilities."
  },
  {
    title: "DevOps Services", animateStyle: "fade-up", id: "02",
    description: "Accelerate your software development and deployment processes with our comprehensive DevOps services. From continuous integration and continuous delivery (CI/CD) pipelines to infrastructure automation and monitoring, we help streamline your development workflows, improve collaboration between development and operations teams, and achieve faster time-to-market for your applications"
  },
  {
    title: "Software Development", animateStyle: "fade-up", id: "03",
    description: "From conceptualization to deployment, we specialize in creating bespoke software solutions that align perfectly with your business objectives. Our team of experienced developers leverages the latest technologies to deliver high- performance, scalable software applications tailored to your specific requirements."
  },
  {
    title: "Web Development", animateStyle: "fade-up", id: "04",
    description: "Unlock the full potential of the digital landscape with our expert web development services. Whether you need a stunning website, a robust e-commerce platform, or a dynamic web application, we have the expertise to bring your vision to life. Our websites are not just visually appealing but also user- friendly, responsive, and optimized for performance."
  },
  {
    title: "Mobile App Development", animateStyle: "fade-up", id: "05",
    description: "Stay connected with your customers on the go with our custom mobile app development services. From iOS to Android, we specialize in building intuitive, feature-rich mobile applications that enhance user engagement and drive business growth. Whether you're looking to launch a consumer- facing app or streamline internal processes with enterprise mobility solutions, we've got you covered."
  },
  {
    title: "IT Consultancy", animateStyle: "fade-up", id: "06",
    description: "Leverage our extensive industry expertise and strategic insights to optimize your IT infrastructure and maximize your technology investments. Our seasoned consultants work closely with you to assess your current IT landscape, identify opportunities for improvement, and develop tailored strategies to achieve your business objectives."
  },
]

const ServiceTab = () => {
  const [activeTab, setActiveTab] = useState("dedicated-team");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  useEffect(() => {
    document.title = 'H&H - Services';
  }, []);

  return (
    <>
      <div className="parallax service-img">
      <div className="parallax-overlay"></div>
        <div className=''>
            <h1 className="">OUR SERVICES</h1>
        </div>
    </div>

      {/* <section id="heroblock-block_91f6b6f3cf6c1deea390b58233df2e45" className="inner-banner d-flex align-items-center flex-wrap position-relative overflow-hidden gradient white-text">
        <div className="block_gradient" style={{background: "linear-gradient(rgb(135 19 19) 50%, rgb(1 2 2) 100%)"}}></div>
        <div className="container">
          <div className="row text-start justify-content-center justify-content-md-start white-text">
            <div className="col-12">
              <div className="banner-content-image-wrap d-flex justify-content-center justify-content-md-start align-items-center flex-column flex-md-row">
                <div className="banner_content text-center text-md-start mb-4 mb-md-0 me-md-5">
                  <h1 className="white-heading">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ducimus illo praesentium odio aliquid. Atque earum vitae dicta, excepturi ipsa rerum tempore neque praesentium explicabo blanditiis quis provident deserunt. Beatae, expedita.
                  </p>
                </div>
                <div className="banner-image-circle position-relative">
                  <img decoding="async" src="https://www.connamara.tech/wp-content/themes/connamara/assets/images/banner-side-circles.png" alt="" style={{opacity: "0"}} />
                  <figure className="hero-banner-circle bgimage">
                  <img decoding="async" src={image2} alt="" className="image-object-fit" />
                    <img decoding="async" src="https://www.connamara.tech/wp-content/uploads/2022/12/services-header-image.jpeg" alt="" className="image-object-fit" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="services" className="why-us mt-2 ">
      <div className="container">
        <div className="section-title" data-aos-once="true" data-aos="zoom-in">
          <h3>
            Why you should <span>choose us</span>
            <img src={image} width="30px" className="pb-1" alt="" />
          </h3>
        </div>

        <div className="row h-100">
          {ServiceData.map(service => (
            <div
              key={service.id}
              className="col-lg-6 p-4 mt-lg-0 my-sm-2"
              data-aos-once="true"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="box">
                <span>{service.id}</span>
                <h4>{service.title}</h4>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section> 
      <section>
        <div className="tabs-container">
          <div className="tabs-sidebar">
            <div className="tab-sideBorder">
              <div
                className={`cursor-pointer ${
                  activeTab === "dedicated-team" ? "active" : ""
                }`}
                onClick={() => handleTabChange("dedicated-team")}
              >
                Dedicated Team
              </div>
              <div
                className={`cursor-pointer ${
                  activeTab === "custom-software-development" ? "active" : ""
                }`}
                onClick={() => handleTabChange("custom-software-development")}
              >
                Software Development
              </div>
              <div
                className={`cursor-pointer ${
                  activeTab === "it-consulting-support" ? "active" : ""
                }`}
                onClick={() => handleTabChange("it-consulting-support")}
              >
                IT Consulting & Support
              </div>
            </div>
          </div>

          <div className="tabs-content">
            {activeTab === "dedicated-team" && <DedicatedTeamContent />}
            {activeTab === "custom-software-development" && (
              <CustomSoftwareDevelopmentContent />
            )}
            {activeTab === "it-consulting-support" && (
              <ITConsultingSupportContent />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceTab;
