import React, { memo, useCallback, useEffect, useState } from 'react';
import axiosInstance from '../service/axiosInstance';
import { useParams } from 'react-router-dom';
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const WeekCalendar = ({ onClickDay, clicked, onClickCalendarDataId }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [dateSelected, setDateSelected] = useState(new Date());


  const userId = useParams();
  const employeeId = userId.id;
  const [calendarDatas, setCalendarDatas] = useState(null)
  
  const calendar = useCallback(() => {
    const token = localStorage.getItem('jwt');
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axiosInstance.get(`/api/emp-attendance/${employeeId}`, { headers })
    .then(response => {
        setCalendarDatas(response.data);}
      )
      .catch(err => console.error(err));
    },[employeeId]);

    useEffect(() => {
      calendar()
    }, [clicked, calendar])

  const getWeekDates = (startDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() - currentDate.getDay());
    for (let i = 0; i < 7; i++) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const formatDay = (date) => {
    const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
    const dayNumber = date.getDate();
    return `${dayName}, ${dayNumber}`;
  };

  const weekDates = getWeekDates(startDate);
  const startDateString = weekDates[0].toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  const endDateString = weekDates[6].toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const goToPreviousWeek = () => {
    const previousWeekStartDate = new Date(startDate);
    previousWeekStartDate.setDate(previousWeekStartDate.getDate() - 7);
    setStartDate(previousWeekStartDate);
  };

  const goToNextWeek = () => {
    const nextWeekStartDate = new Date(startDate);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);
    setStartDate(nextWeekStartDate);
  };
  useEffect(() => {
    const formattedDate = dateSelected.toLocaleDateString().split('T')[0];
    onClickDay(formattedDate);
  }, [dateSelected]);

  const handleDateClick = (date) => {
    console.log('date', date);
    const formattedDate = date.toLocaleDateString().split('T')[0];
    if (formattedDate !== dateSelected.toLocaleDateString().split('T')[0]) {
      setDateSelected(date);
      onClickDay(formattedDate);
      const calendarDataId = getCheckInTimeID(date);
      onClickCalendarDataId(calendarDataId);
    }
  };
  const getCheckInTimeID = (date) => {
    if (calendarDatas) {
      const formattedDate = date.toLocaleDateString();
      const calendarData = calendarDatas.find(data => {
        const dataDate = new Date(data.date);
        return dataDate.toLocaleDateString() === formattedDate;
      });
      const calendarDataId = calendarData?._id;
      return calendarDataId;
    }
    return '--:--';
  };
  const calculateTimeDifference = (date) => {
    if (calendarDatas) {
      const formattedDate = date.toLocaleDateString();
      const calendarData = calendarDatas.find(data => {
        const dataDate = new Date(data.date);
        return dataDate.toLocaleDateString() === formattedDate;
      });
  
      if (calendarData && calendarData.checkIn && calendarData.checkOut) {
        const checkInTime = new Date(calendarData.checkIn);
        const checkOutTime = new Date(calendarData.checkOut);
        const diffInMilliseconds = checkOutTime - checkInTime;
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const hours = Math.floor(diffInSeconds / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
    }
    return '--:--:--';
  };
  
  const getCheckInTime = (date) => {
    if (calendarDatas) {
      const formattedDate = date.toLocaleDateString();
      const calendarData = calendarDatas.find(data => {
        const dataDate = new Date(data.date);
        return dataDate.toLocaleDateString() === formattedDate;
      });
      const calendarDataId = calendarData?._id;
      return calendarData ? (calendarData.checkIn ? new Date(calendarData.checkIn).toLocaleTimeString() : '--:--') : '--:--';
    }
    return '--:--';
  };
  
  const getCheckOutTime = (date) => {
    if (calendarDatas) {
      const formattedDate = date.toLocaleDateString();
      const calendarData = calendarDatas.find(data => {
        const dataDate = new Date(data.date);
        return dataDate.toLocaleDateString() === formattedDate;
      });
      return calendarData ? (calendarData.checkOut ? new Date(calendarData.checkOut).toLocaleTimeString() : '--:--') : '--:--';
    }
    return '--:--';
  };
  
  
  const handleAttendanceChange = (date) => {
    // const formattedDate = date.toISOString().split('T')[0];
    const currentDate = new Date();
    const weekDay = date.getDay();
  
    if (weekDay === 0 || weekDay === 6) {
      return 'Weekend';
    }
  
    if (date > currentDate) {
      return 'No Record';
    }
  
    const checkInTime = getCheckInTime(date);
    const checkOutTime = getCheckOutTime(date);
  
    if (checkInTime === '--:--') {
      return 'Absent';
    }
  
    if (checkOutTime === '--:--') {
      return 'Present';
    }
  
    return 'Present';
  };
  
  return (
    <div className="mb-5">
      <div className="p-0 d-flex justify-content-center alert alert-secondary">
        <div className=" d-flex align-items-center justify-content-between">
          <div className="btn nav-arrow pe-2" onClick={goToPreviousWeek} style={{ fontSize: "2rem" }}>
            <BiChevronLeft />
          </div>
          <div className="date-range">
            {startDateString} - {endDateString}
          </div>
          <div className="btn nav-arrow ps-2" onClick={goToNextWeek} style={{ fontSize: "2rem" }}>
            <BiChevronRight />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="" style={{border:"none", fontSize:"13px"}}>
          <thead>
            <tr className="">
              <th className="text-center"></th>
              <th className="text-center">Check-in</th>
              <th className="text-center"></th>
              <th className="text-center">Check-out</th>
              <th className="text-center">Total Hours</th>
            </tr>
          </thead>
          <tbody>
            {weekDates.map((date, index) => {
              const weekDay = date.getDay();
              const isUpcomingDate = date > new Date()
              return (
                // <tr key={index} className={`cursor-pointer ${isUpcomingDate ? 'text-center' : ''} ${dateSelected && date.toDateString() === dateSelected.toDateString() ? 'alert alert-danger fw-bold' : ''}`} onClick={weekDay !== 0 && weekDay !== 6 ? () => handleDateClick(date) : null}                >
                <tr key={index} className={` ${isUpcomingDate ? 'text-center' : ''} ${dateSelected && date.toDateString() === dateSelected.toDateString() ? 'alert alert-danger fw-bold' : ''} ${weekDay !== 0 && weekDay !== 6 ? 'cursor-pointer' : ''}`} onClick={weekDay !== 0 && weekDay !== 6 ? () => handleDateClick(date) : null}>
                  <td
                      className={`text-center col-1 ${dateSelected && date.toDateString() === dateSelected.toDateString() ? 'text-danger fw-bold' : ''}`}
                  >
                      {formatDay(date)}
                  </td>
                  <td className="text-center col-1">{getCheckInTime(date)}</td>
                  <td className="text-center col-sm-8" style={{ position: 'relative' }}>
                    <div className={`d-none d-md-block center-line ${handleAttendanceChange(date) === 'Weekend' ? 'bg-warning' : (handleAttendanceChange(date) === 'Present' ? 'bg-success' : (handleAttendanceChange(date) === 'No Record' ? 'bg-dark' : 'bg-danger'))}`}></div> {/* Hide center-line on lg and larger screens */}
                    <div
                    id='border-in-table'
                      className={`rounded text-dark bg-white ${handleAttendanceChange(date) === 'Weekend' ? 'border border-warning' : (handleAttendanceChange(date) === 'Present' ? 'border-2 border-success' : (handleAttendanceChange(date) === 'No Record' ? 'border-2 border-dark' : 'border-2 border-danger'))} ${' w-md-auto'}`}
                    >
                      {handleAttendanceChange(date)}
                    </div>
                  </td>
                  <td className="text-center col-1">{getCheckOutTime(date)}</td>
                  <td className="text-center col-2 fw-bold">{calculateTimeDifference(date)}</td>
                  <td>{date._id}</td>
              </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(WeekCalendar);