import React, { useCallback, useEffect, useState } from 'react';
import logo from '../assets/img/hnh-logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('home');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsCollapsed(true);
  };

  const toggleNavbar = () => {
    setIsCollapsed(prevState => !prevState);
  };

  const logoutHandle = useCallback(() => {
    localStorage.clear();
    navigate("/signin");
  }, [navigate]);

  const token = localStorage.getItem("jwt");
  const loginHandle = () => {
    navigate("/signin");
    setIsCollapsed(true);
  };

  useEffect(() => {
    const pathname = location.pathname.substring(1);
    setActiveLink(pathname || 'home');
  }, [location.pathname]);

  const user = JSON.parse(localStorage.getItem("user"));
  const admin = JSON.parse(localStorage.getItem("admin"));

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark sticky-top w-100">
      <div className="container-fluid">
        <Link className="navbar-brand text-white" to='/'>
          <img src={logo} alt="logo" width="60" height="60" className="d-inline-block align-top" />
          H&H
        </Link>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar} aria-expanded={!isCollapsed} aria-label="Toggle navigation">
          <span className="navbar-toggler-icon">
            <i className="bi bi-list fs-2 text-white"></i>
          </span>
        </button>
        <div className={`navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarNavAltMarkup">
          <div className="navbar-nav">
            {token && (user || admin) ? (
              <>
                {!user.isAdmin && <Link to={`/profile/${user._id}`} onClick={() => handleLinkClick('profile')} className={`navbar-link text-capitalize ${activeLink === "profile" ? "active" : "" }`}>Profile</Link>}
                {user.isAdmin && <Link to={`/admin-employees/${user._id}`} onClick={() => handleLinkClick('admin-employees')} className={`navbar-link text-capitalize ${activeLink === "admin-employees" ? "active" : "" }`}>Admin</Link>}
                <button className="btn-button mt-3 " onClick={logoutHandle}>Logout</button>
              </>
            ) : (
              <>
                <Link to="/" onClick={() => handleLinkClick('home')} className={`navbar-link text-capitalize ${activeLink === "home" ? "active" : "" }`}>Home</Link>
                <Link to="/about-us" onClick={() => handleLinkClick('about-us')} className={`navbar-link text-capitalize ${activeLink === "about-us" ? "active" : "" }`}>About Us</Link>
                <Link to="/our-service" onClick={() => handleLinkClick('our-service')} className={`navbar-link text-capitalize ${activeLink === "our-service" ? "active" : "" }`}>Services</Link>
                <Link to="/technology" onClick={() => handleLinkClick('technology')} className={`navbar-link text-capitalize ${activeLink === "technology" ? "active" : "" }`}>Technology</Link>
                <Link to="/careers" onClick={() => handleLinkClick('careers')} className={`navbar-link text-capitalize ${activeLink === "careers" ? "active" : "" }`}>Careers</Link>
                <Link to="/contact" onClick={() => handleLinkClick('contact')} className={`navbar-link text-capitalize ${activeLink === "contact" ? "active" : "" }`}>Contact</Link>
                {/* <button className="btn-button mt-3 " onClick={loginHandle}>Log In</button> */}
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
