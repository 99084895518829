import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const ExitDetailsTable = ({ resignations, onView, onEdit, onDelete }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
    return (
        <div className="table-responsive mt-4">
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th className='text-nowrap'>Employee Name</th>
                        <th className='text-nowrap'>Separation Date</th>
                        <th className='text-nowrap'>Reason For Leaving</th>
                        <th className='text-nowrap'>Interviewer</th>
                        <th className='text-nowrap'>Work Again</th>
                        <th className='text-nowrap'>Like Most</th>
                        <th className='text-nowrap'>Improve Welfare</th>
                        <th className='text-nowrap'>Share With Us</th>
                        <th className='text-nowrap'>Equipment Handed In</th>
                        <th className='text-nowrap'>Security</th>
                        <th className='text-nowrap'>Notice Period</th>
                        <th className='text-nowrap'>Exit Interview</th>
                        <th className='text-nowrap'>Resignation Letter</th>
                        <th className='text-nowrap'>Feedback</th>
                        <th className='text-nowrap'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {resignations.map((employee) => (
                        <tr key={employee._id}>
                            <td>{employee.empName}</td>
                            <td>{formatDate(employee.separationDate)}</td>
                            <td>{employee.reasonForLeaving}</td>
                            <td>{employee.interviewer}</td>
                            <td>{employee.workAgain}</td>
                            <td>{employee.likeMost}</td>
                            <td>{employee.improveWelfare}</td>
                            <td>{employee.shareWithUs}</td>
                            <td>{employee.equipmentHandedIn}</td>
                            <td>{employee.security}</td>
                            <td>{employee.noticePeriod}</td>
                            <td>{employee.exitInterview}</td>
                            <td>{employee.resignationLetter}</td>
                            <td>{employee.feedback}</td>
                            <td className='d-flex gap-1'>
                                <button className="btn btn-sm btn-info mr-2 d-none"  onClick={() => onView(employee._id)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </button>
                                <button className="btn btn-outline-primary btn-sm mr-2" onClick={() => onEdit(employee._id)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                                <button className="btn btn-outline-danger btn-sm" onClick={() => onDelete(employee._id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ExitDetailsTable;
