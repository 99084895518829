import React from 'react';
import img1 from '../assets/img/home/1.jpg';
import img2 from '../assets/img/home/2.jpg';
import img3 from '../assets/img/home/9.jpg';
import img4 from '../assets/img/home/7.jpg';
import img5 from '../assets/img/home/5.jpg';
import img6 from '../assets/img/home/8.jpg';

const Datas = [
    {head1: '01', image:img1, cardColor: "card-color-red", animate:"zoom-in", head2: 'Expertise', description: "With over two decades of industry experience and a team of seasoned professionals, we deliver high-quality solutions to trading, blockchain, and DevOps domains."},
    {head1: '02', image:img2, cardColor: "card-color-violet", animate:"fade-left", head2: 'Innovation', description: "We stay ahead of the curve by adopting emerging technologies and best practices to deliver innovative solutions that drive business value and competitive advantage for our clients."},
    {head1: '03', image:img6, cardColor: "card-color-red", animate:"zoom-out", head2: 'Reliability', description: "Trust is at the core of our business. We pride ourselves on delivering solutions that are reliable, secure, and scalable, ensuring the long-term success and sustainability of our clients' businesses."},
    {head1: '04', image:img4, cardColor: "card-color-violet", animate:"fade-out", head2: 'Customer Satisfaction', description: "We are committed to exceeding our clients' expectations by delivering exceptional service, personalized attention, and ongoing support throughout the project lifecycle."},
    {head1: '05', image:img5, cardColor: "card-color-red", animate:"fade-right", head2: 'Customized Solutions', description: "We understand that every business is unique. That's why we take a personalized approach to every project, ensuring that our solutions are perfectly aligned with your specific needs and goals."},
    {head1: '06', image:img3, cardColor: "card-color-violet", animate:"fade-left", head2: 'Quality Assurance', description: "We are committed to delivering excellence in everything we do. Our rigorous quality assurance processes ensure that every solution we deliver meets the highest standards of quality, reliability, and performance."},
]

const ServiceCard = () => {
  return (
    <div className='container mb-5'>
      <div className="row row-cols-xxl-3 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 g-5">
        {Datas.map((data, idx) => (
          <div key={idx} data-aos-once="true" data-aos={data.animate} className="col d-flex justify-content-center">
            <div className="box-card rounded">
              <div className="box-card-content">
                <h2 className="heading">{data.head1}</h2>
                <h3 className="heading pt-5 ">{data.head2}</h3>
                <img
                  src={data.image}
                  alt="Placeholder"
                  className="img-fluid-card"
                  loading="lazy"
                />
                <div className={`overlay-card ${data.cardColor}`}></div>
                <p className="hidden-paragraph fs-5">
                  {data.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};

export default ServiceCard;
