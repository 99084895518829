import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      if (
        location.pathname === "/case-study/material-exchange" || location.pathname === "/case-study/AFX" ||
        location.pathname === "/case-study/Tera-Exchange" || location.pathname === "/case-study/APACBourse"
      ) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: 0, behavior: 'instant' });
      }
    };

    scrollToTop();
  }, [location.pathname]);

  return <>{children}</>;
};

export default Layout;
