import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axiosInstance from '../../service/axiosInstance';

import EmployeeList from './EmployeeList';
import CandidateForm from './candidateForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewEditCandidate from './ViewEditCandidate';

function OnboardCandidate({ infoData, setemployeeAdded }) {
  const [candidateDetails, setCandidateDetails] = useState({
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    phone: '',
    // uanNumber: '',
    aadhaarNumber: '',
    panNumber: '',
    photo: null, // File upload
    dateOfBirth: '',

    presentAddress: '',
    presentState: '',
    presentPostalCode: '',

    permanentAddress: '',
    permanentState: '',
    permanentPostalCode: '',

    experience: '',
    sourceOfHire: '',
    skillSet: '',
    highestQualification: '',
    location: '',
    title: '',
    currentSalary: '',
    department: '',
    tentativeJoiningDate: '',
    password:'',
    employeeType: ''
  });

  const [employeesData, setEmployeesData] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [entitlement, setEntitlement] = useState({
    annualLeaveEntitlement: '',
    sickLeaveEntitlement: '',
    casualLeaveEntitlement: ''
  });

  const token = localStorage.getItem('jwt');
  const headers = useMemo(()=>({
    Authorization: `Bearer ${token}`
  }),[token]);

  const fetchEmployeesData = useCallback(() => {
    axiosInstance.get("/users-admin", { headers })
      .then((response) => {
        setEmployeesData(response.data);
        setemployeeAdded('updated')
      })
      .catch((error) => {
        console.error("Error fetching employees data:", error);
      });
  },[headers, setemployeeAdded]);

  useEffect(() => {
    // if (infoData && infoData.length > 0) {
      setEmployeesData(infoData);
      fetchEmployeesData();
    // } else {
    // }
    setemployeeAdded('useeffect')
  }, [ infoData, fetchEmployeesData, setemployeeAdded]);

  const openOffcanvas = (item) => {
    setSelectedItem(item);
    setEntitlement({
      annualLeaveEntitlement: item.annualLeaveEntitlement,
      sickLeaveEntitlement: item.sickLeaveEntitlement,
      casualLeaveEntitlement: item.casualLeaveEntitlement
    });
    setShowOffcanvas(true);
  };

  const closeOffcanvas = () => {
    setShowOffcanvas(false);
    setSelectedItem(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEntitlement(prevEntitlement => ({
      ...prevEntitlement,
      [name]: value
    }));
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    const updatedEmployee = {
      ...selectedItem,
      annualLeaveEntitlement: entitlement.annualLeaveEntitlement,
      sickLeaveEntitlement: entitlement.sickLeaveEntitlement,
      casualLeaveEntitlement: entitlement.casualLeaveEntitlement
    };
    axiosInstance.put(`/users/${selectedItem._id}/entitlement`, updatedEmployee, { headers })
      .then(response => {
        toast.success('Entitlements updated successfully');
        fetchEmployeesData();
        closeOffcanvas();
        setemployeeAdded('updatedEmployee')
      })
      .catch(error => {
        console.error("Error updating entitlements:", error);
        toast.error('Failed to update entitlements');
      });
  };

  const [clickedBtn, setclickedBtn] = useState(false)
  
  const handleButtonCLicked = (check) => {
      setclickedBtn(check);
  }

  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [viewCandidate, setViewCandidate] = useState(false);
  const [viewCandidateData, setViewCandidateData] = useState(false);
  
  const openViewForm = (candidate) => {
    setSelectedCandidate(candidate);
    setViewCandidate(true);
    setViewCandidateData(true)
  };

  const openEditForm = (candidate) => {
    setSelectedCandidate(candidate);
    setViewCandidate(true);
    setViewCandidateData(false)
  };

  return (
    <div className="py-2 shadow input-field gap-3 mt-3">
      <ToastContainer/>
      <div className="alert alert-light">
        {clickedBtn && (
          <div className="d-flex justify-content-end">
            <button className='btn btn-danger' onClick={() => handleButtonCLicked(false)}>X</button>
          </div>          
        )}
        {!clickedBtn && !viewCandidate && (
          <div className="d-flex justify-content-end">
            <button className='btn btn-danger' onClick={() => handleButtonCLicked(true)}>Invite Candidate</button>
          </div>
        )}
        {viewCandidate && (
          <div className="d-flex justify-content-end">
            <button className='btn btn-danger' onClick={() => setViewCandidate(false)}>back</button>
          </div>
        )}
      </div>
    {!clickedBtn ? (
      // <EmployeeList setCandidateDetails={setCandidateDetails}/>
      !viewCandidate ? 
      <EmployeeList
        employeesData={employeesData}
        showOffcanvas={showOffcanvas}
        selectedItem={selectedItem}
        openOffcanvas={openOffcanvas}
        closeOffcanvas={closeOffcanvas}
        entitlement={entitlement}
        handleChange={handleChange}
        handleUpdateSubmit={handleUpdateSubmit}
        setCandidateDetails={setCandidateDetails}
        openViewForm={openViewForm}
        openEditForm={openEditForm}
      /> : <ViewEditCandidate candidateDetails={selectedCandidate} viewCandidateData={viewCandidateData} />
    ) : (      
      <CandidateForm candidateDetails={candidateDetails} setCandidateDetails={setCandidateDetails} setclickedBtn={setclickedBtn} fetchEmployeesData={fetchEmployeesData}/>
    ) }
      {/* <ViewEditCandidate candidateDetails={selectedCandidate}/> */}
    </div>
  );
}

export default OnboardCandidate;
