import React, { useMemo, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

const SortedCoffeeKnowledgeTable = ({ sessions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const sessionsPerPage = 5;

  const sortedSessions = useMemo(() => {
    return sessions.sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [sessions]);

  const indexOfLastSession = currentPage * sessionsPerPage;
  const indexOfFirstSession = indexOfLastSession - sessionsPerPage;
  const currentSessions = sortedSessions.slice(indexOfFirstSession, indexOfLastSession);

  const totalPages = Math.ceil(sortedSessions.length / sessionsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
    <div className="shadow p-3">
    <h4>Coffee & Knowledge Session</h4>
    <hr />
    <div className='table-responsive'>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Topic Name</th>
            <th>Session By</th>
            <th>Date</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {!currentSessions.length === 0 ? (currentSessions.map((session, index) => (
            <tr key={index}>
              <td>{session.topic}</td>
              <td>{session.sessionBy ? session.sessionBy.name : 'N/A'}</td>
              <td>{new Date(session.date).toLocaleDateString()}</td>
              <td>
                {session.link ? (
                  <a href={session.link} target="_blank" rel="noopener noreferrer">Link</a>
                ) : (
                  'No Link'
                )}
              </td>
            </tr>
          ))):(
            <tr>
              <td colSpan="4" className="text-center">
                <div className="alert alert-info mb-0" style={{ marginBottom: 0 }}>
                  No data to show
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <nav>
        <ul className="pagination justify-content-center">
          {Array.from({ length: totalPages }, (_, i) => (
            <li key={i + 1} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
              <button onClick={() => paginate(i + 1)} className="page-link">
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    </div>
    </>
  );
};

export default SortedCoffeeKnowledgeTable;
