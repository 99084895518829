import React from "react";
import teraImg from '../../assets/img/case-study/material.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const MaterialEX = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
  return (
    <div className="mt-5 m-3">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="mb-5">
            <div className="card-body">
              <h1 className="card-title text-danger text-center heading-size">MaterialsXchange (MX)</h1>
              <h4 className="card-text text-center">Connamara Helps Materials Exchange Launch in 4 Months</h4>
            </div>
          </div>

          <img src={teraImg} className="object-fit-cover w-100 img-fluid rounded-5" style={{height: "35rem"}} alt="" />

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Client</h1>
              <p className="card-text fs-5">MaterialsXchange (MX) is a startup digital marketplace for raw materials, initially focused on lumber and wood products. The exchange was founded in 2019.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Challenge</h1>
              <p className="card-text fs-5">After a few years of developing and refining its concept, the MaterialsXchange team felt the time was right to launch its exchange in early 2019. Although the lumber industry was ripe for innovation, it also presented a challenge: the cash raw materials market for lumber was fragmented and had numerous challenges around price discovery, delivery, and settlement.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Solution</h1>
              <p className="card-text fs-5">MaterialsXchange’s timing coincided perfectly with the launch of Connamara’s EP3 matching engine technology. EP3 was the ideal technology solution for bringing the efficiency and transparency of modern capital markets to the fragmented and cumbersome cash market for raw materials.</p>
              <p className="card-text fs-5">MX selected Connamara as its technology partner, and in the summer of 2019, Connamara began working with MX founder and CEO Mike Wisnefski and his team to build the exchange.</p>
            </div>
          </div>

          <div className="my-5">
            <div className="card-body border-top">
              <h1 className="mt-5">The Results</h1>
              <p className="card-text mb-2 fs-5">MaterialsXchange — with EP3 as its foundation — completed its first transaction on October 28th, 2019, just over four months after beginning work with Connamara. While new venues often take over a year to complete their first trade, EP3 allowed Connamara to build MX on a very compressed timeframe, enabling MX to launch ahead of schedule and start onboarding new customers.</p>
              <p className="card-text fs-5">While MaterialsXchange was initially created as a minimum viable product, EP3’s built-in scalability and throughput will allow the exchange to scale to the size of the market without changing the underlying technology.</p>
            </div>
          </div>

          <div className="prev-next my-5 d-flex justify-content-end px-3 align-items-center">
            {/* <Link to="/previous" className="prev-icon d-flex align-items-center">
                <FontAwesomeIcon icon={faChevronLeft} className="border rounded-5 fs-3 p-2" />
                <span className="ms-2">Previous</span>
            </Link> */}
            <Link to="/case-study/AFX" className="next-icon d-flex align-items-center" onClick={scrollToTop}>
                <span className="me-2">Next</span>
                <FontAwesomeIcon icon={faChevronRight} className="border rounded-5 fs-3 p-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialEX;
