import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../service/axiosInstance';
import image from '../../assets/img/search.png';

const AttendanceTable = () => {
  const { id:employeeId } = useParams();
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const token = localStorage.getItem('jwt');
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await axiosInstance.get(`/api/emp-attendance/${employeeId}`, { headers });
        const { data } = response;
        console.log("data", data);
        const filteredData = data.filter(record => !record.checkOut);
        console.log("filtered data", filteredData);
        setAttendanceData(filteredData);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, [employeeId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const sessionLength = attendanceData.length;

  if (loading) return <p>Loading...</p>;

  return (
    <div className='p-3' style={{ minHeight: '400px', position: '' }}>
        <div className="d-flex justify-content-between align-items-start">
            <h4>Attendance (pending)</h4>
                {sessionLength > 0 && (
                    <span className="bg-danger m-0 px-2 py-0 mx-3 rounded-4 text-white">
                        {sessionLength}
                    </span>
                )}
        </div>
      <hr className='pb-0 mt-2' />
      <div className="table-responsive">
        {attendanceData ? (
            <table className="table table-striped">
            <thead>
                <tr>
                <th>Date</th>
                <th>Check-in</th>
                </tr>
            </thead>
            <tbody>
                {attendanceData.length > 0 ? (
                attendanceData.map((record, index) => (
                    <tr key={index}>
                    <td>{formatDate(record.date)}</td>
                    <td>{record.checkIn && formatDateTime(record.checkIn)}</td>
                    </tr>
                ))
                ) : (
                <tr>
                    <td colSpan="3" className="text-center">No records found</td>
                </tr>
                )}
            </tbody>
            </table>
        ):(
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <img src={image} alt="Placeholder" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceTable;
