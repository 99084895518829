import React, { useState } from 'react';
import axiosInstance from '../../service/axiosInstance';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

const Form16AndPayslipForm = ({ isOpen, toggle, infoData }) => {
  const [selectedForm, setSelectedForm] = useState('form16');
  const token = localStorage.getItem('jwt');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const [form16Data, setForm16Data] = useState({
    employeeId: '',
    file: null,
    viewToEmployee: false,
    description: ''
  });

  const [payslipData, setPayslipData] = useState({
    employeeId: '',
    file: null,
    viewToEmployee: false,
    month: '',
    description: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (selectedForm === 'form16') {
        const formData = new FormData();
        formData.append('file', form16Data.file);
        formData.append('viewToEmployee', form16Data.viewToEmployee);
        formData.append('description', form16Data.description);

        await axiosInstance.post(`/api/form16/upload/${form16Data.employeeId}`, formData, { headers });
        toast.success('Form 16 uploaded successfully!');
      } else if (selectedForm === 'payslip') {
        const payslipExists = await checkPayslipExists(payslipData.employeeId, payslipData.month);

        if (payslipExists) {
          toast.error(`Payslip for month ${payslipData.month} already exists for this employee.`);
        } else {
          const formData = new FormData();
          formData.append('file', payslipData.file);
          formData.append('viewToEmployee', payslipData.viewToEmployee);
          formData.append('month', payslipData.month);
          formData.append('description', payslipData.description);
        console.log("formData", payslipData);

          await axiosInstance.post(`/api/payslip/upload/${payslipData.employeeId}`, formData, { headers });
          toast.success('Payslip uploaded successfully!');
        }
      }

      clearFormData();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to upload the file.');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (selectedForm === 'form16') {
      setForm16Data({ ...form16Data, file });
    } else if (selectedForm === 'payslip') {
      setPayslipData({ ...payslipData, file });
    }
  };

  const clearFormData = () => {
    if (selectedForm === 'form16') {
      setForm16Data({
        employeeId: '',
        file: '',
        viewToEmployee: false,
        description: ''
      });
    } else if (selectedForm === 'payslip') {
      setPayslipData({
        employeeId: '',
        file: '',
        viewToEmployee: false,
        month: '',
        description: ''
      });
    }
  };

  const checkPayslipExists = async (employeeId, month) => {
    try {
      const response = await axiosInstance.get(`/api/payslip/${employeeId}`, { headers });
      const payslips = response.data;
      return payslips.some(payslip => payslip.month === month);
    } catch (error) {
      console.error('Error checking payslip existence:', error);
      toast.error('Failed to check payslip existence.');
      return true;
    }
  };

  const renderForm = () => {
    if (selectedForm === 'form16') {
      return (
        <div className='shadow p-4'>
          <h4 className='text-center fw-bold'>Form 16</h4>
          <hr />
          <form onSubmit={handleSubmit} className="needs-validation" noValidate>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label htmlFor="employeeId" className="form-label">Employee</label>
              </div>
              <div className="col-lg-7">
                <select
                  className="form-control-announce bg-white"
                  id="employeeId"
                  value={form16Data.employeeId}
                  onChange={(e) => setForm16Data({ ...form16Data, employeeId: e.target.value })}
                  required
                >
                  <option value="">Select Employee</option>
                  {infoData.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label htmlFor="file" className="form-label">File</label>
              </div>
              <div className="col-lg-7">
                <input
                  type="file"
                  className="form-control"
                  id="file"
                  onChange={handleFileChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label htmlFor="description" className="form-label">Description</label>
              </div>
              <div className="col-lg-7">
                <input
                  type="text"
                  className="form-control-announce"
                  id="description"
                  value={form16Data.description}
                  onChange={(e) => setForm16Data({ ...form16Data, description: e.target.value })}
                  placeholder="Description"
                  required
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label className="form-label">View to Employee</label>
              </div>
              <div className="col-lg-7">
                <Switch
                  onChange={(checked) => setForm16Data({ ...form16Data, viewToEmployee: checked })}
                  checked={form16Data.viewToEmployee}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="view-to-employee-switch"
                />
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-button">Submit Form 16</button>
            </div>
          </form>
        </div>
      );
    } else if (selectedForm === 'payslip') {
      return (
        <div className='shadow p-4'>
          <h4 className='text-center fw-bold'>Payslip</h4>
          <hr />
          <form onSubmit={handleSubmit} className="needs-validation" noValidate>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label htmlFor="employeeId" className="form-label">Employee</label>
              </div>
              <div className="col-lg-7">
                <select
                  className="form-control-announce bg-white"
                  id="employeeId"
                  value={payslipData.employeeId}
                  onChange={(e) => setPayslipData({ ...payslipData, employeeId: e.target.value })}
                  required
                >
                  <option value="">Select Employee</option>
                  {infoData.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label htmlFor="file" className="form-label">File</label>
              </div>
              <div className="col-lg-7">
                <input
                  type="file"
                  className="form-control"
                  id="file"
                  onChange={handleFileChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label htmlFor="month" className="form-label">Month</label>
              </div>
              <div className="col-lg-7">
                <input
                  type="month"
                  className="form-control-announce"
                  id="month"
                  value={payslipData.month}
                  onChange={(e) => setPayslipData({ ...payslipData, month: e.target.value })}
                  required
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label htmlFor="description" className="form-label">Description</label>
              </div>
              <div className="col-lg-7">
                <input
                  type="text"
                  className="form-control-announce"
                  id="description"
                  value={payslipData.description}
                  onChange={(e) => setPayslipData({ ...payslipData, description: e.target.value })}
                  placeholder="Description"
                  required
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-3 d-flex align-items-end">
                <label className="form-label">View to Employee</label>
              </div>
              <div className="col-lg-7">
                <Switch
                  onChange={(checked) => setPayslipData({ ...payslipData, viewToEmployee: checked })}
                  checked={payslipData.viewToEmployee}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="view-to-employee-switch"
                />
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-button">Submit Payslip</button>
            </div>
          </form>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isOpen && <div className="offcanvas-backdrop-custom show" onClick={toggle}></div>}
      <div className={`offcanvas offcanvas-end w-50 ${isOpen ? 'show' : ''}`} tabIndex="-1" id="offcanvasForm" aria-labelledby="offcanvasFormLabel">
        <div className="offcanvas-content">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasFormLabel">Upload Form</h5>
            <button type="button" className="btn-close text-reset" onClick={toggle}></button>
          </div>
          <hr className='m-0 p-0' />
          <div className="offcanvas-body">
            <div className="row m-1 ps-2 py-4 shadow">
              <div className="col-lg-3 d-flex align-items-end">
                <label htmlFor="formSelect" className="form-label fw-bold">Select Form:</label>
              </div>
              <div className="col-lg-7">
                <select
                  className="form-control-announce bg-white"
                  id="formSelect"
                  value={selectedForm}
                  onChange={(e) => setSelectedForm(e.target.value)}
                >
                  <option value="form16">Form 16</option>
                  <option value="payslip">Payslip</option>
                </select>
              </div>
            </div>
            {renderForm()}
          </div>
        </div>
      </div> 
    </>


);
};

export default Form16AndPayslipForm;
