// import axios from 'axios';

// const instance = axios.create({
//   withCredentials: true, 
//   baseURL: 'http://localhost:5000'
// });

// export default instance;

import axios from 'axios';
import BASE_URL from './config'

const instance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized access. Redirecting to login page...');
      // window.location.href = '/signin';
      localStorage.clear();
    } else {
      console.error('An error occurred:', error);
    }
    return Promise.reject(error);
  }
);

export default instance;
