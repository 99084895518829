import React from 'react';

const InputField = ({ label, name, placeholder, value, onChange, required, disabled, mandatory, error }) => {
  return (
    <div className="row mb-3">
      <div className="col-lg-3 d-flex align-items-center">
        <label htmlFor={name} className="form-label font-sub-title mb-0">
          {label} {mandatory && <span className="text-danger">*</span>}
        </label>
      </div>
      <div className="col-lg-7">
        <input 
          className='form-control-announce' 
          type="text" 
          name={name} 
          id={name} 
          placeholder={placeholder} 
          value={value} 
          onChange={onChange} 
          required={required} 
          disabled={disabled}           
        />
      </div>
        {error && <div className="text-danger">{error}</div>}
    </div>
  );
};

export default InputField;
