import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';

const CoffeeKnowledgeTable = ({ sessions, onEdit, onDelete }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(sessions.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, sessions.length);
  const currentSessions = sessions.slice(startIndex, endIndex);

  return (
    <div>
      <table className="table table-responsive table-bordered">
        <thead>
          <tr>
            <th>Topic Name</th>
            <th>Session By</th>
            <th>Date</th>
            <th>Link</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentSessions.map((session, index) => (
            <tr key={index}>
              <td>{session.topic}</td>
              <td>{session.sessionBy.name}</td>
              <td>{new Date(session.date).toLocaleDateString()}</td>
              <td>
                <a href={session.link} target="_blank" rel="noopener noreferrer">{session.link ? "Link" : ""}</a>
              </td>
              <td>
                <button
                  className="btn btn-outline-primary btn-sm me-2"
                  onClick={() => onEdit(startIndex + index)}
                  aria-label="Edit"
                >
                  <FaEdit />
                </button>
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => onDelete(session._id)}
                  aria-label="Delete"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination>
        <Pagination.Prev
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
        />
        {[...Array(totalPages).keys()].map((page) => (
          <Pagination.Item
            key={page + 1}
            active={page + 1 === currentPage}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
        />
      </Pagination>
    </div>
  );
};

export default CoffeeKnowledgeTable;
