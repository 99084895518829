import React from "react";
import teraImg from '../../assets/img/case-study/apac.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const APACBourse = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  return (
    <div className="mt-5 m-3">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="mb-5">
            <div className="card-body">
              <h1 className="card-title text-danger heading-size text-center">Asia Pacific Exchange (APAC)</h1>
              <h4 className="card-text text-center">APAC Bourse Utilizes EP3’s Flexibility to Deploy On-Prem</h4>
            </div>
          </div>

          <img src={teraImg} className="object-fit-cover w-100 img-fluid rounded-5" style={{height: "35rem"}} alt="" />

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Client</h1>
              <p className="card-text fs-5">One of the largest bourses in the Asia-Pacific region, operating a fully regulated exchange for listing and trading as well as a range of related services including clearing, settlement and depository services. The exchange is a key participant in the ongoing development of the capital markets in the home country and has played an instrumental role in enhancing the global competitiveness of the home country by maintaining an efficient, secure and active trading market for local and global investors.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Challenge</h1>
              <p className="card-text fs-5">The client had a corporate level mandate to move certain products trading in production to a new platform located within the geographical boundaries of the home country in a very tight time frame.</p>
              <p className="card-text fs-5">Connamara, in conjunction with a local partner, Quick Suite Trading (“QST”), offered a complete solution that would satisfy all of the client’s requirements. Leveraging Connamara’s state-of-the-art matching engine platform, EP3TM, combined with the hosting, systems integration, and support services from QST there was no competing offer that could match the solution.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Solution</h1>
              <p className="card-text fs-5">After narrowing down the field, the client moved to the next phase where detailed project management meetings over many months were held. Connamara and QST had to satisfy exacting requirements for performance, security, and compatibility.</p>
              <p className="card-text fs-5">Successfully passing the benchmarking phase led to acceptance of the solution and the systems integration phase began in earnest. ISVs, such as Refinitiv and ICE Data Services, were notified and had to complete integration to the EP3 FIX APIs for Market Data and Order Entry. A target date for launch was set at the end of 2021.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Results</h1>
              <p className="card-text fs-5">The Connamara EP3 solution was launched into production in December 2021. The system performance was stellar and downstream users continued their trading without any interruption.</p>
              <p className="card-text fs-5">Connamara EP3 continues to power the exchange and new initiatives, including support for additional asset classes, are underway.</p>
            </div>
          </div>
          <div className="prev-next my-5 d-flex justify-content-between px-3 align-items-center">
            <Link to="/case-study/Tera-Exchange" className="prev-icon d-flex align-items-center" onClick={scrollToTop}>
                <FontAwesomeIcon icon={faChevronLeft} className="border rounded-5 fs-3 p-2" />
                <span className="ms-2">Previous</span>
            </Link>
            {/* <Link to="/next" className="next-icon d-flex align-items-center">
                <span className="me-2">Next</span>
                <FontAwesomeIcon icon={faChevronRight} className="border rounded-5 fs-3 p-2" />
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default APACBourse;
