import React, { useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import image from '../../assets/img/search.png';

const CoffeeKnowledge = ({ sessions }) => {
  const sessionsWithoutLinks = useMemo(() => {
    return sessions.filter(session => !session.link).sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [sessions]);

  const sessionLength = sessionsWithoutLinks.length;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pt-3 ps-3 pb-2">
        <h4>Coffee & Knowledge </h4>
            {sessionLength > 0 && (
            <span className="bg-danger m-0 px-2 py-0 mx-3 rounded-4 text-white">
                {sessionLength}
            </span>
            )}
      </div>
      <hr className="p-0 m-0" />
      <div className="" style={{ minHeight: '340px', position: '' }}>
        {sessions.length > 0 ? (
          <div className="table-responsive pt-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className='text-nowrap'>Date</th>
                  <th className='text-nowrap'>Session By</th>
                  <th className='text-nowrap'>Topic</th>
                </tr>
              </thead>
              <tbody>
                {sessionsWithoutLinks.map((session, index) => (
                  <tr key={index}>
                    <td style={{fontSize:'15px'}} className='text-nowrap'>{new Date(session.date).toLocaleDateString()}</td>
                    <td style={{fontSize:'15px'}} className='text-nowrap'>{session.sessionBy ? session.sessionBy.name : 'N/A'}</td>
                    <td style={{fontSize:'15px'}} className='text-nowrap'>{session.topic}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <img src={image} alt="Placeholder" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoffeeKnowledge;
