export const handleNetworkError = (error) => {
    let errorMessage = 'Network Error';
  
    if (error.response) {
      console.log('Server responded with an error:', error.response.data);
      console.log('Status code:', error.response.status);
      errorMessage = 'Server responded with an error';
    } else if (error.request) {
      console.log('No response received:', error.request);
      errorMessage = 'No response received. Check your network connection.';
    } else {
      console.log('Error setting up the request:', error.message);
      errorMessage = 'Error setting up the request.';
    }
  
    return errorMessage;
  };
  