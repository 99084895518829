import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col } from 'react-bootstrap';
import axiosInstance from '../../service/axiosInstance';
import { toast } from 'react-toastify';

export const ExitDetailsForm = ({ infoData, formData: initialFormData, isEdit, onSubmit }) => {
    const [formData, setFormData] = useState({
        empName: '',
        separationDate: '',
        reasonForLeaving: '',
        interviewer: '',
        workAgain: '',
        likeMost: '',
        improveWelfare: '',
        shareWithUs: '',
        equipmentHandedIn: '',
        security: '',
        noticePeriod: '',
        exitInterview: '',
        resignationLetter: '',
        feedback: ''
    });

    useEffect(() => {
        if (initialFormData) {
            setFormData(initialFormData);
        }
    }, [initialFormData]);

    const handleInputChanges = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (isEdit) {
                await axiosInstance.put(`/api/exit-details/${formData._id}`, formData);
                toast.success('Form updated successfully!');
            } else {
                await axiosInstance.post('/api/exit-details', formData);
                toast.success('Form submitted successfully!');
            }

            setFormData({
                empName: '',
                separationDate: '',
                reasonForLeaving: '',
                interviewer: '',
                workAgain: '',
                likeMost: '',
                improveWelfare: '',
                shareWithUs: '',
                equipmentHandedIn: '',
                security: '',
                noticePeriod: '',
                exitInterview: '',
                resignationLetter: '',
                feedback: ''
            });

            onSubmit();
        } catch (error) {
            toast.error('Failed to submit form. Please try again.');
        }
    };

    return (
        <div className="mt-4 shadow p-4">
            <h4>Separation</h4>
            <hr />
            <form onSubmit={handleSubmit}>

                <Row>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="empName" className="form-label">Employee Name</label>
                            </Col>
                            <Col md={7}>
                                <select 
                                    name="empName" 
                                    id="empName" 
                                    className='form-control-announce bg-white' 
                                    value={formData.empName} 
                                    onChange={handleInputChanges} 
                                    // disabled={!isEdit}
                                >
                                    <option value="">Please select employee</option>
                                    {infoData.map(item => (
                                        <option key={item.name} value={item.name}>{item.name}</option>
                                    ))}
                                </select>
                            </Col>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="separationDate" className="form-label">Separation Date</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="date"
                                    className="form-control-announce"
                                    name="separationDate"
                                    id="separationDate"
                                    value={formData.separationDate.split('T')[0]}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>

                <Row className='mb-4'>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="reasonForLeaving" className="form-label">Reason for Leaving</label>
                            </Col>
                            <Col md={7}>
                                <select
                                    name="reasonForLeaving"
                                    id="reasonForLeaving"
                                    className="form-control-announce bg-white"
                                    value={formData.reasonForLeaving}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                >
                                    <option value="">Select a reason</option>
                                    <option value="Better Employment Conditions">Better Employment Conditions</option>
                                    <option value="Career Prospect">Career Prospect</option>
                                    <option value="Death">Death</option>
                                    <option value="Desertion">Desertion</option>
                                    <option value="Dissatisfaction with the Job">Dissatisfaction with the Job</option>
                                    <option value="Emigrating">Emigrating</option>
                                    <option value="Health">Health</option>
                                    <option value="Higher Pay">Higher Pay</option>
                                    <option value="Personality Conflicts">Personality Conflicts</option>
                                    <option value="Retirement">Retirement</option>
                                    <option value="Personal">Personal</option>
                                    <option value="Professional">Professional</option>
                                    <option value="Growth">Growth</option>
                                    <option value="Medical Reason">Medical Reason</option>
                                    <option value="Emergency">Emergency</option>
                                    <option value="Termination">Termination</option>
                                    <option value="Others">Others</option>
                                </select>
                            </Col>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="interviewer" className="form-label">Interviewer</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="interviewer"
                                    id="interviewer"
                                    value={formData.interviewer}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>

                <h4>Questionnaire</h4>
                <hr />

                <Row>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="workAgain" className="form-label">Would you work for this organization again?</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="workAgain"
                                    id="workAgain"
                                    value={formData.workAgain}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="likeMost" className="form-label">What did you like the most about the organization?</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="likeMost"
                                    id="likeMost"
                                    value={formData.likeMost}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>

                <Row className='mb-4'>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="improveWelfare" className="form-label">What can the organization do to improve staff welfare?</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="improveWelfare"
                                    id="improveWelfare"
                                    value={formData.improveWelfare}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="shareWithUs" className="form-label">Is there anything you wish to share with us?</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="shareWithUs"
                                    id="shareWithUs"
                                    value={formData.shareWithUs}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>

                <h4>Checklist for Exit Interview</h4>
                <hr />

                <Row>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="equipmentHandedIn" className="form-label">All assets handed in</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="equipmentHandedIn"
                                    id="equipmentHandedIn"
                                    value={formData.equipmentHandedIn}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="security" className="form-label">Security</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="security"
                                    id="security"
                                    value={formData.security}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="noticePeriod" className="form-label">Notice period followed</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="noticePeriod"
                                    id="noticePeriod"
                                    value={formData.noticePeriod}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="exitInterview" className="form-label">Exit interview conducted</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="exitInterview"
                                    id="exitInterview"
                                    value={formData.exitInterview}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="resignationLetter" className="form-label">Resignation letter submitted</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="resignationLetter"
                                    id="resignationLetter"
                                    value={formData.resignationLetter}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="row my-4">
                            <Col md={4} className='d-flex align-items-end'>
                                <label htmlFor="feedback" className="form-label">Feedback</label>
                            </Col>
                            <Col md={7}>
                                <input
                                    type="text"
                                    className="form-control-announce"
                                    name="feedback"
                                    id="feedback"
                                    value={formData.feedback}
                                    onChange={handleInputChanges}
                                    // disabled={!isEdit}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>
                <button type='submit' className='btn btn-button'>
                    {!isEdit ? "Submit": "Update"}
                </button>
            </form>
        </div>
    );
};
