import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import axiosInstance from '../hnh/service/axiosInstance';

const SignIn = () => {
  const navigate = useNavigate();
  const [password, setPasword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const PostData = () => {
    if (!emailRegex.test(email)) {
        toast.error(`Invalid email`);
        return;
    }
    
    axiosInstance.post('/signin', {
      email: email,
      password: password
    })
    .then((response) => {
        const { token, user } = response.data;    
        if (user.isAdmin) {
            // toast.success("Successfully logged in");
            localStorage.setItem("jwt", token);
            localStorage.setItem("user", JSON.stringify(user));
            navigate(`/admin-employees/${user._id}`);
        } else {
            toast.success("Successfully logged in");
            localStorage.setItem("jwt", token);
            localStorage.setItem("user", JSON.stringify(user));
            navigate(`/profile/${user._id}`);
        }
    })
    .catch(error => {
        console.error('Error signing in:', error);
        if (error.response) {                
            const { data, status } = error.response;
            if (data.error) {            
                toast.error(data.error);
            } else {            
                toast.error(`Error: ${status} - ${error.response.statusText}`);
            }
        } else if (error.request) {        
            toast.error('No response received from server. Please try again later.');
        } else {        
            toast.error('Error processing your request. Please try again later.');
        }
    });
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100" >
      <div className="card shadow-lg border border-" style={{ width: '100%', maxWidth: '500px' }}>
        <div className="card-header text-center text-white py-4" style={{ background: 'linear-gradient(135deg, red, black)' }}>
          <h2 >WELCOME</h2>
          <h2 className="d-">TO</h2>
          <h2 className="fw-bold" >H&H Infotech</h2>
        </div>
        <div className="card-body">
          <h4 className="text-center my-4 fw-bold">User Login</h4>
            <div className="my-4 pt-4">
              <div className="input-group mb-2">
                <span className="input-group-text bg-white border-end-0"><i className="bi bi-person-fill"></i></span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
              </div>
            </div>
            <div className="mb-4">
              <div className="input-group mb-2">
                <span className="input-group-text bg-white border-end-0"><i className="bi bi-lock-fill"></i></span>
                <input
                  className="form-control"
                  type={showPassword ? "text": "password"}
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPasword(e.target.value)}
                />
              </div>
              <div className="text-end">
                <Link to={'/forgot-password'}>Forgot password?</Link>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <input
                  className="m-2"
                  type="checkbox"
                  value={showPassword}
                  onChange={() =>
                    setShowPassword((prev) => !prev)
                  }
              />
              <label htmlFor="check">Show Password</label>
            </div>
            <div className="d-grid ">
              <button type="sumbit"
                className="btn-button mt-3 text-center"
                onClick={() => PostData()}
              >Login</button>
            </div>
        </div>
      </div>
    </div>
      
  );
};

export default SignIn;
