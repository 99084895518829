import React, { useEffect } from 'react';

export const AboutUs = () => {
  useEffect(() => {
    document.title = 'H&H - About us';
  }, []);

  return (
    <>
    <div className="parallax about-img" >
        <div className="parallax-overlay"></div>
        <div className=''>
            <h1 className="text-white">ABOUT US</h1>
        </div>
    </div>

    <section id="about" className="about my-5">
    <div className="container">
      <div className="row justify-content-center" >
        <div className="row mb-lg-5">
          <div className="col-xl-5 col-lg-5 d-flex video-box-first justify-content-center align-items-stretch rounded my-lg-5 g-0" style={{filter:"brightness(60%)"}} data-aos-once="true" data-aos="zoom-in" >

          </div>
          <div className="col-xl-7 col-lg-7 m-0 p-0">
            <div className='ms-lg-3 d-flex flex-column align-items-stretch justify-content-center py-2 px-sm-5 p-2 rounded-5 about-border-shadow-left'>
            <div className=" text-center mt-5" data-aos-once="true" data-aos="fade-up">
              <h1 className='fw-bold text-dark'>Elevating Trading Applications with a Legacy of Excellence</h1>
            </div>

            <div className="icon-box mb-4" data-aos-once="true" data-aos="fade-up">
              <p className="description p-2">
              Welcome to H&H Infotech and Consultancy Pvt Ltd, formerly known as Connamara Software Pvt Ltd. Established in 2004, we pride ourselves on our rich history of software development excellence. As an India-based firm working in collaboration with Connamara Systems, Chicago, we specialize in crafting cutting-edge trading applications. Our partnership with Connamara ensures that we uphold the same high standards of ethics and etiquette that define their esteemed reputation.
              </p>
            </div>
            </div>
          </div>
        </div>

        <div className="row my-5 flex-sm-row-reverse flex-column-reverse">
          <div className="col-xl-7 col-lg-7 order-md-2 order-sm-2 m-0 p-0">
           <div className="d-flex justify-content-center align-items-stretch py-2 px-sm-5 me-lg-3 rounded-5 about-border-shadow-right" style={{ filter: "brightness(80%)" }} data-aos-once="true" data-aos="zoom-in">
            <div className="icon-box mb-" data-aos-once="true" data-aos="fade-up" data-aos-delay="200">
              <div className=" text-center mt-3" data-aos-once="true" data-aos="fade-up">
                <h1 className='fw-bold'>Cultivating Innovation, Collaboration, and Passion in Tech</h1>
              </div>
              <p className="description mt-5 p-2" data-aos-once="true" data-aos="fade-up" data-aos-delay="200">
                At H&H, we foster an environment that thrives on innovation, collaboration, and a passion for technology. If you're someone who loves:
                <ul>
                  <li><b>Out of the Box Thinking: </b>We encourage creativity and value fresh perspectives. Your unique ideas are what drive our innovation.</li>
                  <li><b>Well Structured and Performant Code: </b>We believe in writing clean, efficient code that not only works flawlessly but also scales seamlessly.</li>
                  <li><b>Direct Client Interaction: </b>You'll have the opportunity to directly engage with clients, understanding their needs firsthand and delivering solutions that exceed their expectations.</li>
                  <li><b>Family of Like Minded Enthusiasts: </b>Join a team of passionate individuals who share your enthusiasm for technology. Collaboration and camaraderie are key pillars of our culture.</li>
                  <li><b>Free and Open Culture: </b>We promote transparency, openness, and a supportive work environment where every voice is heard and valued.</li>
                </ul>
              </p>
            </div>
           </div>
          </div>
          <div className="col-xl-5 col-lg-5 d-flex video-box-second flex-column align-items-stretch justify-content-center rounded order-md-1 order-sm-1 my-lg-5 g-0" style={{ filter: "brightness(70%)" }} data-aos-once="true" data-aos="fade-up">
          </div>
        </div>



        <div className="row my-lg-5">
          <div className="col-xl-5 col-lg-5 d-flex video-box-third justify-content-center align-items-stretch rounded my-lg-5 g-0" style={{filter:"brightness(70%)"}} data-aos-once="true" data-aos="zoom-in">

          </div>
          <div className="col-xl-7 col-lg-7 m-0 p-0">
            <div className='ms-lg-3 d-flex flex-column align-items-stretch justify-content-center py-2 px-sm-5 p-2 rounded-5 about-border-shadow-left'>
              <div className=" text-center mt-5" data-aos-once="true" data-aos="fade-up">
                <h1 className='fw-bold'> Work, Cheers, and Growth. Join the Adventure in Software Development</h1>
              </div>
              <div className="icon-box my-3 pb-3" data-aos-once="true" data-aos="fade-up" data-aos-delay="200">
                <p className="description p-2 mt-4">
                    And of course, who doesn't love a little fun? We believe in work-life balance and offer monthly and quarterly team parties to unwind and celebrate our successes together. Plus, there's always free beer to enjoy!
                </p>
                <p className="description p-2">
                    If you're ready to embark on an exciting journey filled with challenges, growth opportunities, and a supportive community, then H&H Infotech and Consultancy Pvt Ltd is the perfect place for you. Join us in shaping the future of software development, one innovative solution at a time. You can join us by emailing your interest to <a href="mailto:careers@hnhinfotech.com">careers@hnhinfotech.com</a>. We can't wait to welcome you to the H&H family!
                </p>
                {/* </p> */}
                </div>

            </div>
            </div>
        </div>

      </div>
    </div>
    </section>

    </>
  );
};
