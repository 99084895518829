import React from 'react';

const UpcomingHolidays = () => {
    const upcomingHolidays = [
        { date: '1 Jan', name: "New Year's Day" },
        { date: '26 Jan', name: 'Republic Day' },
        { date: '25 Mar', name: 'Holi' },
        { date: '29 Mar', name: 'Good Friday' },
        { date: '15 Aug', name: 'Independence Day' },
        { date: '19 Aug', name: 'Raksha Bandhan (Rakhi)' },
        { date: '2 Oct', name: 'Mahatma Gandhi Jayanti' },
        { date: '11 Oct', name: 'Dussehra' },
        { date: '31 Oct', name: 'Diwali/Deepavali' },
        { date: '4 Nov', name: 'Bhai Duj' },
        { date: '25 Dec', name: 'Christmas' }
    ];
    const today = new Date();

    const filteredHolidays = upcomingHolidays
        .filter(holiday => {
            const holidayDate = new Date(`2024-${holiday.date}`);
            return holidayDate >= today;
        })
        .sort((a, b) => {
            const dateA = new Date(`2024-${a.date}`);
            const dateB = new Date(`2024-${b.date}`);
            return dateA - dateB;
        });

    const closestHoliday = filteredHolidays.length > 0 ? filteredHolidays[0] : null;

    return (
        <>
            <div className='p-3' style={{ minHeight: '400px' }}>
                <h4 className="">Upcoming Holiday</h4>
                <hr />
                <ul className="list-group" style={{ maxHeight: '300px', overflow: 'auto' }}>
                    {filteredHolidays.length > 0 ? (
                        upcomingHolidays.map((holiday, index) => (
                            <li key={index} className={`list-group-item fs-6 ${holiday === closestHoliday ? 'text-white bg-dark' : ''}`}>
                                {holiday.date} - {holiday.name}
                            </li>
                        ))
                    ) : (
                        <li className="list-group-item">No upcoming holidays</li>
                    )}
                </ul>
            </div>
        </>
    );
};

export default UpcomingHolidays;
