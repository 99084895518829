import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/css/style.css";
import { AboutUs } from "./hnh/AboutUs";
import ServiceTab from "./hnh/ServiceTab";
import { HomePage } from "./hnh/HomePage";
import Navbar from './hnh/Navbar'
import Footer from "./hnh/Footer";
import Careers from "./hnh/Career";
import TechnoTab from "./hnh/TechnoTab";
import { Contact } from "./hnh/Contact";
import ApplicationForm from "./hnh/ApplicationForm";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ToastContainer } from "react-toastify";
import { CaseStudy } from "./hnh/CaseStudy";
import AFX from "./hnh/case-study/AFX";
import MaterialEX from "./hnh/case-study/MaterialEX";
import TeraExchange from "./hnh/case-study/TeraExchange";
import APACBourse from "./hnh/case-study/APACBourse";
import Layout from "./Layout";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 500, 
      easing: 'ease', 
    });
  }, []);
  return (
    // <div className="app-container">
    <Router>
      {/* <Topbar/> */}
      <Navbar/>
      <Layout>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
        <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/our-service" element={<ServiceTab />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/technology" element={<TechnoTab />} />
            <Route path="/contact" element={<Contact />} />        
            <Route path="/apply" element={<ApplicationForm />} />        
            <Route path="/case-study" element={<CaseStudy />} />    
            <Route path="/case-study/AFX" element={<AFX />} />    
            <Route path="/case-study/Tera-Exchange" element={<TeraExchange />} />    
            <Route path="/case-study/Material-Exchange" element={<MaterialEX />} />                
            <Route path="/case-study/APACBourse" element={<APACBourse />} />    
        </Routes>
      </Layout>
      <Footer/>
    </Router>
    // </div>
  );
}

export default App;
