import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import image1 from '../assets/img/career/learn.png';
import image2 from '../assets/img/career/coffee-knowledge.png';
import image3 from '../assets/img/career/Neon-podcast-logo.jpg';
import image4 from '../assets/img/career/funFriday.png';

const data = [
  {
    title: "Learn & Grow",
    description: "At H&H, we're committed to providing our team members with the tools, resources, and opportunities they need to learn, grow, and succeed. Our focus on professional development ensures that every member of our team has the support they need to reach their full potential and achieve their career goals.",
    img: image1, background: "orange-background"
  },
  {
    title: "Sip, Share, and Learn",
    description: "Join our Coffee and Knowledge Sessions! At H&H we believe in the power of continuous learning and collaboration. That's why we host regular 'Coffee and Knowledge' sessions, where team members come together to share insights, exchange ideas, and learn from each other over a cup of coffee.",
    img: image2, background: "violet-background"
  },
  {
    title: "Podcast Sessions",
    description: "At H&H, we're passionate about sharing knowledge, insights, and stories from the world of software development. Our podcast sessions are designed to bring you thought-provoking discussions, expert interviews, and valuable insights into the latest trends, technologies, and best practices in the industry.",
    img: image3, background: "blue-background"
  },
  {
    title: "Fun Friday & Engagement Meet",
    description: "We believe in the importance of fostering a positive and engaging workplace culture. That's why we're excited to invite you to our upcoming Fun Friday & Engagement Meet event! It's an opportunity for our team members to come together, unwind, and connect in a relaxed and enjoyable atmosphere.",
    img: image4, background: "yellow-background"
  }
];

function CareerCulture() {
  return (
    <div className="container mt-5">
      <div className="row g-4">
        {data.map((item, index) => (
          <div key={index} className="col-lg-6 col-md-12">
            <div className={`our-project-inner section-with-background p-4 rounded ${item.background}`}>
              <div className="row">
                  <h3 className='text-center fw-bold'>{item.title}</h3>
                <div className="col-md-6">
                  <p>{item.description}</p>
                </div>
                <div className="col-md-6 align-self-center">
                  <img src={item.img} alt="SportVerse" className="img-fluid w-100" style={{ objectFit: 'cover', maxHeight: '200px' }} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CareerCulture;
