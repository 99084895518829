import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import PersonalDetail from "./PersonalDetail";
// import EmployeeDocument from "./EmploeeDocument";
// import { Error } from "../Error";
import Employees from "./Employees";
import PlanMyLeave from "./PlanMyLeave";
import Dashboard from "./Dashboard";
import axiosInstance from '../service/axiosInstance';
import { toast } from "react-toastify";
import AttendanceCheckIn from "./AttendanceCheckIn";
import EmployeeDetails from "./EmployeeDetails";
import FileDisplay from "./FileDisplay";
import uploadimages from '../../assets/img/user.png'
import MainComponent from "../admin/EmpResignation/MainComponent";
import EmployeeFiles from "./EmployeeFiles";
import EmployeePayFormFiles from "./EmployeePayFormFiles";
import SortedCoffeeKnowledgeTable from "./SortedCoffeeKnowledgeTable";

const Profile = () => {
  const {id} = useParams();
  const [firstImage, setFirstImage] = useState({
    file: "",
  });
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const token = localStorage.getItem('jwt');
  const headers = useMemo(() => ({
    Authorization: `Bearer ${token}`
  }),[token]);
  const handleClose = () => {
    setShow(false)
    setFirstImage({...firstImage, file: ""})
  };
  const handleShow = () => setShow(true);
  
  useEffect(() => {
    const storedItem = localStorage.getItem("user");
    if (storedItem) {
      const parsedItem = JSON.parse(storedItem);
      setData(parsedItem);
    }
    if (!storedItem) {
      navigate("/signin");
    }
    const token = localStorage.getItem("jwt");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);
  
  
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
      if (!token) {
          navigate("/signin");
          localStorage.clear();
      }
      try {
        if (userData._id === id){
            console.log("User")
        } else {
            navigate("/signin");
            localStorage.clear();
        }
      } catch (error) {
        navigate("/signin");
        localStorage.clear();
      }
  }, [navigate, token, id, userData]);
  
  const convertToBase64 = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > 50 * 1024) {
      toast.error("Image size should be below 30kb.");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFirstImage({ file: reader.result });
    };
  };
  
  // const uploadimages =
  // "https://images.unsplash.com/photo-1593085512500-5d55148d6f0d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MnwtTWZ4QU9PM2tqOHx8ZW58MHx8fHx8";

  const [secondImage, setSecondImage] = useState()

  const [infoData, setInfoData] = useState()
  useEffect(() => {
    axiosInstance.get(`/user/${id}`, {headers})
    .then((response) => {
      return response.data;
    })
      .then((data) => {
        setSecondImage(data.image);
        setInfoData(data);
      })
      .catch(error => {
        if (error.response?.status === 401) {
          toast('Token expired or invalid. Redirecting to login page...');
          localStorage.clear();
          navigate("/signin");
        } else {
          console.error('Request failed:', error.message);
        }
      });
  },[headers, id, navigate]);
  
  const handleImageChange = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', firstImage.file);
    
    const userId = data._id

    axiosInstance.put(`/users/${userId}/image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ image: firstImage.file }),
    })
    .then(response => {
      if (response?.status === 200) {
        toast.success(response.data.message)
        setSecondImage(firstImage.file);
        handleClose()
      }
    })
    .catch(error => {
        toast.error("Image not uploaded")
        console.error(error);
    });
};
  
  const [activeTab, setActiveTab] = useState('dashboard');
  const [employeesData, setEmployeesData] = useState([]);
  useEffect(() => {
    axiosInstance.get("/users",{headers})
    .then((response) => {
        setEmployeesData(response.data);
    })
    .catch(error => {
      if (error.response && error.response?.status === 401) {
        toast('Token expired or invalid. Redirecting to login page...');
        localStorage.clear();
        navigate("/signin");
      } else {
        console.error('Request failed:', error.message);
      }
    });
  }, [headers, navigate]);

  const [, setFormData] = useState()
  // const [first, setfirst] = useState(false)
  const fetchEmployeeDetails = useCallback(() => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      navigate("/signin");
      return;
    }
  
    var headers = {
      Authorization: `Bearer ${token}`
    };
  
    axiosInstance.get(`/getEmployeeByPostedBy/${id}`, { headers })
      .then(response => {
        setFormData(response.data.employee);
        // setfirst(true);
      })
      .catch(error => {
        if (error.response?.status === 401) {
          toast('Token expired or invalid. Redirecting to login page...');
          localStorage.clear();
          navigate("/signin");
        } else {
          console.error('Request failed:', error.message);
        }
      });
  },[id, navigate]);
  
  useEffect(() => {
    fetchEmployeeDetails();
  }, [fetchEmployeeDetails]);
  
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSessions = useCallback(async () => {
    const token = localStorage.getItem('jwt');
    const headers = {
      Authorization: `Bearer ${token}`
    };

    try {
      const response = await axiosInstance.get('/api/coffeeKnowledge', { headers });
      setSessions(response.data);
      checkLinks(response.data);
    } catch (error) {
      console.error('Error fetching sessions:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const checkLinks = (data) => {
    data.forEach(session => {
      if (!session.link) {
        toast.warn(`Coffee and Knowledge session on "${session.topic}" scheduled for ${new Date(session.date).toLocaleDateString()}`);
      }
    });
  };

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div className="container border-bottom border-dark my-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="position-relative circle">
              <img
                className="img-fluid rounded-circle border"
                src={secondImage ? secondImage : uploadimages}
                alt="..."
              />
              <i className="bi bi-pencil edit-icon" onClick={handleShow}></i>
              {show && (
                <>
                  <div className="modal-backdrop fade show"></div>
                  <div className="modal d-block" tabIndex="-1" style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Upload Image</h5>
                          <button type="button" className="btn-close" onClick={handleClose}></button>
                        </div>
                        <div className="modal-body">
                          <div className="position-relative circle">
                            <img
                              className="img-fluid rounded-circle border"
                              src={firstImage.file ? firstImage.file : secondImage ? secondImage : uploadimages}
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                          <label htmlFor="fileInput" className="cursor-pointer btn btn-dark">
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              accept=".jpeg, .png, .jpg"
                              onChange={(e) => convertToBase64(e)}
                            />
                            Upload Photo
                          </label>
                          {firstImage.file &&
                            <div type="button" className="btn btn-danger" onClick={handleImageChange}>
                              Save
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6">
            {infoData ? (
              <div>
                <h2>Employee Profile</h2>
                {/* <h6>{infoData._id}</h6> */}
                <h5 className="fs-6 fw-bold">
                  Name: <span className="fw-normal">{infoData.name}</span>
                </h5>
                <h5 className="fs-6 fw-bold">
                Designation: <span className="fw-normal">{infoData.department}</span>
                </h5>
                <h5 className="fs-6 fw-bold">
                  Department: <span className="fw-normal">{infoData.currentSalary}</span>
                </h5>
                <h5 className="fs-6 fw-bold">
                  Email: <span className="fw-normal">{infoData.email}</span>
                </h5>
              </div>
            ) : (
              <p>
                <div className="text-center">
                  {/* Skeleton UI */}
                  <div className="skeleton-card mb-3"></div>
                  <div className="skeleton-card mb-3"></div>
                  <div className="loading-animation"></div>

                </div>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="container">
      <div className="overflow-auto">
        <ul className="nav nav-tabs flex-nowrap">
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'dashboard' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('dashboard')}>
              Dashboard
            </div>
          </li>
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'profile' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('profile')}>
              Profile
            </div>
          </li>
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'planLeave' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('planLeave')}>
              Leave
            </div>
          </li>
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'employee' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('employee')}>
              Employees
            </div>
          </li>
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'attendance' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('attendance')}>
              Attendance
            </div>
          </li>
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'separation' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('separation')}>
              Separation
            </div>
          </li>
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'files' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('files')}>
              Files
            </div>
          </li>
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'payroll' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('payroll')}>
              Payroll
            </div>
          </li>
          <li className="nav-item cursor-pointer">
            <div className={`nav-link ${activeTab === 'coffee' ? 'text-danger active' : 'text-dark'}`}
                 onClick={() => setActiveTab('coffee')}>
              Coffee & Knowledge
            </div>
          </li>
        </ul>
      </div>

      <div className="tab-content">
        {activeTab === "dashboard" && <Dashboard employeesData={employeesData} sessions={sessions}/>}
        {activeTab === "profile" && (
          <>
            <EmployeeDetails infoData={infoData}/>
            <FileDisplay/>
          </>
        )}
        {activeTab === "planLeave" && <PlanMyLeave employeesData={employeesData}/>}
        {activeTab === "employee" && <Employees employeesData={employeesData}/>}
        {activeTab === "attendance" && <AttendanceCheckIn/>}
        {activeTab === "separation" && <MainComponent/>}
        {activeTab === "files" && <EmployeeFiles/>}
        {activeTab === "payroll" && <EmployeePayFormFiles/>}
        {activeTab === "coffee" && <SortedCoffeeKnowledgeTable sessions={sessions}/>}
      </div>
    </div>
    </>
  );
};

export default Profile;
