import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../service/axiosInstance';
import { FaTrash, FaEdit } from 'react-icons/fa';

function HiringTab({employeesData}) {
  const [interviews, setInterviews] = useState([]);
  const [formData, setFormData] = useState({
    candidateName: '',
    interviewer: '',
    jobTitle: '',
    date: '',
    email: '',
    phone: '',
    interviewNotes: '',
    feedback: ''
  });

  const [editModalData, setEditModalData] = useState(null); 
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    fetchInterviews();
  }, []);

  const fetchInterviews = () => {
    axiosInstance.get('/api/interviews')
      .then(response => {
        setInterviews(response.data);
      })
      .catch(error => {
        console.error('Error fetching interviews:', error);
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const Jobs = [
    {title:"Ruby-On-Rails"}, {title:"React"}, {title:"DevOps"}, {title:"GoLang"}
  ]

  const handleSubmit = (e) => {
    // console.log("Submit", formData);
    e.preventDefault();
    axiosInstance.post('/api/interviews', formData)
      .then(response => {
          setFormData({
            candidateName: '',
            interviewer: '',
            jobTitle: '',
            date: '',
            email: '',
            phone: '',
            interviewNotes: '',
            feedback: ''
          });
          fetchInterviews();
      })
      .catch(error => {
        console.error('Error creating interview:', error);
      });
  };

  const handleEdit = (interview) => {
    setEditModalData(interview);
    setShowEditModal(true);
  };

  const handleDelete = (id) => {
    axiosInstance.delete(`/api/interviews/${id}`)
      .then(response => {
        if (response.status === 200) {
          fetchInterviews();
        } else {
          console.error('Failed to delete interview');
        }
      })
      .catch(error => {
        console.error('Error deleting interview:', error);
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const formattedDate = new Date(editModalData.date).toISOString().split('T')[0];
    const updatedFormData = { ...editModalData, date: formattedDate };

    axiosInstance.put(`/api/interviews/${editModalData._id}`, updatedFormData)
      .then(response => {
        setShowEditModal(false);
        fetchInterviews();
      })
      .catch(error => {
        console.error('Error updating interview:', error);
      });
  };
  
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="container p-0 mt-5">

      <div className="shadow p-4" style={{maxWidth: "500px"}}>
      <h1 className="fs-2  text-center mb-4">INTERVIEWS</h1>
        <div className="" style={{width:"auto"}}>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="candidateName" className="form-label">Candidate Name</label>
              <input type="text" className="form-control" id="candidateName" name="candidateName" value={formData.candidateName} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label htmlFor="interviewer" className="form-label">Interviewer</label>
              <select className="form-select" name="interviewer" id="interviewer" value={formData.interviewer} onChange={handleChange} required>
                <option value="">Select Interviewer</option>
                {employeesData.map((emp) => (
                  <option key={emp.id} value={emp.name}>{emp.name}</option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="jobTitle" className="form-label">Job Title</label>
              <select className="form-select" name="jobTitle" id="jobTitle" value={formData.jobTitle} onChange={handleChange} required>
                <option value="">Select Job Title</option>
                  {Jobs.map((job, index) => (
                    <option key={index} value={job.title}>{job.title}</option>
                  ))}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="date" className="form-label">Date</label>
              <input type="date" className="form-control" id="date" name="date" value={formData.date} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="text" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label htmlFor="interviewNotes" className="form-label">Interview Notes</label>
              <textarea className="form-control" id="interviewNotes" name="interviewNotes" value={formData.interviewNotes} onChange={handleChange}></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="feedback" className="form-label">Feedback</label>
              <textarea className="form-control" id="feedback" name="feedback" value={formData.feedback} onChange={handleChange}></textarea>
            </div>
            <div className='text-center'>
              <button type="submit" className="btn btn-button">Create Interview</button>
            </div>
          </form>
        </div>
      </div>

      <h2 className="mt-5">All Interviews</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Candidate Name</th>
              <th scope="col">Interviewer</th>
              <th scope="col">Job Title</th>
              <th scope="col">Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {interviews.map(interview => (
              <tr key={interview._id}>
                <td>{interview.candidateName}</td>
                <td>{interview.interviewer}</td>
                <td>{interview.jobTitle}</td>
                <td>{interview.date.split('T')[0]}</td>
                <td className='d-flex'>
                  <button className="btn btn-outline-primary btn-sm me-2" onClick={() => handleEdit(interview)}><FaEdit /></button>
                  <button className="btn btn-outline-danger btn-sm" onClick={() => handleDelete(interview._id)}><FaTrash /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showEditModal && (
        <div>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                {/* Modal Header */}
                <div className="modal-header">
                  <h5 className="modal-title">Edit Interview</h5>
                  <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                </div>
                {/* Modal Body */}
                <div className="modal-body">
                  {/* Edit Form */}
                  <form onSubmit={handleUpdate}>
                    <div className="mb-3">
                      <label htmlFor="candidateName" className="form-label">Candidate Name</label>
                      <input type="text" className="form-control" id="candidateName" name="candidateName" value={editModalData.candidateName} onChange={(e) => setEditModalData({ ...editModalData, candidateName: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="interviewer" className="form-label">Interviewer</label>
                      <input type="text" className="form-control" id="interviewer" name="interviewer" value={editModalData.interviewer} onChange={(e) => setEditModalData({ ...editModalData, interviewer: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="jobTitle" className="form-label">Job Title</label>
                      <select className="form-select" name="jobTitle" id="jobTitle" value={editModalData.jobTitle} onChange={(e) => setEditModalData({ ...editModalData, jobTitle: e.target.value })} required>
                        <option value="">Select Job Title</option>
                        {Jobs.map((job, index) => (
                          <option key={index} value={job.title}>{job.title}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="date" className="form-label">Date</label>
                      <input type="date" className="form-control" id="date" name="date" value={editModalData.date ? formatDate(editModalData.date) : ''} onChange={(e) => setEditModalData({ ...editModalData, date: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="email" className="form-control" id="email" name="email" value={editModalData.email} onChange={(e) => setEditModalData({ ...editModalData, email: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">Phone</label>
                      <input type="text" className="form-control" id="phone" name="phone" value={editModalData.phone} onChange={(e) => setEditModalData({ ...editModalData, phone: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="interviewNotes" className="form-label">Interview Notes</label>
                      <textarea className="form-control" id="interviewNotes" name="interviewNotes" value={editModalData.interviewNotes} onChange={(e) => setEditModalData({ ...editModalData, interviewNotes: e.target.value })}></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="feedback" className="form-label">Feedback</label>
                      <textarea className="form-control" id="feedback" name="feedback" value={editModalData.feedback} onChange={(e) => setEditModalData({ ...editModalData, feedback: e.target.value })}></textarea>
                    </div>
                    <div className="text-center">
                      <button type="submit" className="btn btn-button">Update Interview</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HiringTab;
