import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../../service/axiosInstance';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import BASE_URL from '../../service/config';

const Form16Upload = ({ employeeId }) => {
  const [rows, setRows] = useState([]);
  const token = localStorage.getItem('jwt');
  const headers = useMemo(() => ({
    Authorization: `Bearer ${token}`
  }), [token]);

  useEffect(() => {
    const fetchForm16Files = async () => {
      if (employeeId) {
        try {
          const response = await axiosInstance.get(`/api/form16/${employeeId}`, { headers });
          setRows(response.data);
        } catch (error) {
          console.error('Error fetching Form 16 files:', error);
        }
      }
    };

    fetchForm16Files();
  }, [employeeId, headers]);

  const handleChange = async (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);

    try {
      await axiosInstance.put(`/api/form16/${newRows[index]._id}`, { [field]: value }, { headers });
      toast.success('Update successful');
    } catch (error) {
      console.error('Error updating Form 16 file:', error);
      toast.error('Failed to update file');
    }
  };

  const handleFileChange = async (index, e) => {
    const file = e.target.files[0];
    const newRows = [...rows];

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axiosInstance.put(`/api/form16/upload/${newRows[index]._id}`, formData, { headers });

      newRows[index].filePath = response.data.filePath;
      setRows(newRows);
      toast.success('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    }
  };

  const handleDelete = async (id, index) => {
    try {
      await axiosInstance.delete(`/api/form16/${id}`, { headers });
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
      toast.success('File deleted successfully');
    } catch (error) {
      console.error('Error deleting file:', error);
      toast.error('Failed to delete file');
    }
  };

  return (
    <div className="font-content col-md-12">
      <table className="font-content table table-responsive text-nowrap" style={{ fontSize: '13px' }}>
        <thead>
          <tr>
            <th>View to Employee</th>
            <th>File</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <Switch
                  onChange={(checked) => handleChange(index, 'viewToEmployee', checked)}
                  checked={row.viewToEmployee}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="font-content react-switch"
                  id={`view-to-employee-switch-${index}`}
                />
              </td>
              <td>
                {row.filePath ? (
                  <div>
                    <a
                      // href={row.filePath}
                      href={`${BASE_URL}/${row.filePath}`} 
                      target="_blank"
                      rel="noopener noreferrer"
                      download={row.filePath.split('-')[1]}
                    >
                      {row.filePath.split('-')[1]}
                    </a>
                  </div>
                ) : (
                  <input type="file" className="font-content form-control-announce" onChange={(e) => handleFileChange(index, e)} />
                )}
              </td>
              <td>
                <input
                  type="text"
                  className="font-content form-control-announce"
                  value={row.description}
                  onChange={(e) => handleChange(index, 'description', e.target.value)}
                />
              </td>
              <td>
                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(row._id, index)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Form16Upload;
