import React, { useEffect, useState } from 'react';
import axiosInstance from '../service/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FaMinus } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import DatePicker from 'react-datepicker';

function OnboardEmployee() {
  const [candidateData, setCandidateData] = useState( { } );
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [, setImageError] = useState('');
  const [image, setImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [workExperience, setWorkExperience] = useState([])
  const [checked, setChecked] = useState(false)
  
  const { id } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = () => {
      axiosInstance.get(`/api/candidate/details`, { 
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${id}`
        }
       })
      .then(response => {
          setCandidateData(response.data.candidate);
          setWorkExperience(response.data.candidate.workExperiences);
      })
      .catch(error => {
          console.error('Error fetching candidate data:', error.message);
          navigate('/')
      });
    };
  
    fetchData();
  }, [id , navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
      setCandidateData({ ...candidateData, [name]: value });
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    } else if (name === 'photo') {
      const file = e.target.files[0];
      if (file && file.size > 30 * 1024) {
        setImageError('Image size must be below 30kb.');
      } else {
        setImageError('');
        setImage(file);
      }
    } else {
      setCandidateData({ ...candidateData, [name]: value });
    }
    // console.log('setCandidateData', candidateData);
  };


  const handleWorkChange = (e, index) => {
    const { name, value } = e.target;
    setWorkExperience(prevWorkExperience => {
      const updatedExperience = [...prevWorkExperience];
      updatedExperience[index] = {
        ...updatedExperience[index],
        [name]: value
      };
      return updatedExperience;
    });
  };
  const addRow = () => {
    setWorkExperience([...workExperience, {
      companyName: '',
      jobTitle: '',
      fromDate: '',
      toDate: '',
      jobDescription: '',
      relevant: true 
    }]);
  };

  const removeRow = (indexToRemove) => {
    setWorkExperience(prevWorkExperience => {
      const updatedExperience = prevWorkExperience.filter((experience, index) => index !== indexToRemove);
      return updatedExperience;
    });
  };
  
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate passwords
    if (!validatePassword(password)) {
      setPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.');
      return;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    }
    // Validate image size
    if (image && image.size > 30 * 1024) {
      setImageError('Image size must be below 30kb.');
      return;
    }
    const validationErrors = {};
    if (!/^[0-9]{6}$/.test(candidateData.presentPostalCode)) {
      validationErrors.presentPostalCode = "Please enter a valid 6-digit postal code.";
    }
    if (!/^[0-9]*$/.test(candidateData.experience)) {
      validationErrors.experience = "Please enter a valid number for experience.";
    }
    if (!candidateData.firstName || !/^[a-zA-Z]+$/.test(candidateData.firstName)) {
      validationErrors.firstName = 'First name should contain only letters';
    }
    
    if (!candidateData.lastName || !/^[a-zA-Z]+$/.test(candidateData.lastName)) {
      validationErrors.lastName = 'Last name should contain only letters';
    }      
    
    if (!candidateData.panNumber && candidateData.panNumber.length !== 10) {
    validationErrors.panNumber = 'PAN number should be 10 characters long';
    }
    
    if (!candidateData.aadhaarNumber && !/^\d{16}$/.test(candidateData.aadhaarNumber)) {
      validationErrors.aadhaarNumber = 'Aadhaar number should be 12 numeric characters long';
    }
    if (!candidateData.phone || !/^\d{10}$/.test(candidateData.phone)) {
      validationErrors.phone = 'Phone number should be 10 numeric characters long';
    }
    setErrors(validationErrors);
    const formData = {
      ...candidateData,
      workExperiences: workExperience,
      name: candidateData.firstName + ' ' + candidateData.lastName
    }

    // console.log(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      // console.log("candidateData", formData)
      axiosInstance.post('/api/candidate/form', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${id}`
        }
      })
        .then(response => {
          // console.log('Candidate form submitted successfully');
          toast.success('Form submitted successfully')
          navigate('/signin')
        })
        .catch(error => {
          if (error.response) {
            console.error('Error submitting candidate form:', error.response.data);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error sending request:', error.message);
          }
        });
      };
  };

  if (!candidateData) {
    return <p>Loading...</p>;
  }

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|~\-={}[\]:;"'<>,.?\\/])(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleCheckChange = () => {
    setChecked(!checked);
    if (!checked) {
      setCandidateData(prevDetails => ({
        ...prevDetails,
        permanentAddress: candidateData.presentAddress,
        permanentState: candidateData.presentState,
        permanentPostalCode: candidateData.presentPostalCode
      }));
    }
  }

  return (
    <div className="container p-2 shadow input-field gap-3">
    <div className='text-center'>
        <img src="https://www.hnhinfotech.com/static/media/hnh-logo.0c587d7baa167f74414f.png" alt="hnh-logo" width="50px" height="50px"/>
    </div>
        <h2 className='text-center fw-bold'>H&H Infotech and Consultancy Pvt Ltd</h2>
        <hr />
      <form onSubmit={handleSubmit}>
        <div className="mt-4 ms-3 fw-bold fs-4">Candidate Details</div>
        <div className="p-4 row row-cols-lg-2 row-cols-md-2">

          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="firstName" className="form-label font-sub-title mb-0">First Name</label>
            </div>
            <div className="col-lg-8">
              <input 
                // className='form-control-announce' 
                className={candidateData.firstName && !/^[a-zA-Z]+$/.test(candidateData.firstName) ? "form-control is-invalid" : "form-control-announce"} 
                pattern="^[a-zA-Z]+$" 
                title="First name should contain only letters."
                type="text" name="firstName" placeholder='First Name' value={candidateData.firstName} onChange={handleInputChange} />
              {errors.firstName && <div className="text-danger">{errors.firstName}</div>}

            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="lastName" className="form-label font-sub-title mb-0">Last Name</label>
            </div>
            <div className="col-lg-8">
              <input 
                // className='form-control-announce'
                className={candidateData.lastName && !/^[a-zA-Z]+$/.test(candidateData.lastName) ? "form-control is-invalid" : "form-control-announce"} 
                pattern="^[a-zA-Z]+$" 
                title="Last name should contain only letters."
                type="text" name="lastName" placeholder='Last Name' value={candidateData.lastName} onChange={handleInputChange} />
               {errors.lastName && <div className="text-danger">{errors.lastName}</div>}

            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="email" className="form-label font-sub-title mb-0">Email</label>
            </div>
            <div className="col-lg-8">
              <input className='form-control-announce' type="email" name="email" placeholder='Email' value={candidateData.email} onChange={handleInputChange} readOnly/>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="phone" className="form-label font-sub-title mb-0">Phone</label>
            </div>
            <div className="col-lg-8">
              <input 
                // className='form-control-announce'
                className={candidateData.phone && !/^\d{10}$/.test(candidateData.phone) ? "form-control is-invalid" : "form-control-announce"} 
                pattern="^\d{10}$" 
                title="Phone number should be 10 numeric characters long."
                type="tel" name="phone" placeholder='Phone' value={candidateData.phone} onChange={handleInputChange} />
              {errors.phone && <div className="text-danger">{errors.phone}</div>}
            </div>
          </div>
          <div>
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label htmlFor="aadhaarNumber" className="form-label font-sub-title mb-0">Aadhaar</label>
              </div>
              <div className="col-lg-8">
                <input className='form-control-announce' type="text" name="aadhaarNumber" placeholder='Aadhaar Number' value={candidateData.aadhaarNumber} onChange={handleInputChange} readOnly/>
                {errors.aadhaarNumber && <div className="text-danger">{errors.aadhaarNumber}</div>}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label htmlFor="panNumber" className="form-label font-sub-title mb-0">PAN Number</label>
              </div>
              <div className="col-lg-8">
                <input className='form-control-announce' type="text" name="panNumber" placeholder='PAN Number' value={candidateData.panNumber} onChange={handleInputChange} readOnly/>
                {errors.panNumber && <div className="text-danger">{errors.panNumber}</div>}
              </div>
            </div>
            {/* dateOfBirth */}
            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label htmlFor="dateOfBirth" className="form-label font-sub-title mb-0">Date Of Birth</label>
              </div>
              <div className="col-lg-9">
                <DatePicker
                  selected={candidateData.dateOfBirth}
                  onChange={(dateOfBirth) => handleInputChange({ target: { name: 'dateOfBirth', value: dateOfBirth } })}
                  className="form-control-announce w-auto cursor-pointer"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  placeholderText='Date Of Birth'
                  required
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
            {/* <DatePicker
              selected={candidateData.dateOfBirth ? new Date(candidateData.dateOfBirth) : new Date()}
              onChange={(dateOfBirth) => handleInputChange({ target: { name: 'dateOfBirth', value: dateOfBirth } })}
              className="form-control-announce w-auto cursor-pointer"
              id="dateOfBirth"
              name="dateOfBirth"
              placeholderText='Date Of Birth'
              required
            /> */}
          </div>
        </div>
          <div className="ms-3 fw-bold fs-4">Address Details</div>
        <div className="p-4">

          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="presentAddress" className="form-label font-sub-title mb-0">Present Address</label>
            </div>
            <div className="col-lg-6">
              <input className='form-control-announce' type="text" name="presentAddress" placeholder='Present Address' value={candidateData.presentAddress} onChange={handleInputChange} required />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="presentState" className="form-label font-sub-title mb-0"></label>
            </div>
            <div className="col-lg-6">
              <input className='form-control-announce' type="text" name="presentState" placeholder='Present State' value={candidateData.presentState} onChange={handleInputChange} required />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="presentPostalCode" className="form-label font-sub-title mb-0"></label>
            </div>
            <div className="col-lg-6">
              <input 
                // className='form-control-announce' 
                type="text" 
                name="presentPostalCode" 
                placeholder='Enter Present Postal Code' 
                value={candidateData.presentPostalCode} 
                onChange={handleInputChange} 
                required 
                pattern="[0-9]{6}" 
                title="Please enter a valid 6-digit postal code."
                className={candidateData.presentPostalCode && !/^[0-9]{6}$/.test(candidateData.presentPostalCode) ? "form-control is-invalid" : "form-control-announce"}
              />
            {errors.presentPostalCode && <div className="invalid-feedback">{errors.presentPostalCode}</div>}
            </div>
          </div>


          

          {/* <div className="p-4"> */}
            <div className="row mb-3">
              <div className="col-lg-2 d-flex align-items-center">
                <label htmlFor="permanentAddress" className="form-label font-sub-title mb-0">Permanent Address</label>
              </div>
              <div className="col-lg-6">
                <div className="d-flex">
                  <input className='' type="checkbox" name="permanentAddress" placeholder='Permanent Address' checked={checked} onChange={handleCheckChange} />
                  <p className='mb-0 ps-2'>Same as present Address</p>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="permanentAddress" className="form-label font-sub-title mb-0"></label>
              </div>
              <div className="col-lg-6">
                <input
                  name="permanentAddress"
                  placeholder="Permanent Address"
                  className='form-control-announce'
                  value={checked ? candidateData.presentAddress : candidateData.permanentAddress} 
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 d-flex align-items-center">
                <label htmlFor="permanentAddress" className="form-label font-sub-title mb-0"></label>
              </div>
              <div className="col-lg-6">
                <input
                  name="permanentState"
                  placeholder="Permanent State"
                  className='form-control-announce'
                  value={checked ? candidateData.presentState : candidateData.permanentState} 
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="permanentAddress" className="form-label font-sub-title mb-0"></label>
              </div>
              <div className="col-lg-6">
                <input
                  name="permanentPostalCode"
                  className='form-control-announce'
                  placeholder="Permanent Postal Code"
                  value={checked ? candidateData.presentPostalCode : candidateData.permanentPostalCode} 
                  onChange={handleInputChange}
                />
                {errors.permanentAddress && <div className="text-danger">{errors.permanentAddress}</div>}
              </div>
            </div>
          {/* </div> */}
        </div>
          <div className="ms-3 fw-bold fs-4">Professional Details</div>
        <div className="p-4 row row-cols-lg-2 row-cols-md-2">

          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="experience" className="form-label font-sub-title mb-0">Experience</label>
            </div>
            <div className="col-lg-7">
              <input type="text" name="experience" placeholder='Experience' value={candidateData.experience} onChange={handleInputChange} 
                className={candidateData.experience && !/^[0-9]*$/.test(candidateData.experience) ? "form-control is-invalid" : "form-control-announce"} 
                pattern="[0-9]*" 
                title="Please enter a valid number for experience."
                required 
              />
            {errors.experience && <div className="invalid-feedback">{errors.experience}</div>}
            </div>
          </div>
          {/* <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="sourceOfHire" className="form-label font-sub-title mb-0">Source Of Hire</label>
            </div>
            <div className="col-lg-7">
              <input className='form-control-announce' type="text" name="sourceOfHire" placeholder='Source Of Hire' value={candidateData.sourceOfHire} onChange={handleInputChange} required />
            </div>
          </div> */}
          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="sourceOfHire" className="form-label font-sub-title mb-0">Source Of Hire</label>
            </div>
            <div className="col-lg-7">
              <select className='form-control-announce bg-white' name="sourceOfHire" id="sourceOfHire" value={candidateData.sourceOfHire} onChange={handleInputChange} required>
                <option value="">Please select</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Naukri">Naukri</option>
                <option value="Reference">Reference</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="highestQualification" className="form-label font-sub-title mb-0">Highest Qualification</label>
            </div>
            <div className="col-lg-7">
              <input className='form-control-announce' type="text" name="highestQualification" placeholder='Highest Qualification' value={candidateData.highestQualification} onChange={handleInputChange} required />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="department" className="form-label font-sub-title mb-0">Department</label>
            </div>
            <div className="col-lg-7">
              <select className='form-control-announce bg-white ' value={candidateData.department} name="department" id="department" onChange={handleInputChange} readOnly disabled required>
                <option value="">Please select</option> 
                <option value="HR Management">HR Management</option>
                <option value="Top Level Management">Top Level Management</option>
                <option value="Quality">Quality</option>
                <option value="Project Management">Project Management</option>
                <option value="Full Stack Developer">Full Stack Developer</option>
                <option value="Frontend Developer">Frontend Developer</option>
                <option value="Backend Developer">Backend Developer</option>
                <option value="DevOps">DevOps</option>
                <option value="Admin">Admin</option>
                <option value="Developer">Developer</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="location" className="form-label font-sub-title mb-0">Location</label>
            </div>
            <div className="col-lg-7">
              <input className='form-control-announce' type="text" name="location" placeholder='Location' value={candidateData.location} onChange={handleInputChange} required />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="currentSalary" className="form-label font-sub-title mb-0">Designation</label>
            </div>

            <div className="col-lg-7">
              <select className='form-control-announce bg-white' value={candidateData.currentSalary} name="currentSalary" id="currentSalary" onChange={handleInputChange} readOnly disabled required>
                <option value="">Please select</option> 
                <option value="HR Manager">HR Manager</option>
                <option value="Director">Director</option>
                <option value="Quality/Tester">Quality/Tester</option>
                <option value="Project Manager">Project Manager</option>
                <option value="Junior DevOps">Junior DevOps</option>
                <option value="Serior DevOps">Serior DevOps</option>
                <option value="Senior Solution Architect">Senior Solution Architect</option>
                <option value="Solution Architect">Solution Architect</option>
                <option value="Senior Software Engineer">Senior Software Engineer</option>
                <option value="Software Engineer">Software Engineer</option>
              </select>
            </div>
          </div>
          <div className="row row-cols-1 mb-3">
            <div className="col-lg-4 d-flex align-items-center">
              <label htmlFor="skillSet" className="form-label font-sub-title mb-0">Skill Set</label>
            </div>
            <div className="col-lg-7">
              <textarea className='form-control-announce' type="text" name="skillSet" placeholder='Skill Set' value={candidateData.skillSet} onChange={handleInputChange} required />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4 mt-1 d-flex align-items-start">
              <label htmlFor="employeeType" className="form-label font-sub-title mb-0">Employee Type</label>
            </div>
            <div className="col-lg-7 d-flex align-items-start">
              <select className='form-control-announce bg-white' value={candidateData.employeeType} name="employeeType" id="employeeType" onChange={handleInputChange} readOnly disabled required>
                <option value="">Please select</option> 
                <option value="Permanent Employee">Permanent Employee</option>
                <option value="Contractual Employee">Contractual Employee</option>
              </select>
            </div>
          </div>
        </div>
        <div className="ms-3 fw-bold fs-4">Others</div>
        <div className="p-4">

          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="tentativeJoiningDate" className="form-label font-sub-title mb-0">Tentative Joining Date</label>
            </div>
            <div className="col-lg-6">
              <DatePicker
                  selected={candidateData.tentativeJoiningDate ? new Date(candidateData.tentativeJoiningDate) : new Date()}
                  onChange={(tentativeJoiningDate) => handleInputChange({ target: { name: 'tentativeJoiningDate', value: tentativeJoiningDate } })}
                  className="form-control-announce w-auto cursor-pointer"
                  id="tentativeJoiningDate"
                  name="tentativeJoiningDate"
                  placeholderText='Date Of Birth'
                  required
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
        <div className="ms-3 fw-bold fs-4">Work Experience</div>
            <div className='btn border' onClick={addRow}><IoAdd /> Add Row</div>
          </div>
        <div className='table-responsive border border-dark'>
          <table className='border-0'>
            <thead>
              <tr className='fw-bold'>
                <td className='text-nowrap'>Company Name</td>
                <td className='text-nowrap'>Job Title</td>
                <td className='text-nowrap'>From Date</td>
                <td className='text-nowrap'>To Date</td>
                <td className='text-nowrap'>Job Description</td>
                <td className='text-nowrap'>Relevant</td>
              </tr>
            </thead>
            <tbody>
            { workExperience.length === 0 ? (
              <>
                <div className="alert alert-light">
                  <div className="">
                    Click on add button to add work experiences
                  </div>
                </div>
              </>
            ) : (
              workExperience.map((experience, index) => (
                <tr key={index}>
                  <td className='text-nowrap'><input type="text" name="companyName" className='form-control-announce' value={experience.companyName} onChange={(e) => handleWorkChange(e, index)} /></td>
                  <td className='text-nowrap'><input type="text" name="jobTitle" className='form-control-announce' value={experience.jobTitle} onChange={(e) => handleWorkChange(e, index)} /></td>
                  <td className='text-nowrap'><input type="date" name="fromDate" className='form-control-announce' value={experience.fromDate && experience.fromDate.split('T')[0]} onChange={(e) => handleWorkChange(e, index)} /></td>

                  <td className='text-nowrap'><input type="date" name="toDate" className='form-control-announce' value={experience.toDate && experience.toDate.split('T')[0]} onChange={(e) => handleWorkChange(e, index)} /></td>

                  <td className='text-nowrap'><textarea name="jobDescription" className='form-control-announce' value={experience.jobDescription} onChange={(e) => handleWorkChange(e, index)} cols="30" rows="2"></textarea></td>
                  <td className='text-nowrap'>
                    <select name="relevant" className='form-control-announce' value={experience.relevant} onChange={(e) => handleWorkChange(e, index)}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </td>
                  <td className='text-nowrap'><div className='text-danger cursor-pointer' onClick={() => removeRow(index) }><FaMinus /></div></td>
                </tr>
              ))
            )}
            </tbody>
          </table>

        </div>
          
          <div className="ms-3 mt-5 fw-bold fs-4">Set Password</div>
          <div className="p-4">
          {/* Password */}
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="password" className="form-label font-sub-title mb-0">Password</label>
            </div>
            <div className="col-lg-8">
              <div className="input-group">
                <input className='form-control' type={showPassword ? "text" : "password"} name="password" placeholder='Password' value={candidateData.password} onChange={handleInputChange} required />
                <div className="input-group-append">
                  <div className="input-group-text p-3" onClick={togglePasswordVisibility}>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </div>
                </div>
              </div>
              {passwordError && <span className="text-danger">{passwordError}</span>}
            </div>
          </div>

            <div className="row mb-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label htmlFor="confirmPassword" className="form-label font-sub-title mb-0">Confirm Password</label>
              </div>
              <div className="col-lg-8">
                <input className='form-control' type="password" name="confirmPassword" placeholder='Confirm Password' value={confirmPassword} onChange={handleInputChange} />
                {confirmPasswordError && <span className="text-danger">{confirmPasswordError}</span>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className='btn btn-button'>Submit</button>
          </div>
      </form>
    </div>
  );
}

export default OnboardEmployee;

