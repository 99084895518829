import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../service/axiosInstance';
import { toast } from 'react-toastify';

const ExitManagement = ({ resignationId, headers }) => {
  const [actions, setActions] = useState({
    tasks: [],
    checklists: [],
    customFunctions: [],
  });
  
  useEffect(() => {
    if (resignationId) {
      setActions({
        tasks: resignationId.tasks,
        checklists: resignationId.checklists,
        customFunctions: resignationId.customFunctions,
      });
    }
  }, [resignationId]);

  const handleStatusChange = async (arrayName, itemId, currentStatus) => {
    const newStatus = currentStatus === 'Pending' ? 'Completed' : 'Pending';
    try {
      const response = await axiosInstance.put(`/api/api/resignations/${resignationId}/${arrayName}/${itemId}`, { status: newStatus }, { headers });
      console.log(response.data);
      setActions((prevActions) => ({
        ...prevActions,
        [arrayName]: response.data[arrayName],
      }));
      toast.success(`Successfully updated status to ${newStatus}`);
    } catch (error) {
      console.error('Error updating item status:', error);
      toast.error('Error updating status');
    }
  };

  return (
    <div className="p-4 bg-light text-dark shadow-sm rounded">
      <div className="-body">
        <h5 className="-title mb-4">Actions</h5>
        <div id="accordion">
          {Object.keys(actions).map((arrayName, index) => (
            <div className="accordion-item border p-2" key={index}>
              <h4 className="accordion-header p-0 m-0" id={`heading${index}`}>
                <div
                  className="d-flex justify-content-between align-items-center"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index}`}
                >
                  {/* <span className='fs-6'>{arrayName.charAt(0).toUpperCase() + arrayName.slice(1)}</span> */}
                  <span className='fs-6'>
                    {arrayName.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase())}
                  </span>

                </div>
              </h4>

              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse show"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <ul className="list-group list-group-flush">
                    {actions[arrayName].map((item) => (
                      <li key={item._id} className="fs-6 list-group-item fs-6 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={item.status === 'Completed'}
                            onChange={() => handleStatusChange(arrayName, item._id, item.status)}
                            id={`status-${item._id}`}
                            readOnly
                            disabled
                          />
                          <div className="">{item.name}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExitManagement;
