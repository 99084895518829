import axiosInstance from '../service/axiosInstance';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

const FileValidate = ({ empId }) => {
    const [filePaths, setFilePaths] = useState({});
    const token = localStorage.getItem('jwt');
    const headers = useMemo(() => ({
        Authorization: `Bearer ${token}`
    }), [token]);

    const fetchFiles = useCallback(() => {
        axiosInstance.get(`/files/${empId}`, { headers })
            .then(response => {
                setFilePaths(response.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    console.log('Files not found yet');
                    setFilePaths({});
                } else {
                    console.error('Error:', error);
                }
            });
    }, [headers, empId]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const renderFileIcon = (key) => (
        <div key={key} className="col-md-6 mb-4">
            <div className="card no-hover-card">
                <div className="card-body">
                    {/* <h5 className="card-title">{capitalizeFirstLetter(key)}</h5> */}
                    <div className="d-flex align-items-center">
                        {filePaths[key] ? (
                            <span className="text-success me-2"><FaCheck /></span>
                        ) : (
                            <span className="text-danger me-2"><FaTimes /></span>
                        )}
                        <span className="file-name">{capitalizeFirstLetter(key)}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="container-fluid mb-5">
            <div className="d-flex justify-content-between fw-bold fs-4 mb-2 align-items-center mt-5">
                <div>Employees Documents</div>
            </div>
            <div className="mb-5">
                {Object.keys(filePaths).length > 0 ? (
                    <div className="row border p-4 shadow">
                        {Object.keys(filePaths).map(key => renderFileIcon(key))}
                    </div>
                ) : (
                    <div className="alert alert-info">
                        No files to display
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileValidate;
