import axiosInstance from '../service/axiosInstance';
import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import BASE_URL from '../service/config';

const FileDisplay = ({ filePaths }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const downloadFile = (fileUrl, fileName) => {
        axiosInstance.get(fileUrl, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data]);
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(anchor.href);
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    };

    const handleImageClick = (filePath, title) => {
        setModalImage(filePath);
        setModalTitle(title);
        setShowModal(true);
    };

    const renderFileCard = (filePath, fileName, cardTitle) => (
        <div className="col-md-6 mb-4">
            <div className="card no-hover-card">
                <div className="card-body">
                    <h5 className="card-title">{cardTitle}</h5>
                    <button className="d-flex btn border w-100" onClick={() => downloadFile(filePath, fileName)}>
                        <span className="me-2"><FaDownload /></span>
                        <span className="file-name ms-2">{fileName}</span>
                    </button>
                </div>
            </div>
        </div>
    );

    const renderImageCard = (filePath, fileName, cardTitle) => (
        <div className="col-md-6 mb-4">
            <div className="card no-hover-card">
                <div className="card-body">
                    <h5 className="card-title">{cardTitle}</h5>
                    <img
                        src={`${BASE_URL}/${filePath}`}
                        alt={cardTitle}
                        className="img-fluid mb-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleImageClick(filePath, cardTitle)}
                    />
                    <button className="d-flex btn border w-100" onClick={() => downloadFile(filePath, fileName)}>
                        <span className="me-2"><FaDownload /></span>
                        <span className="file-name ms-2">{fileName}</span>
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <h1 className="mt-5 mb-4">Employee Files</h1>
            <div className="mb-5">
                {Object.keys(filePaths).length > 0 ? (
                    <div className="row border p-4 shadow">
                        {filePaths.offerLetter && renderFileCard(filePaths.offerLetter, filePaths.offerLetter.split("-").pop(), "Offer Letter")}
                        {filePaths.appointmentLetter && renderFileCard(filePaths.appointmentLetter, filePaths.appointmentLetter.split("-").pop(), "Appointment Letter")}
                        {filePaths.employeeHandbook && renderFileCard(filePaths.employeeHandbook, filePaths.employeeHandbook.split("-").pop(), "Employee Handbook")}
                        {filePaths.kra && renderFileCard(filePaths.kra, filePaths.kra.split("-").pop(), "KRA")}
                        {filePaths.salarySlips && renderFileCard(filePaths.salarySlips, filePaths.salarySlips.split("-").pop(), "Salary Slips")}
                        {filePaths.agreement && renderFileCard(filePaths.agreement, filePaths.agreement.split("-").pop(), "Agreement")}
                        {filePaths.aadhaar && renderImageCard(filePaths.aadhaar, filePaths.aadhaar.split("-").pop(), "Aadhaar")}
                        {filePaths.panCard && renderImageCard(filePaths.panCard, filePaths.panCard.split("-").pop(), "PAN Card")}
                    </div>
                ) : (
                    <div className="alert alert-info">
                        No files to display
                    </div>
                )}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={`${BASE_URL}/${modalImage}`} alt={modalTitle} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FileDisplay;
