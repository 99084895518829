import React from 'react';
// import Widget from './checkIn';
// import RegularizationRequestAdmin from './RegularizationRequestAdmin';

const AttendancePending = ({ pendingData }) => {
  return (
    <>
        <div className="position-relative">
            <div className="card no-hover-card mt-4">
                <div className="card-header bg-dark text-white">Pending Attendance</div>
                {pendingData.length === 0 ? (
                <div className="alert alert-info m-0" role="alert">
                    No pending attendance data available.
                </div>
                ) : (
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                        <th>Employee Name</th>
                        <th>Check-In Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingData.map((record, index) => (
                        <tr key={index}>
                            <td>{record.employeeId.name}</td>
                            <td>{new Date(record.checkIn).toLocaleString()}</td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                )}
            </div>
        </div>
        {/* <Widget/> */}
        {/* <RegularizationRequestAdmin/> */}
    </>
  );
};

export default AttendancePending;
