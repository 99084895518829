import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SideNavbar from './SideNavbar';
import MyDashboard from './MyDashboard';
import HiringTab from './HiringTab';
import axiosInstance from '../service/axiosInstance';
import LeaveForm from './LeaveForm';
import AttendancePending from './AttendancePending';
import RegularizationRequestAdmin from './RegularizationRequestAdmin';
import AdminAttendance from './AdminAttendance';
import uploadimages from '../../assets/img/user.png'
import { handleNetworkError } from '../service/apiUtils';
import OnboardCandidate from './onboardCandidate/OnboardCandidate';
import MainComponent from './AdminResignation/MainComponent';
import MainComponentExit from './ExitDetails/MainComponentExit';
import AdminFilesUpload from './FileUpload/AdminFilesUpload';
import { PayrollComp } from './Payroll/PayrollComp';
import CoffeeKnowledgeApp from './CoffeeAndKnowledge/CoffeeKnowledgeApp';

const AdminEmployees = () => {
    // const uploadimages =
    // "https://images.unsplash.com/photo-1593085512500-5d55148d6f0d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MnwtTWZ4QU9PM2tqOHx8ZW58MHx8fHx8";
    const [updateData, setUpdateData] = useState('')
    const [employeesData, setEmployeesData] = useState([])
    const [, setemployeeAdded] = useState('')
    const navigate = useNavigate();
    const token = localStorage.getItem('jwt');
    const user = localStorage.getItem('user');
    const headers = useMemo(() => ({
        Authorization: `Bearer ${token}`
      }), [token]);
    const { id } = useParams()
    const userData = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (!token || !user) {
            navigate("/signin");
            localStorage.clear();
        }
        try {
            if (userData._id === id){
                console.log("User")
            } else {
                navigate("/signin");
                localStorage.clear();
            }
        } catch (error) {
            navigate("/signin");
            localStorage.clear();
        }
    }, [employeesData, navigate, token, user, id, userData]);
    
    const PostData = (employeeData) => {
        navigate('/file-upload', { state: { employeeData } });
    };

    useEffect(() => {
        axiosInstance.get("/users-admin", {headers}).then((response) => {
          setEmployeesData(response.data);
        }).catch((error) => {handleNetworkError(error)})
    }, [headers, updateData]);

    // const logoutHandle = () =>{
    //     localStorage.clear()
    //     navigate("/signin")
    // }

    const [activeTab, setActiveTab] = useState("dashboard");

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    // Attendanace Pending
    const [pendingData, setPendingData] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);

    const fetchData = useCallback(() => {
      axiosInstance.get('/api/attendanceByCheckout', { headers })
        .then(res => {
          const pendingRecords = res.data.filter(record => !record.checkOut);
          setPendingData(pendingRecords);
          const count = pendingRecords.length;
          setNotificationCount(count);
        })
        .catch(error => handleNetworkError(error));
    },[headers, updateData]);

    // RegularizationRequestAdmin
    const fetchRequests = useCallback(() => {
        axiosInstance.get('/api/api/emp-attendance/regularization-requests', { headers })
          .then(response => {
            const pendingRequestsCount = getPendingRequestsCount(response.data);
            updatePendingRequestsCount(pendingRequestsCount);
          })
          .catch(error => {
            console.error('Error fetching regularization requests:', error);
            handleNetworkError(error);
          });
      },[headers, updateData]);

      useEffect(() => {
        fetchData();
        fetchRequests();
      }, [fetchData, fetchRequests ]);
    
      const getPendingRequestsCount = (data) => {
        return data.reduce((count, request) => {
          if (request.adminApproval === 'pending') {
            return count + 1;
          }
          return count;
        }, 0);
      };
    const [pendingRequestsCount, setPendingRequestsCount] = useState(0);

    const updatePendingRequestsCount = (count) => {
      setPendingRequestsCount(count);
    };
  
    const [pendingLeaves, setPendingLeaves] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/adminLeaves', {headers})
        .then(response => {
          const data = response.data;
  
          const pendingLeavesData = data.filter(leave => leave.status === 'Pending');
  
          setPendingLeaves(pendingLeavesData);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          handleNetworkError(error);
        });
    }, [headers, updateData]);
    // console.log('PendingLeaves', pendingLeaves.length);

    const handleUpdateLeave = (value) => {
        setUpdateData(value)
    }
    return (
        <>
            <div className="row m-1">
                <div className="col-lg-2 sticky-sidebar">
                    <SideNavbar activeTab={activeTab} handleTabChange={handleTabChange} notificationCount={notificationCount} pendingRequestsCount={pendingRequestsCount} pendingLeavesCount={pendingLeaves.length}/>
                </div>
                
                <div className="col-lg-10 mt-2-">
                    {/* <div>
                        <button className="btn-button mt-3 text-center" 
                        onClick={()=>logoutHandle()}
                        >Logout</button>
                    </div> */}
                    {activeTab === "dashboard" && (
                        <MyDashboard employeesData={employeesData} setemployeeAdded={setemployeeAdded} onUpdateData={handleUpdateLeave}/>
                    )}
                    {activeTab === "employees" && (
                        <div className="container my-5">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
                                {employeesData.map((item, index) => {
                                    return (
                                        <div key={index} className="col">
                                            <div className="card text-center pt-4 cursor-pointer" onClick={() => PostData(item)}>
                                                <div className="position-relative circle">
                                                    <div className="img-fluid rounded-circle" style={{ backgroundImage: `url(${item.image ? item.image : uploadimages})` }}></div>
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title">{item.name}</h5>
                                                    <p className="card-text">{item.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    {activeTab === "hiring" && (
                        <HiringTab employeesData={employeesData}/>
                    )}
                    {activeTab === "time-off" && (
                        // <LeavesHistory employeesData={employeesData}/>
                        <LeaveForm employeesData={employeesData} onUpdateData={handleUpdateLeave}/>
                    )}
                    {activeTab === "attendance" && (
                        // <Attendance employeesData={employeesData}/>
                        <AdminAttendance employeesData={employeesData}/>
                    )}
                    {activeTab === "onboard" && (
                        // <OnboardAdmin infoData={employeesData}/>
                        <OnboardCandidate infoData={employeesData} setemployeeAdded={setemployeeAdded}/>
                        // <SignUp/>
                    )}
                    {activeTab === "resignation" && (
                        <MainComponent infoData={employeesData} setemployeeAdded={setemployeeAdded}/>
                    )}
                    {activeTab === "exit-interview" && (
                        <MainComponentExit infoData={employeesData} setemployeeAdded={setemployeeAdded}/>
                    )}
                    {activeTab === "notification" && (
                        <>
                            <AttendancePending pendingData={pendingData} />
                            <RegularizationRequestAdmin onUpdateData={handleUpdateLeave}/>
                        </>
                    )}
                    {activeTab === "files" && (
                        <>
                            <AdminFilesUpload/>
                        </>
                    )}
                    {activeTab === 'payroll' && (
                        <>
                            <div className="shadow mt-3 p-3">
                                <PayrollComp employeesData={employeesData}/>
                            </div>
                        </>
                    )}
                    {activeTab === "Coffee&Knowledge" && (
                        <>
                            <CoffeeKnowledgeApp employeesData={employeesData}/>
                        </>
                    )}
                </div>
            </div>
        </>
      );
}
  
export default AdminEmployees;
