import React from "react";
import EmployeeAnnouncement from "./EmployeeAnnouncement";
import UpcomingHolidays from "./UpcomingHolidays";
import BirthdayDisplay from "../component/BirthdayDisplay";
import CoffeeKnowledge from "./CofeeKnowledge";
import AttendanceTable from "./AttendanceTable";

const Dashboard = ({ employeesData, sessions }) => {
  return (
    <div className="container mt-3">
      <div className="mb-4">
        <h3 className="mb-4">My Dashboard</h3>
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-3">
          <div className="col-md-6 col-xl-4">
            <div className="shadow">
              <EmployeeAnnouncement />
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="shadow">
              <BirthdayDisplay employeesData={employeesData} today />
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="shadow">
              <UpcomingHolidays />
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="shadow">
              <CoffeeKnowledge sessions={sessions} />
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="shadow">
              <AttendanceTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
