import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-content">
          <div className="about-hnh mt-4">
              <h2 style={{fontSize:"22px", color: "#DA251C" }}>H&H</h2>
            <p>
              H&H Infotech and Consultancy Pvt Ltd, formerly known as Connamara Software Pvt Ltd, was established in 2004 and is an India based software development firm working offshore for Connamara Systems, Chicago.
            </p>
            <div className="social">
              <a href="#twitter"><i className="bi bi-twitter" rel="noreferrer"></i></a>
              <a href="#facebook"><i className="bi bi-facebook" rel="noreferrer"></i></a>
              {/* <a href="https://www.instagram.com/hnh_infotech/" target='_blank' rel="noreferrer"><i className="bi bi-instagram"></i></a>
              <a href="https://www.linkedin.com/company/hnh-infotech-consultancy-pvt-ltd/about/" target='_blank' rel="noreferrer"><i className="bi bi-linkedin"></i></a> */}
              <a href="#insta" ><i className="bi bi-instagram"></i></a>
              <a href="#linkedin" ><i className="bi bi-linkedin"></i></a>
            </div>
          </div>
          <div className="info-section mt-4">
            <h3 style={{fontSize:"22px", color: "#DA251C" }}>INFORMATION</h3>
            <p >
              <FontAwesomeIcon icon={faMapMarkerAlt} /> Location: 418 A, 4<sup>th</sup> floor, Suncity Trade Tower,<br /> Sec - 21, Gurugram - 122016, Haryana, India
            </p>
            <p ><FontAwesomeIcon icon={faEnvelope} /> Email: <a href="mailto:ask@hnhinfotech.com">ask@hnhinfotech.com</a></p>
            <p ><FontAwesomeIcon icon={faPhone} /> +91 124 4039532</p>
          </div>

          <div className="support-section mt-4">
            <h3 style={{ fontSize:"22px", color: "#DA251C" }}>SUPPORT</h3>
            <p>We (H&H and Connamara) value quality over quantity and, in doing so, have created an amazing family of like-minded software enthusiasts, both in Chicago and India.</p>
          </div>
        </div>
      </div>

      <div className="copyright">
        <p>© Copyright <span style={{ color: "#DA251C" }}>H&H</span>. All Rights Reserved</p>
        <p>Designed by <span style={{ color: "#DA251C" }}>H&H</span></p>
      </div>
    </div>
  );
};

export default Footer;
