import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/css/style.css";
import { AboutUs } from "./hnh/AboutUs";
import ServiceTab from "./hnh/ServiceTab";
import { HomePage } from "./hnh/HomePage";
import Navbar from './hnh/Navbar'
import Footer from "./hnh/Footer";
import Careers from "./hnh/Career";
import TechnoTab from "./hnh/TechnoTab";
import { Contact } from "./hnh/Contact";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ToastContainer } from "react-toastify";
import { CaseStudy } from "./hnh/CaseStudy";
import AFX from "./hnh/case-study/AFX";
import MaterialEX from "./hnh/case-study/MaterialEX";
import TeraExchange from "./hnh/case-study/TeraExchange";
import APACBourse from "./hnh/case-study/APACBourse";
import Layout from "./Layout";
import Profile from "./hnh/employee/Profile";
import Employees from "./hnh/employee/Employees";
import SignIn from "./hnh-login/SignIn";
import AdminEmployees from "./hnh/admin/AdminEmployees";
import { Error } from "./hnh/Error";
import FileUpload from "./hnh/admin/FileUploadTest";
import OnboardEmployee from "./hnh/admin/OnboardEmployee";
import ForgotPassword from "./hnh-login/ForgotPassword";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 500, 
      easing: 'ease', 
    });
  }, []);
  return (
    // <div className="app-container">
    <Router>
      {/* <Topbar/> */}
      <Navbar/>
      <Layout>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
        <Routes>
            <Route path="*" element={<Error />} />
            <Route path="/" exact element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/our-service" element={<ServiceTab />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/technology" element={<TechnoTab />} />
            <Route path="/contact" element={<Contact />} />        
            {/* <Route path="/apply" element={<ApplicationForm />} />         */}
            <Route path="/case-study" element={<CaseStudy />} />    
            <Route path="/case-study/AFX" element={<AFX />} />    
            <Route path="/case-study/Tera-Exchange" element={<TeraExchange />} />    
            <Route path="/case-study/Material-Exchange" element={<MaterialEX />} />                
            <Route path="/case-study/APACBourse" element={<APACBourse />} />    
            <Route path="/profile/:id" element={<Profile />} />    
            <Route path="/employees" element={<Employees />} />    
            <Route path="/signin" element={<SignIn />}></Route>
            {/* <Route path="/signup/:id" element={<SignUp />}> </Route>  */}
            <Route path="/admin-employees/:id" element={<AdminEmployees />}> </Route> 
            <Route path="/file-upload" element={<FileUpload />}> </Route>             
            <Route path="/candidate/:id" element={<OnboardEmployee />}> </Route>             
            <Route path="/forgot-password" element={<ForgotPassword />}> </Route>             
        </Routes>
      </Layout>
      <Footer/>
    </Router>
    // </div>
  );
}

export default App;
