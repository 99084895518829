import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../../service/axiosInstance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditResignationForm = ({ resignation, infoData, onClose, fetchResignations, headers }) => {
  const [employeeId, setEmployeeId] = useState('');
  const [dateOfRelieving, setDateOfRelieving] = useState(null);
  const [lastWorkingDay, setLastWorkingDay] = useState(null);
  const [finalSettlementDate, setFinalSettlementDate] = useState(null);
  const [reason, setReason] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    if (resignation) {
      setEmployeeId(resignation.employeeId._id);
      setDateOfRelieving(new Date(resignation.dateOfRelieving));
      setLastWorkingDay(new Date(resignation.lastWorkingDay));
      setFinalSettlementDate(new Date(resignation.finalSettlementDate));
      setReason(resignation.reason);
      setIsAccepted(resignation.isAccepted);
    }
  }, [resignation]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedResignation = {
        employeeId,
        dateOfRelieving,
        lastWorkingDay,
        finalSettlementDate,
        reason,
        isAccepted,
      };

      await axiosInstance.patch(`/api/api/resignations/${resignation._id}`, updatedResignation, {headers});
      toast.success('Resignation form updated successfully')
      fetchResignations();
      onClose();
    } catch (error) {
      console.error('Error updating resignation:', error);
      toast.error("Error updating resignation")
    }
  };

  return (
    <>
    <div className="shadow">
    <h3 className='pt-2 px-2'>Resignation Form</h3>
    <hr />
    <div className="p-4">
    <form onSubmit={handleSubmit}>
      <div className="row mb-3">
        <div className="col-xl-6">
          <div className="row mb-3">
            <div className='col-lg-4 d-flex align-items-end'>
              <label htmlFor="dateOfRelieving" className="form-label">Date of Relieving</label>
            </div>
            <div className="col-lg-8">
              <DatePicker
                selected={dateOfRelieving}
                onChange={(date) => setDateOfRelieving(date)}
                className={`form-control-announce`}
                dateFormat="yyyy-MM-dd"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="row mb-3">
            <div className='col-lg-2 d-flex align-items-end'>
              <label htmlFor="lastWorkingDay" className="form-label">Notice period</label>
            </div>
            <div className="col-lg-4">
              <DatePicker
                selected={lastWorkingDay}
                onChange={(date) => setLastWorkingDay(date)}
                className="form-control-announce"
                dateFormat="yyyy-MM-dd"
                placeholderText='From'
                required
              />
            </div>
            <div className="col-lg-4">
              <DatePicker
                selected={finalSettlementDate}
                onChange={(date) => setFinalSettlementDate(date)}
                className="form-control-announce"
                dateFormat="yyyy-MM-dd"
                placeholderText='To'
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className="row">
            <div className='col-lg-2 d-flex align-items-end'>
              <label htmlFor="reason" className="form-label">Reason for Resignation</label>
            </div>
            <div className="col-lg-7">
              <textarea
                className={`form-control-announce`}
                id="reason"
                rows="1"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-12 d-flex align-items-center">
          <input
            type="checkbox"
            className="form-check-input me-2"
            id="isAccepted"
            checked={isAccepted}
            onChange={(e) => setIsAccepted(e.target.checked)}
          />
          <label htmlFor="isAccepted" className="form-check-label">Resignation Accepted?</label>
        </div>
      </div>
      <button type="submit" className="btn btn-button">Submit</button>
    </form>
      </div>
    </div>
    </>
  );
};

export default EditResignationForm;
