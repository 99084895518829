import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../service/axiosInstance';
// import { FaCheck, FaTimes } from 'react-icons/fa';
import { RiArrowDownLine, RiArrowUpLine, RiDeleteBinLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import AttendanceCalendar from './CalendarAttendance';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';

const PlanMyLeave = ({ employeesData }) => {
    const [activeTab, setActiveTab] = useState('applyLeave');
    const { id } = useParams()
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };    

    const filteredEmployee = employeesData.find(employee => employee._id === id);

    return (
        <div className=" mt-4">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'applyLeave' ? 'text-white bg-secondary active' : 'text-dark'}`}
                        onClick={() => handleTabChange('applyLeave')}
                    >
                        Apply Leave
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'viewCalendar' ? 'text-white bg-secondary active' : 'text-dark'}`}
                        onClick={() => handleTabChange('viewCalendar')}
                    >
                        View Calendar
                    </button>
                </li>
            </ul>

            {activeTab === 'applyLeave' && <ApplyLeaveTab filteredEmployee={filteredEmployee} />}
            {activeTab === 'viewCalendar' && <AttendanceCalendar />}
        </div>
    );
};

const ApplyLeaveTab = ({ filteredEmployee }) => {
    const [leaveType, setLeaveType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reason, setReason] = useState('');
    const [empData, setEmpData] = useState( filteredEmployee );
    const [leaveHistoryData, setLeaveHistoryData] = useState([]);
    const [numberOfDays, setNumberOfDays] = useState(0);    

    const navigate = useNavigate() 
    const {id} = useParams();
    const token = localStorage.getItem('jwt');
    const headers = useMemo(() => {
        return {
          Authorization: `Bearer ${token}`
        };
      }, [token]);

    const fetchLeaveEntitlement = useCallback(() => {
        axiosInstance.get(`/getEmployeeByPostedBy/${id}`, { headers })
            .then(response => {
                // console.log("getEmployeeByPostedBy", response.data)
                setEmpData(response.data.employee);
            })
            .catch(error => console.error('Error fetching leave entitlement:', error));
    }, [headers, id]);

    const fetchLeaveHistory = useCallback(() => {    
        axiosInstance.get(`/getleave/${id}`, { headers })
          .then(response => {
            setLeaveHistoryData(response.data.applications);
          })
          .catch(error => console.error('Error fetching leave history:', error));
      }, [headers, id]);

      useEffect(() => {
        const storedItem = localStorage.getItem("user");
        const token = localStorage.getItem("jwt");
        if (!storedItem && !token) {
          navigate("/signin");
          return
        }
        fetchLeaveEntitlement();
        fetchLeaveHistory();
    }, [fetchLeaveEntitlement, fetchLeaveHistory, navigate]);
    

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!leaveType || !startDate || !endDate || !reason) {
            toast.error('Please fill all required fields.');
            return;
        }
    
        if (['sickLeave', 'casualLeave', 'annualLeave'].includes(leaveType)) {
            if (numberOfDays <= 0 || numberOfDays > getLeaveEntitlement(leaveType)) {
                toast.error('Invalid number of days for the selected leave type.');
                return;
            }
        }

        const newLeaveRecord = {
            empName: empData ? empData.name : '',
            leaveType,
            startDate,
            endDate,
            reason,
            numberOfDays,
            status: 'Pending'
        };

        axiosInstance.post(`/api/update-entitlement-and-create-leave/${id}`, newLeaveRecord, { headers })
            .then(response => {
                // const {  } = response.data;
                fetchLeaveEntitlement();
                fetchLeaveHistory();
                setLeaveType('');
                setStartDate('');
                setEndDate('');
                setReason('');
                setNumberOfDays(0);
                toast.success('Leave application submitted successfully.');
            })
            .catch(error => {
                console.error('Error submitting leave application:', error);
                toast.error('Error submitting leave application.');
            });
    };

    

    const handleClear = () => {
        setLeaveType('');
        setStartDate('');
        setEndDate('');
        setReason('');
        setNumberOfDays(0);
    };

    const revertEntitlementDays = (item) => {
        const start = item.startDate
        const end = item.endDate
        let numberOfLeaves = 0
        if(start && end){
            const startTimestamp = new Date(start).getTime();
            const endTimestamp = new Date(end).getTime();
            const days = Math.round((endTimestamp - startTimestamp) / (1000 * 60 * 60 * 24));
            numberOfLeaves = days + 1;
        } else {
            numberOfLeaves = 0
        }
        const updatedEntitlement = { ...empData };

        switch (item.leaveType) {
            case 'annualLeave':
                updatedEntitlement.annualLeaveEntitlement += numberOfLeaves;
                break;
            case 'sickLeave':
                updatedEntitlement.sickLeaveEntitlement += numberOfLeaves;
                break;
            case 'casualLeave':
                updatedEntitlement.casualLeaveEntitlement += numberOfLeaves;
                break;
            case 'Half Day':
                updatedEntitlement.casualLeaveEntitlement += numberOfLeaves / 2;
                break;
            default:
                break;
        }

        axiosInstance.put(`/updateEntitlement/${id}`, updatedEntitlement, { headers })
            .then(response => {
                toast.success('Leave entitlement updated successfully');
                fetchLeaveHistory();
                fetchLeaveEntitlement();
            })
            .catch(error => {
                console.error('Error updating leave entitlement:', error);
                toast.error('Error updating leave entitlement');
            });

    };

    const handleLeaveTypeChange = (e) => {
        setLeaveType(e.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        if (endDate) {
            calculateNumberOfDays(date, endDate);
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        if (startDate) {
            calculateNumberOfDays(startDate, date);
        }
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
    };

    const calculateNumberOfDays = (start, end) => {
        if (start && end) {
            const startTimestamp = new Date(start).getTime();
            const endTimestamp = new Date(end).getTime();
            const days = Math.round((endTimestamp - startTimestamp) / (1000 * 60 * 60 * 24));
            setNumberOfDays(days + 1);
        } else {
            setNumberOfDays(0);
        }
    };

    const handleDelete = (item) => {
        axiosInstance.delete(`/delete-leave/${item._id}`, { headers })
            .then(response => {
                revertEntitlementDays(item);
                fetchLeaveEntitlement();
                toast.success('Leave request deleted')
                setLeaveHistoryData(prevData => prevData.filter(entry => entry._id !== item._id));
                fetchLeaveHistory();
            })
            .catch(error => {
                console.error('Error deleting leave:', error);
                toast.error('Error deleting leave')
            });
    };

    const getLeaveEntitlement = (type) => {
        switch (type) {
            case 'sickLeave':
                return empData.sickLeaveEntitlement;
            case 'casualLeave':
                return empData.casualLeaveEntitlement;
            case 'annualLeave':
                return empData.annualLeaveEntitlement;
            default:
                return 0;
        }
    };

    const formatLeaveType = (leaveType) => {
        switch (leaveType) {
            case 'sickLeave':
                return 'Sick Leave';
            case 'casualLeave':
                return 'Casual Leave';
            case 'annualLeave':
                return 'Annual Leave';
            default:
                return leaveType;
        }
    };
    const [sortConfig, setSortConfig] = useState({ field: null, direction: 'asc' });

    const handleSort = (field) => {
        let direction = 'asc';
        if (sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ field, direction });

        const sortedData = [...leaveHistoryData].sort((a, b) => {
            if (field === 'applyDate') {
                const dateA = new Date(a.applyDate);
                const dateB = new Date(b.applyDate);
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (field === 'status') {
                return direction === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
            }
            return 0;
        });

        setLeaveHistoryData(sortedData);
    };

    const renderSortArrow = (field) => {
        if (sortConfig.field === field) {
            return sortConfig.direction === 'asc' ? <RiArrowUpLine /> : <RiArrowDownLine />;
        }
        return null;
    };
    
    
    return (
        <div className="mt-3">
            <div className="row">
                <div className="col-lg-12 shadow px-3 py-2">
                    <h4>Leave Balances</h4>
                    <hr />
                    <ul className="d-flex p-0 list-unstyled">
                        {/* <li className='border-start fs-5 border-3 border-dark px-2 me-2' >
                            <span className='text-danger fs-4'>&#8226;</span> Annual Leave: <span className='text-danger'>{empData.annualLeaveEntitlement}</span>
                        </li> */}
                        <li className='border-start fs-5 border-3 border-dark px-2 me-2' >
                            <span className='text-success fs-4'>&#8226;</span> Casual Leave: <span className='text-success'>{empData?.casualLeaveEntitlement}</span>
                        </li>
                        <li className='border-start fs-5 border-3 border-dark border-end px-2 ' >
                            <span className='text-primary fs-4'>&#8226;</span> Sick Leave: <span className='text-primary'>{empData?.sickLeaveEntitlement}</span>
                        </li>
                    </ul>

                </div>
                {/* <div className="col-lg-12"></div> */}
                    <div className="col-lg- shadow mt-md-4 mt-lg-0 p-3">
                        <h3 className='py-2 '>Apply Leave</h3>
                        <hr />
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                                <div className="col-lg-3 d-flex align-items-center">
                                    <label htmlFor="leaveType" className="form-label font-sub-title mb-0">Leave Type</label>
                                </div>
                                <div className="col-lg-7">
                                    <select id="leaveType" className="form-control-announce bg-white" value={leaveType} onChange={handleLeaveTypeChange}>
                                        <option value="">Select</option>
                                        <option value="sickLeave">Sick Leave</option>
                                        <option value="casualLeave">Casual Leave</option>
                                        {/* <option value="annualLeave">Annual Leave</option> */}
                                        <option value="Comp Off">Comp Off</option>
                                        <option value="Paternity Leave">Paternity Leave</option>
                                        <option value="Leave Without Pay">Leave without pay</option>
                                        <option value="Half Day">Half Day</option>
                                        <option value="Short Leave">Short Leave</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-3 d-flex align-items-center">
                                    <label htmlFor="startDate" className="form-label font-sub-title mb-0">Start Date</label>
                                </div>
                                <div className="col-md-4 w-auto">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control-announce w-100 cursor-pointer"
                                        id="startDate"
                                        placeholderText='From'
                                        required
                                    />
                                </div>
                                <div className="col-md-5">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control-announce w-100 cursor-pointer"
                                        id="endDate"
                                        placeholderText='To'
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-lg-3 d-flex align-items-center">
                                    <label className="form-label font-sub-title mb-0">Number of Days Selected</label>
                                </div>
                                <div className="col-lg-7">
                                    <input type="text" className="form-control-announce" value={numberOfDays} readOnly />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-3 d-flex align-items-center">
                                    <label htmlFor="reason" className="form-label font-sub-title mb-0">Reason</label>
                                </div>
                                <div className="col-lg-7">
                                    <textarea id="reason" className="form-control-announce" value={reason} onChange={handleReasonChange}></textarea>
                                </div>
                            </div>
                            <div className="m-3 d-flex justify-content-center">
                                <button type="button" className="btn me-2" onClick={handleClear}>Clear</button>
                                {/* <button type="submit" className="btn btn-button" disabled={!leaveType || !startDate || !endDate || numberOfDays <= 0 || numberOfDays > getLeaveEntitlement(leaveType)}>Submit</button> */}
                                <button 
                                    type="submit" 
                                    className="btn btn-button" 
                                    disabled={
                                        !leaveType || 
                                        !startDate || 
                                        !endDate || 
                                        !numberOfDays ||
                                        (['sickLeave', 'casualLeave', 'annualLeave'].includes(leaveType) && (numberOfDays <= 0 || numberOfDays > getLeaveEntitlement(leaveType)))
                                    }
                                >
                                    Submit
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
            {/* Leave history section */}
            <div className="row mt-4 shadow">
                <h3 className='mb-4 fw-bold'>Leave History</h3>
                <div className="col-lg-12 table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className='cursor-pointer' onClick={() => handleSort('applyDate')}>
                                    Date {renderSortArrow('applyDate')}
                                </th>
                                <th className='text-nowrap'>Reason</th>
                                <th className='text-nowrap'>Leave Type</th>
                                <th className='text-nowrap'>Start Date</th>
                                <th className='text-nowrap'>End Date</th>
                                <th className='text-nowrap cursor-pointer' onClick={() => handleSort('status')}>
                                    Status {renderSortArrow('status')}
                                </th>
                                <th className='text-nowrap'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaveHistoryData.length > 0 ? (
                                leaveHistoryData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{new Date(item.applyDate).toLocaleDateString('en-US')}</td>
                                        <td className="truncate-text">{item.reason}</td>
                                        <td>{formatLeaveType(item.leaveType)}</td>
                                        <td>{new Date(item.startDate).toLocaleDateString('en-US')}</td>
                                        <td>{new Date(item.endDate).toLocaleDateString('en-US')}</td>
                                        <td>{item.status}</td>
                                        <td>
                                            {item.status === 'Pending' && (
                                                <button className="btn btn-danger btn-sm me-1" onClick={() => handleDelete(item)} data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                                                    <RiDeleteBinLine />
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className='text-center p-5' colSpan="7">No leave history available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PlanMyLeave;
