import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../service/axiosInstance';
import { BsArrowLeft } from 'react-icons/bs';
import FileDisplay from './FileDisplay';
import { toast } from 'react-toastify';

const FileUpload = () => {
    const [files, setFiles] = useState({
        offerLetter: null,
        appointmentLetter: null,
        employeeHandbook: null,
        kra: null,
        salarySlips: null,
        agreement: null,
        aadhaar: null,
        panCard: null,
    });
    const navigate = useNavigate();
    const location = useLocation();
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [userData, setUserData] = useState(null);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [filePaths, setFilePaths] = useState({});
    const [FilesAvailable, setFilesAvailable] = useState(false);

    const handleFileChange = (event, fieldName) => {
        const file = event.target.files[0];
        if (file) {
            const allowedExtensions = fieldName === 'aadhaar' || fieldName === 'panCard' ? ["jpg", "jpeg", "png"] : ["pdf", "docx", "doc"];
            const extension = file.name.split('.').pop().toLowerCase();

            if (allowedExtensions.includes(extension)) {
                setFiles(prevFiles => ({
                    ...prevFiles,
                    [fieldName]: file
                }));
            } else {
                const allowedExtensionsMessage = fieldName === 'aadhaar' || fieldName === 'panCard' ? "JPG, JPEG, and PNG" : "PDF, DOCX, and DOC";
                toast.error(`Only ${allowedExtensionsMessage} files are allowed.`);
            }
        }
    };

    const token = localStorage.getItem('jwt');
    const admin = localStorage.getItem('user')
    const userId = JSON.parse(admin)?._id
  
    const headers = useMemo(() => ({
        Authorization: `Bearer ${token}`
    }), [token]);

    useEffect(() => {
        if (token) {
            const { email, name, userName, image, _id } = location.state.employeeData;
            setUserData({ email, name, userName, image, _id });
        } else {
            navigate("/signin");
        }
    }, [location.state.employeeData, navigate, token]);

    useEffect(() => {
        if (
            files.offerLetter ||
            files.appointmentLetter ||
            files.employeeHandbook ||
            files.kra ||
            files.salarySlips ||
            files.agreement ||
            files.aadhaar ||
            files.panCard
        ) {
            setUploadButtonDisabled(false);
        } else {
            setUploadButtonDisabled(true);
        }
    }, [files]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        if (files.offerLetter) formData.append('offerLetter', files.offerLetter);
        if (files.appointmentLetter) formData.append('appointmentLetter', files.appointmentLetter);
        if (files.employeeHandbook) formData.append('employeeHandbook', files.employeeHandbook);
        if (files.kra) formData.append('kra', files.kra);
        if (files.salarySlips) formData.append('salarySlips', files.salarySlips);
        if (files.agreement) formData.append('agreement', files.agreement);
        if (files.aadhaar) formData.append('aadhaar', files.aadhaar);
        if (files.panCard) formData.append('panCard', files.panCard);

        if (filePaths) {
            setIsLoading(true);
            axiosInstance.post(`/upload/${userData._id}/upload`, formData, {
                headers: {
                    ...headers,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    toast.success('Upload successful');
                    setUploadSuccess(true);
                    getUploadData()
                })
                .catch(error => {
                    toast.error('Upload failed');
                    console.error('Error:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            console.log("Update Uploaded files");
        }
    };

    const handleGoBack = () => {
        navigate(`/admin-employees/${userId}`);
    };

    const handleIndividualUpdate = (fieldName) => {
        const formData = new FormData();
        formData.append('file', files[fieldName]);
        formData.append('fieldName', fieldName);
        if (!files[fieldName]) {
            toast.error(`Please select a ${fieldName} file to update.`);
            return;
        }
        setIsLoading(true);
        axiosInstance.put(`/update/${userData._id}/update`, formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
            params: {
                fieldName: fieldName,
            },
        })
            .then(response => {
                toast.success('Upload successful');
                console.log(response.data.message);
                getUploadData()
            })
            .catch(error => {
                toast.error('Upload failed');
                console.error('Error:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getUploadData = () => {
        axiosInstance.get(`/files/${userData._id}`, { headers })
            .then(response => {
                setFiles(response.data);
                setFilePaths(response.data);
                setFilesAvailable(true);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        if (userData) {
            getUploadData();
        }
    }, [uploadSuccess, userData, headers]);

    return (
        <div className="container mt-4">
            <button className="btn btn-danger border text-" onClick={handleGoBack}>
                <BsArrowLeft /> Back
            </button>
            {userData && (
                <>
                    <div className="row border alert mt-2 border-dark">
                        <div className="col-md-4">
                            <div className="position-relative circle">
                                <div className="img-fluid rounded-circle" style={{ backgroundImage: `url(${userData.image})` }}></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h3>User Information</h3>
                                <p>Email: {userData.email}</p>
                                <p>Name: {userData.name}</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <h1 className="mb-4">File Upload</h1>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="border p-4 shadow">
                    <div className="row row-cols-1 row-cols-md-2 g-3">
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-4 d-flex align-items-center">
                                    <label htmlFor="offerLetter" className="form-label">Offer Letter:</label>
                                </div>
                                <div className="col-lg-9">
                                    <div className="input-group">
                                        <input type="file" accept=".pdf,.docx,.doc" className="form-control" name="offerLetter" id="offerLetter" onChange={(e) => handleFileChange(e, 'offerLetter')} />
                                        {FilesAvailable && (
                                            <button type="button" className="btn btn-sm text-white btn-danger" onClick={() => handleIndividualUpdate('offerLetter')}>
                                                <i className="bi bi-cloud-upload"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row">
                                <div className="col-lg-4 d-flex align-items-center">
                                    <label htmlFor="appointmentLetter" className="form-label">Appointment Letter:</label>
                                </div>
                                <div className="col-lg-9">
                                    <div className="input-group">
                                        <input type="file" accept=".pdf,.docx,.doc" className="form-control" name="appointmentLetter" id="appointmentLetter" onChange={(e) => handleFileChange(e, 'appointmentLetter')} />
                                        {FilesAvailable && (
                                            <button type="button" className="btn btn-sm text-white btn-danger" onClick={() => handleIndividualUpdate('appointmentLetter')}>
                                                <i className="bi bi-cloud-upload"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-4 d-flex align-items-center">
                                    <label htmlFor="employeeHandbook" className="form-label">Employee Handbook:</label>
                                </div>
                                <div className="col-lg-9">
                                    <div className="input-group">
                                        <input type="file" accept=".pdf,.docx,.doc" className="form-control" name="employeeHandbook" id="employeeHandbook" onChange={(e) => handleFileChange(e, 'employeeHandbook')} />
                                        {FilesAvailable && (
                                            <button type="button" className="btn btn-sm text-white btn-danger" onClick={() => handleIndividualUpdate('employeeHandbook')}>
                                                <i className="bi bi-cloud-upload"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-4 d-flex align-items-center">
                                    <label htmlFor="kra" className="form-label">KRA:</label>
                                </div>
                                <div className="col-lg-9">
                                    <div className="input-group">
                                        <input type="file" accept=".pdf,.docx,.doc" className="form-control" name="kra" id="kra" onChange={(e) => handleFileChange(e, 'kra')} />
                                        {FilesAvailable && (
                                            <button type="button" className="btn btn-sm text-white btn-danger" onClick={() => handleIndividualUpdate('kra')}>
                                                <i className="bi bi-cloud-upload"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-4 d-flex align-items-center">
                                    <label htmlFor="salarySlips" className="form-label">Salary Slips:</label>
                                </div>
                                <div className="col-lg-9">
                                    <div className="input-group">
                                        <input type="file" accept=".pdf,.docx,.doc" className="form-control" name="salarySlips" id="salarySlips" onChange={(e) => handleFileChange(e, 'salarySlips')} />
                                        {FilesAvailable && (
                                            <button type="button" className="btn btn-sm text-white btn-danger" onClick={() => handleIndividualUpdate('salarySlips')}>
                                                <i className="bi bi-cloud-upload"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-4 d-flex align-items-center">
                                    <label htmlFor="agreement" className="form-label">Agreement:</label>
                                </div>
                                <div className="col-lg-9">
                                    <div className="input-group">
                                        <input type="file" accept=".pdf,.docx,.doc" className="form-control" name="agreement" id="agreement" onChange={(e) => handleFileChange(e, 'agreement')} />
                                        {FilesAvailable && (
                                            <button type="button" className="btn btn-sm text-white btn-danger" onClick={() => handleIndividualUpdate('agreement')}>
                                                <i className="bi bi-cloud-upload"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-4 d-flex align-items-center">
                                    <label htmlFor="aadhaar" className="form-label">Aadhaar:</label>
                                </div>
                                <div className="col-lg-9">
                                    <div className="input-group">
                                        <input type="file" accept=".jpg,.jpeg,.png" className="form-control" name="aadhaar" id="aadhaar" onChange={(e) => handleFileChange(e, 'aadhaar')} />
                                        {FilesAvailable && (
                                            <button type="button" className="btn btn-sm text-white btn-danger" onClick={() => handleIndividualUpdate('aadhaar')}>
                                                <i className="bi bi-cloud-upload"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-4 d-flex align-items-center">
                                    <label htmlFor="panCard" className="form-label">PAN Card:</label>
                                </div>
                                <div className="col-lg-9">
                                    <div className="input-group">
                                        <input type="file" accept=".jpg,.jpeg,.png" className="form-control" name="panCard" id="panCard" onChange={(e) => handleFileChange(e, 'panCard')} />
                                        {FilesAvailable && (
                                            <button type="button" className="btn btn-sm text-white btn-danger" onClick={() => handleIndividualUpdate('panCard')}>
                                                <i className="bi bi-cloud-upload"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!FilesAvailable && (
                        <div className='text-center mt-3'>
                            <button type="submit" className="btn btn-button" disabled={uploadButtonDisabled}>
                                {isLoading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    "Upload"
                                )}
                            </button>
                        </div>
                    )}
                </div>
            </form>

            <div className="mt-5">
                <FileDisplay uploadSuccess={uploadSuccess} formData={files} filePaths={filePaths}/>
            </div>
        </div>
    );
};

export default FileUpload;
