import React, { useState } from "react";
// import { Link } from "react-router-dom";
import ApplicationForm from "./ApplicationForm";

function CarrerAccordion() {
    const [selectedJob, setSelectedJob] = useState(""); 

    const handleJobSelection = (jobTitle) => {
      setSelectedJob(jobTitle); 
    };
    const skills = [
        {
          target:"collapse1",
          title: "ReactJS Developer",
          year:"4-6 Years",
          description:
            "We're seeking a skilled ReactJS Developer to join our frontend development team. If you have a strong proficiency in JavaScript, including DOM manipulation and React.js, along with experience with popular React.js workflows, we'd love to hear from you!",
          requiredSkills: [
            "Strong proficiency in JavaScript",
            "Thorough understanding of React.js and its core principles",
            "Experience with popular React.js workflows (such as Flux or Redux)",
            "Familiarity with RESTful APIs",
          ],
        },
        {
          target:"collapse2",
          title: "Ruby On Rails",
          year:"5-8 Years",
          description:
            "We're seeking a skilled Rails Developer to join our backend development team. If you have a strong proficiency in Ruby on Rails, MySQL, and front-end technologies like HTML, CSS, and JavaScript, we'd love to hear from you!",
          requiredSkills: [
            "Strong proficiency in Ruby on Rails framework",
            "Proficiency in MySQL",
            "Knowledge of HTML, CSS, and JavaScript",
          ],
        },
        {
          target:"collapse3",
          title: "GoLang Developer",
          year:"2-5 Years",
          description:
            "We're seeking a skilled Go Developer to join our backend development team. If you have a strong proficiency in GoLang, knowledge of RESTful APIs, and experience with cloud technologies like AWS or Google Cloud, we'd love to hear from you!",
          requiredSkills: [
            "Proficiency in GoLang",
            "Experience with RESTful APIs",
            "Knowledge of cloud technologies (e.g., AWS, Google Cloud)",
          ],
        },
        {
          target:"collapse4",
          title: "DevOps Engineer",
          year:"3-5 Years",
          description:
            "We're looking for a DevOps Engineer to help streamline our development and deployment processes. If you have experience with CI/CD pipelines, cloud platforms, and containerization technologies, we'd love to hear from you!",
          requiredSkills: [
            "Experience with CI/CD pipelines (e.g., Jenkins, GitLab CI)",
            "Knowledge of cloud platforms (e.g., AWS, Azure)",
            "Understanding of containerization technologies (e.g., Docker, Kubernetes)",
          ],
        },
        {
          target:"collapse5",
          title: "MERN Stack Developer",
          year:"0-2 Years",
          description:
            "We're seeking a skilled MERN Stack Developer to join our team. If you have experience building full-stack web applications using MongoDB, Express.js, React.js, and Node.js, along with knowledge of RESTful APIs and modern JavaScript frameworks, we'd love to hear from you!",
          requiredSkills: [
            "Proficiency in MongoDB, Express.js, React.js, and Node.js",
            "Experience with RESTful APIs and modern JavaScript frameworks",
            "Familiarity with front-end and back-end development concepts",
            "Strong problem-solving and debugging skills"
          ]
        },
        {
          target:"collapse6",
          title: "Senior Node JS Developer",
          year:"2-4 Years",
          description:
            "We're looking for an experienced Senior Node.js Developer to lead our backend development efforts. If you have a deep understanding of Node.js, asynchronous programming, and building scalable and efficient server-side applications, along with experience in architecting and implementing complex systems, we'd love to hear from you!",
          requiredSkills: [
            "Expertise in Node.js and asynchronous programming",
            "Experience with building scalable and efficient server-side applications",
            "Strong knowledge of database technologies (e.g., MongoDB, MySQL)",
            "Ability to architect and implement complex systems"
          ]
        }
      ];

  return (
    <>
    <div className="row">
        <div className="col-lg-7 mt-5">
            <div className="accordion accordion-flush" id="accordionFlushExample">
                {skills.map((skill, index) => {
                    return (
                    <div key={index} className="accordion-item border rounded-2 mb-3" data-aos-once="true" data-aos="fade-up">
                        <div className="accordion-header" style={{lineHeight:"3"}}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-${skill.target}`} aria-expanded="false" aria-controls={`flush-${skill.target}`}>
                            {skill.title}
                        </button>
                        </div>
                        <div id={`flush-${skill.target}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body border">
                                <p style={{fontSize:"17px"}}>{skill.description}</p>
                                <ul className="glow">
                                {skill.requiredSkills.map((requiredSkill, index) => (
                                    <li key={index}>{requiredSkill}</li>
                                ))}
                                </ul>
                                <div className="justify-content-center text-center">
                                <a href="#apply">
                                    <div className="btn-button rounded" onClick={()=>handleJobSelection(skill.title)}>Apply</div>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
        </div>
        <div className="col-lg-5" id="apply">
            <ApplicationForm datas={skills} job={selectedJob}/>
        </div>
    </div>
    </>
  );
}

export default CarrerAccordion;
