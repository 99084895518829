import React from "react";
import teraImg from '../../assets/img/case-study/teraExchange.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const TeraExchange = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
  return (
    <div className="mt-5 m-3">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="my-5">
            <div className="card-body text-center">
              <h1 className="mt-5 text-danger heading-size">TeraExchange Leverages EP3 Platform to Power Swap Execution Facility</h1>
              <h4 className="card-text text-center">Swap Execution Facility – Client Success Case Study</h4>
            </div>
          </div>

          <img src={teraImg} className="object-fit-fill img-fluid rounded-5" alt="" />

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Client</h1>
              <p className="card-text fs-5">TeraExchange is a global pioneer in the regulated derivatives marketplace, providing a multi-asset class platform for trading an array of financial instruments including contracts on interest rates, FX, and digital instruments.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Challenge</h1>
              <p className="card-text fs-5">Having experienced rapid growth, TeraExchange required a modern exchange platform to replace their existing provider’s limited solution, which was stifling their ability to scale and innovate.</p>
              <p className="card-text fs-5">EP3™ exceeded their requirements for performance, reliability, low latency, and availability of modern APIs, but did not have integrations to third parties that were required to move forward with the project — specifically, support for their central counterparty clearing partners, CME and London Clearing House (LCH), and their vendor for trade practice reporting and surveillance, Eventus Systems, which required EP3™ to work with the industry-leading Validus platform.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Solution</h1>
              <p className="card-text fs-5">Connamara Professional Services scoped the requirements for the integrations and delivered a detailed timeline with precise cost estimates for extending EP3™ to support their needs. Once TeraExchange signed off on the plan, the project was launched in earnest to meet the aggressive timelines.</p>
              <p className="card-text fs-5">Adhering to the established Connamara agile methodology, the Connamara Professional Services team delivered on time and on budget and the client progressed towards launch.</p>
            </div>
          </div>

          <div className="my-4">
            <div className="card-body border-top">
              <h1 className="mt-5">The Results</h1>
              <p className="card-text fs-5">EP3’s cutting-edge technology provided TeraExchange with industry-leading speed, state-of-the-art self-healing capabilities, and customized, flexible integrations. The seamless infrastructure assures future-proof capabilities designed specifically to meet the unique needs of TeraExchange and its users.</p>
              <p className="card-text fs-5">With these customized integrations, the TeraExchange team launched the SEF with confidence, secure in the knowledge that they not only had a solution for their current needs, but also a robust foundation for future scalability and innovation, courtesy of EP3’s cutting-edge capabilities.</p>
            </div>
          </div>
          <div className="prev-next my-5 d-flex justify-content-between px-3 align-items-center">
            <Link to="/case-study/AFX" className="prev-icon d-flex align-items-center" onClick={scrollToTop}>
                <FontAwesomeIcon icon={faChevronLeft} className="border rounded-5 fs-3 p-2" />
                <span className="ms-2">Previous</span>
            </Link>
            <Link to="/case-study/APACBourse" className="next-icon d-flex align-items-center" onClick={scrollToTop}>
                <span className="me-2">Next</span>
                <FontAwesomeIcon icon={faChevronRight} className="border rounded-5 fs-3 p-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeraExchange;
