import React from 'react';

const formatAadhaarNumber = (value) => {
  
  const numericValue = value.replace(/\D/g, '');
  
  
  const formattedValue = numericValue.replace(/(\d{4})(?=\d)/g, '$1-');

  return formattedValue;
};

const AadhaarInput = ({ value, onChange, mandatory, required, error }) => {
  const handleChange = (e) => {
    const formattedValue = formatAadhaarNumber(e.target.value);
    onChange(formattedValue);
  };

  return (
    <div className="row mb-3">
    <div className="col-lg-3 d-flex align-items-center">
      <label className="form-label font-sub-title mb-0">
        AadharNumber{mandatory && <span className="text-danger">*</span>}
      </label>
    </div>
    <div className="col-lg-7">
      <input
        className='form-control-announce'
        type="text"
        value={value}
        onChange={handleChange}
        maxLength={14} 
        placeholder="Enter Aadhaar Number"
        required={required}
      />
    </div>
    {error && <div className="text-danger">{error}</div>}
  </div>

  );
};

export default AadhaarInput;
