import React, { useState } from 'react';
import { FaThList, FaTh } from 'react-icons/fa';
import uploadimages from '../../assets/img/user.png'

const Employees = ({employeesData}) => {
    // const uploadimages =
    //     "https://images.unsplash.com/photo-1593085512500-5d55148d6f0d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MnwtTWZ4QU9PM2tqOHx8ZW58MHx8fHx8";

    const [isListView, setIsListView] = useState(true); 

    const toggleView = () => {
        setIsListView(!isListView);
    };

    return (
        <div className="container my-5">
            <div className="d-flex justify-content-end mb-3">
                <button className="btn btn-outline-primary me-3" onClick={toggleView}>
                    {isListView ? <FaTh size={20} /> : <FaThList size={20} />}
                </button>
            </div>
            {isListView ? (
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Department</th>
                                <th>Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeesData.map((item, index) => (
                                <tr key={index}>
                                    <td className='text-nowrap align-content-center'>
                                        <img
                                            src={item.image ? item.image : uploadimages}
                                            alt={item.name}
                                            className="img-fluid rounded-circle"
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    </td>
                                    <td className='text-nowrap align-content-center'>{item.name}</td>
                                    <td className='text-nowrap align-content-center'>{item.email}</td>
                                    <td className='text-nowrap align-content-center'>{item.currentSalary}</td>
                                    <td className='text-nowrap align-content-center'>{item.department}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ):(
            <div className='row g-4'>
                {employeesData.map((item, index)=>{
                    return(
                        <div key={index} className="col-lg-3 col-lg-4">
                            <div className="card no-hover-card text-center pt-4" >
                                <div className="position-relative circle">
                                    <div
                                    className="img-fluid rounded-circle"
                                    style={{ backgroundImage: `url(${item.image ? item.image : uploadimages})` }}
                                    />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">{item.name}</h5>
                                    {/* <p className="card-text m-0 p-0">{item.department}</p> */}
                                    <p className="m-0 p-0">{item.currentSalary}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            )}
        </div>
    );
}

export default Employees;
