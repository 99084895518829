import React, { useEffect, useMemo, useState } from 'react';
import axiosInstance from '../../service/axiosInstance';
import { toast } from 'react-toastify';
import { FaMinus } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import InputField from '../../component/InpuField';
import AadhaarInput from '../../component/AadharInput';
import DatePicker from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import uploadimages from '../../../assets/img/user.png'
import FileValidate from '../FileValidate';

function ViewEditCandidate({ candidateDetails, viewCandidateData }) {
  const [candidateData, setCandidateData] = useState(candidateDetails);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [workExperience, setWorkExperience] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState(false);
  const token = localStorage.getItem('jwt');
  const headers = useMemo(()=>({
    Authorization: `Bearer ${token}`
  }),[token]);
  
  useEffect(() => {
    
    setSelected(viewCandidateData)
    if (candidateDetails) {
      axiosInstance.get(`/api/candidate-details-admin/${candidateDetails._id}`, {headers})
        .then((response) => 
          {
            setCandidateData(response.data)
            setWorkExperience(response.data.workExperiences)
          }
        )
        .catch((error) => {})
    }
  }, [candidateDetails, viewCandidateData, headers]);
  
  const handleInputChange = (e) => {
    const { name, type } = e.target;
    let inputValue;
    
    if (type === 'file') {
      inputValue = e.target.files[0];
    } else {
      inputValue = e.target.value;
    }
    
    setCandidateData({ ...candidateData, [name]: inputValue });
  };

  const handleCheckChange = () => {
    setChecked(!checked);
    if (!checked) {
      setCandidateData(prevDetails => ({
        ...prevDetails,
        permanentAddress: candidateData?.presentAddress,
        permanentState: candidateData?.presentState,
        permanentPostalCode: candidateData?.presentPostalCode
      }));
    }
  };

  const handleWorkChange = (e, index) => {
    const { name, value } = e.target;
    setWorkExperience(prevWorkExperience => {
      const updatedExperience = [...prevWorkExperience];
      updatedExperience[index] = {
        ...updatedExperience[index],
        [name]: value
      };
      return updatedExperience;
    });
  };

  const handleDateChange = (date) => {
    date.setHours(0, 0, 0, 0);
    
    setCandidateData(prevCandidateData => ({
      ...prevCandidateData,
      tentativeJoiningDate: date
    }));
  };

  const addRow = () => {
    setWorkExperience([...workExperience, {
      companyName: '',
      jobTitle: '',
      fromDate: '',
      toDate: '',
      jobDescription: '',
      relevant: true 
    }]);
  };

  const removeRow = (indexToRemove) => {
    setWorkExperience(prevWorkExperience => {
      const updatedExperience = prevWorkExperience.filter((experience, index) => index !== indexToRemove);
      return updatedExperience;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, panNumber, aadhaarNumber, email, phone } = candidateData;
    let errors = {};
    
    if (!email || !email.includes('@')) {
      errors.email = 'Invalid email format';
    }
    
    if (!firstName || !/^[a-zA-Z]+$/.test(firstName)) {
      errors.firstName = 'First name should contain only letters';
    }
    
    if (!lastName || !/^[a-zA-Z]+$/.test(lastName)) {
      errors.lastName = 'Last name should contain only letters';
    }      
    
    if (!panNumber || panNumber.length !== 10) {
      errors.panNumber = 'PAN number should be 10 characters long';
    }
    
    if (!aadhaarNumber && !/^\d{16}$/.test(aadhaarNumber)) {
      errors.aadhaarNumber = 'Aadhaar number should be 12 numeric characters long';
    }
    
    if (!phone || !/^\d{10}$/.test(phone)) {
      errors.phone = 'Phone number should be 10 numeric characters long';
    }

    setErrors(errors);
    
    setLoading(true);
    if (Object.keys(errors).length === 0) {
      const name = firstName + ' ' + lastName;
      const formData = {
        ...candidateData,
        workExperiences: workExperience,
        name: name
      };

      axiosInstance
        .put(`/api/candidate-details-admin/${candidateDetails._id}`, formData, {headers})
        .then((response) => {
          toast.success('Updated successful');
        })
        .catch((error) => {
          console.error("Updated failed:", error);
          if (error.response && error.response.data && error.response.data.error) {
            toast.error('Updated failed: ' + error.response.data.error);
          } else {
            toast.error('Updated failed: ' + error.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  // Edit image of candidate profile
  const [secondImage, setSecondImage] = useState(candidateDetails.image)
  const [show, setShow] = useState(false);
  const [firstImage, setFirstImage] = useState({
    file: "",
  });
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false)
    setFirstImage({...firstImage, file: ""})
  };
  const convertToBase64 = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > 50 * 1024) {
      toast.error("Image size should be below 30kb.");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFirstImage({ file: reader.result });
    };
  };
  const handleImageChange = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', firstImage.file);
    
    const userId = candidateDetails._id

    axiosInstance.put(`/users-admin/${userId}/image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ image: firstImage.file }),
    })
    .then(response => {
      if (response.status === 200) {
        toast.success(`Image uploaded successfully`);
        setSecondImage(firstImage.file);
        handleClose()
      }
    })
    .catch(error => {
        console.error(error);
        toast.error("Error uploading image")
    });
};

  return (
    <>

      {!selected && 
        <div className="d-flex">
          <div className="position-relative circle">
            <img
              className="img-fluid rounded-circle border"
              src={secondImage ? secondImage : uploadimages}
              alt="..."
            />
            <i className="bi bi-pencil edit-icon" onClick={handleShow}></i>
            {show && (
              <>
                <div className="modal-backdrop fade show"></div>
                <div className="modal d-block" tabIndex="-1" style={{ display: "block" }}>
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Upload Employee's photo</h5>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                      </div>
                      <div className="modal-body">
                        <div className="position-relative circle">
                          <img
                            className="img-fluid rounded-circle border"
                            src={firstImage.file ? firstImage.file : secondImage ? secondImage : uploadimages}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="modal-footer d-flex justify-content-between">
                        <label htmlFor="fileInput" className="cursor-pointer btn btn-dark">
                          <input
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            accept=".jpeg, .png, .jpg"
                            onChange={(e) => convertToBase64(e)}
                          />
                          Upload Photo
                        </label>
                        {firstImage.file &&
                          <div type="button" className="btn btn-danger" onClick={handleImageChange}>
                            Save
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            {!selected && 
              <button type="submit" className='btn btn-button' disabled={loading}>
                {loading ? (
                    <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="">Sending...</span>
                    </>
                ) : (
                    <span>Submit</span>
                )}
              </button>
            }

          </div>
        </div>
      }
      <form onSubmit={handleSubmit}>
        <div className="mt-4 ms-3 fw-bold fs-4">Candidate Details</div>
        <div className="p-4 row row-cols-lg-2 row-cols-md-2">
          <InputField
            label="First Name"
            name="firstName"
            placeholder="First Name"
            value={candidateData?.firstName}
            onChange={handleInputChange}
            mandatory={true}
            error={errors.firstName} 
            required
            disabled={selected}
          />

          <InputField
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            value={candidateData?.lastName}
            onChange={handleInputChange}
            mandatory={true}
            error={errors.lastName} 
            required
            disabled={selected}
          />

          <InputField
            label="Email"
            name="email"
            placeholder="Email"
            value={candidateData?.email}
            onChange={handleInputChange}
            mandatory={true}
            error={errors.email} 
            required
            disabled={selected}
          />

          <InputField
            label="Phone"
            name="phone"
            placeholder="Phone"
            value={candidateData?.phone}
            onChange={handleInputChange}
            mandatory={true}
            error={errors.phone} 
            disabled={selected}
          />

          <div>
            <AadhaarInput
              value={candidateData?.aadhaarNumber}
              onChange={(value) => handleInputChange({ target: { name: 'aadhaarNumber', value } })}
              mandatory={true}
              error={errors.aadhaarNumber} 
              required
              disabled={selected}
            />

            <InputField
              label="PAN Number"
              name="panNumber"
              placeholder="PAN Number"
              value={candidateData?.panNumber}
              onChange={handleInputChange}
              error={errors.panNumber} 
              mandatory={true}
              disabled={selected}
            />
            <div className="row mt-3">
              <div className="col-lg-3 d-flex align-items-center">
                <label htmlFor="dateOfBirth">Date Of Birth</label>
              </div>
              <div className="col-lg-3">
                <DatePicker
                  selected={candidateData?.dateOfBirth}
                  onChange={(dateOfBirth) => handleInputChange({ target: { name: 'dateOfBirth', value: dateOfBirth } })}
                  className="form-control-announce w-auto cursor-pointer"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  placeholderText='Date Of Birth'
                  required
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  disabled={selected}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ms-3 fw-bold fs-4">Address Details</div>
        <div className="p-4">
          <InputField
            label="Present Address"
            name="presentAddress"
            placeholder="Present Address"
            value={candidateData?.presentAddress}
            onChange={handleInputChange}
            disabled={selected}
          />

          <InputField
            name="presentState"
            placeholder="Present State"
            value={candidateData?.presentState}
            onChange={handleInputChange}
            disabled={selected}
          />

          <InputField
            name="presentPostalCode"
            placeholder="Present Postal Code"
            value={candidateData?.presentPostalCode}
            onChange={handleInputChange}
            disabled={selected}
          />

        </div>
        <div className="p-4">
          <div className="row mb-">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="permanentAddress" className="form-label font-sub-title mb-0">Permanent Address</label>
            </div>
            <div className="col-lg-6">
              <div className="d-flex">
                <input className='' type="checkbox" name="permanentAddress" placeholder='Permanent Address' checked={checked} onChange={handleCheckChange} disabled={selected}/>
                <p className='mb-0 ps-2'>Same as present Address</p>
              </div>
            </div>
          </div>
            <InputField
              name="permanentAddress"
              placeholder="Permanent Address"
              value={checked ? candidateData?.presentAddress : candidateData?.permanentAddress} 
              onChange={handleInputChange}
              disabled={selected}
            />

            <InputField
              name="permanentState"
              placeholder="Permanent State"
              value={checked ? candidateData?.presentState : candidateData?.permanentState} 
              onChange={handleInputChange}
              disabled={selected}
            />

            <InputField
              name="permanentPostalCode"
              placeholder="Permanent Postal Code"
              value={checked ? candidateData?.presentPostalCode : candidateData?.permanentPostalCode} 
              onChange={handleInputChange}
              disabled={selected}
            />

        </div>

        <div className="ms-3 fw-bold fs-4">Professional Details</div>
        <div className="p-4 row row-cols-lg-2 row-cols-md-2">

          <InputField
            label="Experience"
            name="experience"
            placeholder="Experience"
            value={candidateData?.experience}
            onChange={handleInputChange}
            disabled={selected}
          />

          {/* <InputField
            label="Source Of Hire"
            name="sourceOfHire"
            placeholder="Source Of Hire"
            value={candidateData?.sourceOfHire}
            onChange={handleInputChange}
          /> */}
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="sourceOfHire" className="form-label font-sub-title mb-0">Source Of Hire</label>
            </div>
            <div className="col-lg-8">
              <select className='form-control-announce bg-white' name="sourceOfHire" id="sourceOfHire" value={candidateData?.sourceOfHire} onChange={handleInputChange} disabled={selected}>
                <option value="">Please select</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Naukri">Naukri</option>
                <option value="Reference">Reference</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <InputField
            label="Highest Qualification"
            name="highestQualification"
            placeholder="Highest Qualification"
            value={candidateData?.highestQualification}
            onChange={handleInputChange}
            disabled={selected}
          />

          <InputField
            label="Location"
            name="location"
            placeholder="Location"
            value={candidateData?.location}
            onChange={handleInputChange}
            disabled={selected}
          />

          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="department" className="form-label font-sub-title mb-0">Department</label>
            </div>
            <div className="col-lg-7">
              <select className='form-control-announce bg-white' value={candidateData.department} name="department" id="department" onChange={handleInputChange} disabled={selected} required>
                <option value="">Please select</option> 
                <option value="HR Management">HR Management</option>
                <option value="Top Level Management">Top Level Management</option>
                <option value="Quality">Quality</option>
                <option value="Project Management">Project Management</option>
                <option value="Full Stack Developer">Full Stack Developer</option>
                <option value="Frontend Developer">Frontend Developer</option>
                <option value="Backend Developer">Backend Developer</option>
                <option value="DevOps">DevOps</option>
                <option value="Admin">Admin</option>
                <option value="Developer">Developer</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="currentSalary" className="form-label font-sub-title mb-0">Designation</label>
            </div>

            <div className="col-lg-8">
              <select className='form-control-announce bg-white' value={candidateData.currentSalary} name="currentSalary" id="currentSalary" onChange={handleInputChange} disabled={selected} required>
                <option value="">Please select</option> 
                <option value="HR Manager">HR Manager</option>
                <option value="Director">Director</option>
                <option value="Quality/Tester">Quality/Tester</option>
                <option value="Project Manager">Project Manager</option>
                <option value="Junior DevOps">Junior DevOps</option>
                <option value="Senior Software Engineer">Senior Software Engineer</option>
                <option value="Serior DevOps">Serior DevOps</option>
                <option value="Senior Solution Architect">Senior Solution Architect</option>
                <option value="Solution Architect">Solution Architect</option>
                <option value="Software Engineer">Software Engineer</option>
              </select>
            </div>
          </div>

          <div className="row row-cols-1 mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="skillSet" className="form-label font-sub-title mb-0">Skill Set</label>
            </div>
            <div className="col-lg-8">
              <textarea className='form-control-announce' type="text" name="skillSet" placeholder='Skill Set' value={candidateData?.skillSet} onChange={handleInputChange} disabled={selected}/>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-3 mt-1 d-flex align-items-start">
              <label htmlFor="employeeType" className="form-label font-sub-title mb-0">Employee Type</label>
            </div>
            <div className="col-lg-8 d-flex align-items-start">
              <select className='form-control-announce bg-white' value={candidateData.employeeType} name="employeeType" id="employeeType" onChange={handleInputChange} disabled={selected} required>
                <option value="">Please select</option> 
                <option value="Permanent Employee">Permanent Employee</option>
                <option value="Contractual Employee">Contractual Employee</option>
              </select>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="ms-3 fw-bold fs-4">Work Experience</div>
          { !selected && 
            <div className='btn border' onClick={addRow}><IoAdd /> Add Row</div>
          }
        </div>
        <div className='table-responsive border border-dark'>
          <table className='border-0'>
            <thead>
              <tr>
                <td className='text-nowrap'>Company Name</td>
                <td className='text-nowrap'>Job Title</td>
                <td className='text-nowrap'>From Date</td>
                <td className='text-nowrap'>To Date</td>
                <td className='text-nowrap'>Job Description</td>
                <td className='text-nowrap'>Relevant</td>
              </tr>
            </thead>
            <tbody>
            { workExperience.length === 0 ? (
              <>
                <div className="alert alert-light">
                  <div className="">
                    Click on add button to add work experiences
                  </div>
                </div>
              </>
            ) : (
              workExperience.map((experience, index) => (
                <tr key={index}>
                  <td className='text-nowrap'><input type="text" name="companyName" className='form-control-announce' value={experience.companyName} onChange={(e) => handleWorkChange(e, index)}  disabled={false} /></td>
                  <td className='text-nowrap'><input type="text" name="jobTitle" className='form-control-announce' value={experience.jobTitle} onChange={(e) => handleWorkChange(e, index)}  disabled={selected}/></td>
                  <td className='text-nowrap'><input type="date" name="fromDate" className='form-control-announce' value={experience.fromDate && experience.fromDate.split('T')[0] } onChange={(e) => handleWorkChange(e, index)}  disabled={selected}/></td>
                  <td className='text-nowrap'><input type="date" name="toDate" className='form-control-announce' value={experience.toDate && experience.toDate.split('T')[0] } onChange={(e) => handleWorkChange(e, index)}  disabled={selected}/></td>
                  {/* <td className='text-nowrap'>
                    <DatePicker
                      selected={experience.fromDate ? new Date(experience.fromDate) : new Date()}
                      onChange={(fromDate) => handleInputChange({ target: { name: 'fromDate', value: fromDate } })}
                      className="form-control-announce w-auto cursor-pointer"
                      id="fromDate"
                      name="fromDate"
                      placeholderText='fromDate'
                      dateFormat="yyyy-MM-dd"
                      required
                    />
                  </td> */}
                  {/* <td className='text-nowrap'>
                    <DatePicker
                      selected={experience.toDate ? new Date(experience.toDate) : new Date()}
                      onChange={(toDate) => handleInputChange({ target: { name: 'toDate', value: toDate } })}
                      className="form-control-announce w-auto cursor-pointer"
                      id="toDate"
                      name="toDate"
                      placeholderText='toDate'
                      dateFormat="yyyy-MM-dd"
                      required
                    />
                  </td> */}
                  <td className='text-nowrap'><textarea name="jobDescription" className='form-control-announce' value={experience.jobDescription} onChange={(e) => handleWorkChange(e, index)} cols="30" rows="1"  disabled={selected}></textarea></td>
                  <td className='text-nowrap'>
                    <select name="relevant" className='form-control-announce' value={experience.relevant} onChange={(e) => handleWorkChange(e, index)}  disabled={selected}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </td>
                  {!selected &&
                    <td className='text-nowrap'><div className='text-danger cursor-pointer' onClick={() => removeRow(index) }><FaMinus /></div></td>
                  }
                </tr>
              ))
            )}
            </tbody>
          </table>
        </div>
        <div className="ms-3 mt-4 fw-bold fs-4">Onboard Employee</div>
        <div className="p-4">
          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="tentativeJoiningDate" className="form-label font-sub-title mb-0">Tentative Joining Date</label>
            </div>
            <div className="col-lg-6">
              <DatePicker
                selected={candidateData?.tentativeJoiningDate}
                onChange={date => handleDateChange(date)}
                className="form-control-announce cursor-pointer"
                name="tentativeJoiningDate"
                placeholderText="Tentative Joining Date"
                dateFormat="yyyy-MM-dd"
                required
                disabled={selected}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
        </div>

        {!selected && 
          <button type="submit" className='btn btn-button' disabled={loading}>
            {loading ? (
                <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="">Sending...</span>
                </>
            ) : (
                <span>Submit</span>
            )}
          </button>
        }
      </form>
      <FileValidate empId={candidateDetails?._id}/>
    </>
  );
}

export default ViewEditCandidate;
