import React, { useState } from 'react';
import { Nav, Offcanvas, Button } from 'react-bootstrap';
import { FaHome, FaFileAlt, FaCoffee, FaUsers, FaCalendar, FaUserPlus, FaBell, FaBriefcaseMedical, FaBars, FaListAlt, FaUserCheck, FaFileInvoiceDollar } from 'react-icons/fa';

const SideNavbar = ({ activeTab, handleTabChange, notificationCount, pendingRequestsCount, pendingLeavesCount }) => {
  const [isSeparationOpen, setIsSeparationOpen] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleSeparation = () => {
    setIsSeparationOpen(!isSeparationOpen);
    handleTabChange("resignation");
  };

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  return (
    <>
      <Button className="d-lg-none bg-danger border" onClick={handleShowOffcanvas}>
        <FaBars /> Menu
      </Button>

      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} className="sidebar-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "dashboard" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => { handleTabChange("dashboard"); handleCloseOffcanvas(); }}
              >
                <FaHome className="me-1 icon-size" /> <span className=''>My Dashboard</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "employees" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => { handleTabChange("employees"); handleCloseOffcanvas(); }}
              >
                <FaUsers className="me-1 icon-size" /> <span className=''>Employees</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "time-off" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => { handleTabChange("time-off"); handleCloseOffcanvas(); }}
              >
                <FaBriefcaseMedical className="me-1 icon-size" />
                <span>
                  <span className=''>Time-off</span>
                  <span className='badge bg-danger rounded-circle ms-sm-1'>{pendingLeavesCount}</span>
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "attendance" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => { handleTabChange("attendance"); handleCloseOffcanvas(); }}
              >
                <FaCalendar className="me-1 icon-size" /> <span className=''>Attendance</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "onboard" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => { handleTabChange("onboard"); handleCloseOffcanvas(); }}
              >
                <FaUserPlus className="me-1 icon-size" /> <span className=''>Onboard Employee</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "resignation" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={toggleSeparation}
              >
                <FaListAlt className="me-1 icon-size" /> <span className=''>Separation</span>
              </Nav.Link>
              <div className={`submenu bg- ${isSeparationOpen ? 'open' : ''}`}>
                <Nav.Link
                  href="#"
                  className={`d-flex align-items-center ms-4 mb-0 ${activeTab === "exit-interview" ? 'text-danger fw-bold alert alert-danger' : 'text-dark'}`}
                  onClick={() => { handleTabChange("exit-interview"); handleCloseOffcanvas(); }}
                >
                  <FaUserCheck className="me-1 icon-size" /> <span className=''>Exit Interview</span>
                </Nav.Link>
              </div>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "notification" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => { handleTabChange("notification"); handleCloseOffcanvas(); }}
              >
                <FaBell className="me-1 icon-size" />
                <span>
                  <span className=''>Notification</span>
                  <span className='badge bg-danger rounded-circle ms-sm-1'>{notificationCount + pendingRequestsCount}</span>
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "files" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => handleTabChange("files")}
              >
                <FaFileAlt className="me-1 icon-size" />
                <span>
                  <span className=''>Files</span>
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "payroll" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => handleTabChange("payroll")}
              >
                  <FaFileInvoiceDollar className="me-1 icon-size" />
                <span>
                  <span>Payroll</span>
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center mt-2 ${activeTab === "Coffee&Knowledge" ? 'text-danger fw-bold' : 'text-dark'}`}
                onClick={() => handleTabChange("Coffee&Knowledge")}
              >
                  <FaCoffee className="me-1 icon-size" />
                <span>
                  <span>Coffee & Knowledge</span>
                </span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      <Nav className="shadow vh-100 sidebar h-100 center-icon d-none d-lg-block">
        <div className="position-sticky mt-4 showrow">
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "dashboard" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("dashboard")}
            >
              <FaHome className="me-1 icon-size" /> <span className=''>My Dashboard</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "employees" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("employees")}
            >
              <FaUsers className="me-1 icon-size" /> <span className=''>Employees</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "time-off" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("time-off")}
            >
              <FaBriefcaseMedical className="me-1 icon-size" />
              <span>
                <span className=''>Time-off</span>
                <span className='badge bg-danger rounded-circle ms-sm-1'>{pendingLeavesCount}</span>
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "attendance" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("attendance")}
            >
              <FaCalendar className="me-1 icon-size" /> <span className=''>Attendance</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "onboard" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("onboard")}
            >
              <FaUserPlus className="me-1 icon-size" /> <span className=''>Onboard Employee</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "resignation" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={toggleSeparation}
            >
              <FaListAlt className="me-1 icon-size" /> <span className=''>Separation</span>
            </Nav.Link>
            <div className={`submenu bg- ${isSeparationOpen ? 'open' : ''}`}>
              <Nav.Link
                href="#"
                className={`d-flex align-items-center ms-4 mb-0 ${activeTab === "exit-interview" ? 'text-danger fw-bold alert alert-danger' : 'text-dark'}`}
                onClick={() => handleTabChange("exit-interview")}
              >
                <FaUserCheck className="me-1 icon-size" /> <span className=''>Exit Interview</span>
              </Nav.Link>
            </div>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "notification" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("notification")}
            >
              <FaBell className="me-1 icon-size" />
              <span>
                <span className=''>Notification</span>
                <span className='badge bg-danger rounded-circle ms-sm-1'>{notificationCount + pendingRequestsCount}</span>
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "files" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("files")}
            >
              <FaFileAlt className="me-1 icon-size" />
              <span>
                <span className=''>Files</span>
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "payroll" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("payroll")}
            >
                <FaFileInvoiceDollar className="me-1 icon-size" />
              <span>
                <span>Payroll</span>
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              className={`d-flex align-items-center mt-2 ${activeTab === "Coffee&Knowledge" ? 'text-danger fw-bold' : 'text-dark'}`}
              onClick={() => handleTabChange("Coffee&Knowledge")}
            >
                <FaCoffee className="me-1 icon-size" />
              <span>
                <span>Coffee & Knowledge</span>
              </span>
            </Nav.Link>
          </Nav.Item>
        </div>
      </Nav>
    </>
  );
}

export default SideNavbar;
