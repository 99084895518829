import React, { useState } from 'react';
import axiosInstance from '../../service/axiosInstance';
import { toast } from 'react-toastify';
import { FaMinus } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import InputField from '../../component/InpuField';
import AadhaarInput from '../../component/AadharInput';
import DatePicker from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';

function CandidateForm({ candidateDetails, setCandidateDetails, setclickedBtn, fetchEmployeesData }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [workExperience, setWorkExperience] = useState([]);
  const [checked, setChecked] = useState(false);
  const token = localStorage.getItem('jwt');
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const handleInputChange = (e) => {
    const { name, type } = e.target;
    let inputValue;
    
    if (type === 'file') {
      inputValue = e.target.files[0];
    } else {
      inputValue = e.target.value;
    }
    
    setCandidateDetails({ ...candidateDetails, [name]: inputValue });
  };

  const handleCheckChange = () => {
    setChecked(!checked);
    if (!checked) {
      setCandidateDetails(prevDetails => ({
        ...prevDetails,
        permanentAddress: candidateDetails.presentAddress,
        permanentState: candidateDetails.presentState,
        permanentPostalCode: candidateDetails.presentPostalCode
      }));
    }
  };

  const handleWorkChange = (e, index) => {
    const { name, value } = e.target;
    setWorkExperience(prevWorkExperience => {
      const updatedExperience = [...prevWorkExperience];
      updatedExperience[index] = {
        ...updatedExperience[index],
        [name]: value
      };
      return updatedExperience;
    });
  };

  const handleDateChange = (date) => {
    date.setHours(0, 0, 0, 0);
    
    setCandidateDetails(prevCandidateDetails => ({
      ...prevCandidateDetails,
      tentativeJoiningDate: date
    }));
  };

  const addRow = () => {
    setWorkExperience([...workExperience, {
      companyName: '',
      jobTitle: '',
      fromDate: '',
      toDate: '',
      jobDescription: '',
      relevant: true 
    }]);
  };

  const removeRow = (indexToRemove) => {
    setWorkExperience(prevWorkExperience => {
      const updatedExperience = prevWorkExperience.filter((experience, index) => index !== indexToRemove);
      return updatedExperience;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const { firstName, lastName, panNumber, aadhaarNumber, email, phone } = candidateDetails;
    let errors = {};
    
    if (!email || !email.includes('@')) {
      errors.email = 'Invalid email format';
    }
    
    if (!firstName || !/^[a-zA-Z]+$/.test(firstName)) {
      errors.firstName = 'First name should contain only letters';
    }
    
    if (!lastName || !/^[a-zA-Z]+$/.test(lastName)) {
      errors.lastName = 'Last name should contain only letters';
    }      
    
    if (!panNumber || panNumber.length !== 10) {
      errors.panNumber = 'PAN number should be 10 characters long';
    }
    
    if (!aadhaarNumber && !/^\d{16}$/.test(aadhaarNumber)) {
      errors.aadhaarNumber = 'Aadhaar number should be 12 numeric characters long';
    }
    
    if (!phone || !/^\d{10}$/.test(phone)) {
      errors.phone = 'Phone number should be 10 numeric characters long';
    }

    setErrors(errors);
    
    if (Object.keys(errors).length === 0) {
      const name = firstName + ' ' + lastName;
      const formData = {
        ...candidateDetails,
        workExperiences: workExperience,
        name: name
      };
      
      axiosInstance
        .post("/api/admin/register", formData, {headers})
        .then((response) => {
          toast.success('Registration successful');
          fetchEmployeesData();
          setCandidateDetails({
            firstName: '',
            lastName: '',
            name: '',
            email: '',
            phone: '',
            aadhaarNumber: '',
            panNumber: '',
            presentAddress: '',
            presentState: '',
            presentPostalCode: '',
            experience: '',
            sourceOfHire: '',
            skillSet: '',
            highestQualification: '',
            location: '',
            title: '',
            currentSalary: '',
            department: '',
            tentativeJoiningDate: '',
            password: ''
          });
          setWorkExperience([]);
          setTimeout(() => {
            setclickedBtn(false)
          }, 100);
        })
        .catch((error) => {
          console.error("Registration failed:", error);
          if (error.response && error.response.data && error.response.data.error) {
            toast.error('Registration failed: ' + error.response.data.error);
          } else {
            toast.error('Registration failed: ' + error.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mt-4 ms-3 fw-bold fs-4">Candidate Details</div>
        <div className="p-4 row row-cols-lg-2 row-cols-md-2">
          <InputField
            label="First Name"
            name="firstName"
            placeholder="First Name"
            value={candidateDetails.firstName}
            onChange={handleInputChange}
            mandatory={true}
            error={errors.firstName} 
            required
          />

          <InputField
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            value={candidateDetails.lastName}
            onChange={handleInputChange}
            mandatory={true}
            error={errors.lastName} 
            required
          />

          <InputField
            label="Email"
            name="email"
            placeholder="Email"
            value={candidateDetails.email}
            onChange={handleInputChange}
            mandatory={true}
            error={errors.email} 
            required
          />

          <InputField
            label="Phone"
            name="phone"
            placeholder="Phone"
            value={candidateDetails.phone}
            onChange={handleInputChange}
            mandatory={true}
            error={errors.phone} 
          />

          <div>
            <AadhaarInput
              value={candidateDetails.aadhaarNumber}
              onChange={(value) => handleInputChange({ target: { name: 'aadhaarNumber', value } })}
              mandatory={true}
              error={errors.aadhaarNumber} 
              required
            />

            <InputField
              label="PAN Number"
              name="panNumber"
              placeholder="PAN Number"
              value={candidateDetails.panNumber}
              onChange={handleInputChange}
              error={errors.panNumber} 
              mandatory={true}
            />
          </div>
        </div>
        <div className="ms-3 fw-bold fs-4">Address Details</div>
        <div className="p-4">
          <InputField
            label="Present Address"
            name="presentAddress"
            placeholder="Present Address"
            value={candidateDetails.presentAddress}
            onChange={handleInputChange}
          />

          <InputField
            name="presentState"
            placeholder="Present State"
            value={candidateDetails.presentState}
            onChange={handleInputChange}
          />

          <InputField
            name="presentPostalCode"
            placeholder="Present Postal Code"
            value={candidateDetails.presentPostalCode}
            onChange={handleInputChange}
          />

        </div>
        <div className="p-4">
          <div className="row mb-">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="permanentAddress" className="form-label font-sub-title mb-0">Permanent Address</label>
            </div>
            <div className="col-lg-6">
              <div className="d-flex">
                <input className='' type="checkbox" name="permanentAddress" placeholder='Permanent Address' checked={checked} onChange={handleCheckChange} />
                <p className='mb-0 ps-2'>Same as present Address</p>
              </div>
            </div>
          </div>
            <InputField
              name="permanentAddress"
              placeholder="Permanent Address"
              value={checked ? candidateDetails.presentAddress : candidateDetails.permanentAddress} 
              onChange={handleInputChange}
            />

            <InputField
              name="permanentState"
              placeholder="Permanent State"
              value={checked ? candidateDetails.presentState : candidateDetails.permanentState} 
              onChange={handleInputChange}
            />

            <InputField
              name="permanentPostalCode"
              placeholder="Permanent Postal Code"
              value={checked ? candidateDetails.presentPostalCode : candidateDetails.permanentPostalCode} 
              onChange={handleInputChange}
            />

        </div>

        <div className="ms-3 fw-bold fs-4">Professional Details</div>
        <div className="p-4 row row-cols-lg-2 row-cols-md-2">

          <InputField
            label="Experience"
            name="experience"
            placeholder="Experience"
            value={candidateDetails.experience}
            onChange={handleInputChange}
          />

          {/* <InputField
            label="Source Of Hire"
            name="sourceOfHire"
            placeholder="Source Of Hire"
            value={candidateDetails.sourceOfHire}
            onChange={handleInputChange}
          /> */}
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="sourceOfHire" className="form-label font-sub-title mb-0">Source Of Hire</label>
            </div>
            <div className="col-lg-8">
              <select className='form-control-announce bg-white' name="sourceOfHire" id="sourceOfHire" value={candidateDetails.sourceOfHire} onChange={handleInputChange}>
                <option value="">Please select</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Naukri">Naukri</option>
                <option value="Reference">Reference</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <InputField
            label="Highest Qualification"
            name="highestQualification"
            placeholder="Highest Qualification"
            value={candidateDetails.highestQualification}
            onChange={handleInputChange}
          />

          <InputField
            label="Location"
            name="location"
            placeholder="Location"
            value={candidateDetails.location}
            onChange={handleInputChange}
          />
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="currentSalary" className="form-label font-sub-title mb-0">Department</label>
            </div>
            <div className="col-lg-7">
              <select className='form-control-announce bg-white cursor-pointer' value={candidateDetails.department} name="department" id="department" onChange={handleInputChange} required>
                <option value="">Please select</option> 
                <option value="HR Management">HR Management</option>
                <option value="Top Level Management">Top Level Management</option>
                <option value="Quality">Quality</option>
                <option value="Project Management">Project Management</option>
                <option value="Full Stack Developer">Full Stack Developer</option>
                <option value="Frontend Developer">Frontend Developer</option>
                <option value="Backend Developer">Backend Developer</option>
                <option value="DevOps">DevOps</option>
                <option value="Admin">Admin</option>
                <option value="Developer">Developer</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="department" className="form-label font-sub-title mb-0">Designation</label>
            </div>

            <div className="col-lg-8">
              <select className='form-control-announce bg-white cursor-pointer' value={candidateDetails.currentSalary} name="currentSalary" id="currentSalary" onChange={handleInputChange} required>
                <option value="">Please select</option> 
                <option value="HR Manager">HR Manager</option>
                <option value="Director">Director</option>
                <option value="Quality/Tester">Quality/Tester</option>
                <option value="Project Manager">Project Manager</option>
                <option value="Junior DevOps">Junior DevOps</option>
                <option value="Senior Software Engineer">Senior Software Engineer</option>
                <option value="Serior DevOps">Serior DevOps</option>
                <option value="Senior Solution Architect">Senior Solution Architect</option>
                <option value="Solution Architect">Solution Architect</option>
                <option value="Software Engineer">Software Engineer</option>
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-3 d-flex align-items-center">
              <label htmlFor="skillSet" className="form-label font-sub-title mb-0">Skill Set</label>
            </div>
            <div className="col-lg-8 d-flex align-items-center">
              <textarea className='form-control-announce' type="text" name="skillSet" placeholder='Skill Set' value={candidateDetails.skillSet} onChange={handleInputChange} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-3 mt-1 d-flex align-items-start">
              <label htmlFor="employeeType" className="form-label font-sub-title mb-0">Employee Type</label>
            </div>
            <div className="col-lg-8 d-flex align-items-start">
              <select className='form-control-announce bg-white' value={candidateDetails.employeeType} name="employeeType" id="employeeType" onChange={handleInputChange} required>
                <option value="">Please select</option> 
                <option value="Permanent Employee">Permanent Employee</option>
                <option value="Contractual Employee">Contractual Employee</option>
              </select>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="ms-3 fw-bold fs-4">Work Experience</div>
          <div className='btn border' onClick={addRow}><IoAdd /> Add Row</div>
        </div>
        <div className='table-responsive border border-dark'>
          <table className='border-0'>
            <thead>
              <tr>
                <td>Company Name</td>
                <td>Job Title</td>
                <td>From Date</td>
                <td>To Date</td>
                <td>Job Description</td>
                <td>Relevant</td>
              </tr>
            </thead>
            <tbody>
            { workExperience.length === 0 ? (
              <>
                <div className="alert alert-light">
                  <div className="">
                    Click on add button to add work experiences
                  </div>
                </div>
              </>
            ) : (
              workExperience.map((experience, index) => (
                <tr key={index}>
                  <td><input type="text" name="companyName" className='form-control-announce' value={experience.companyName} onChange={(e) => handleWorkChange(e, index)} /></td>
                  <td><input type="text" name="jobTitle" className='form-control-announce' value={experience.jobTitle} onChange={(e) => handleWorkChange(e, index)} /></td>
                  <td><input type="date" name="fromDate" className='form-control-announce' value={experience.fromDate} onChange={(e) => handleWorkChange(e, index)} /></td>
                  <td><input type="date" name="toDate" className='form-control-announce' value={experience.toDate} onChange={(e) => handleWorkChange(e, index)} /></td>
                  <td><textarea name="jobDescription" className='form-control-announce' value={experience.jobDescription} onChange={(e) => handleWorkChange(e, index)} cols="30" rows="1"></textarea></td>
                  <td>
                    <select name="relevant" className='form-control-announce' value={experience.relevant} onChange={(e) => handleWorkChange(e, index)}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </td>
                  <td><div className='text-danger cursor-pointer' onClick={() => removeRow(index) }><FaMinus /></div></td>
                </tr>
              ))
            )}
            </tbody>
          </table>
        </div>
        <div className="ms-3 mt-4 fw-bold fs-4">Onboard Employee</div>
        <div className="p-4">
          <div className="row mb-3">
            <div className="col-lg-2 d-flex align-items-center">
              <label htmlFor="tentativeJoiningDate" className="form-label font-sub-title mb-0">Tentative Joining Date</label>
            </div>
            <div className="col-lg-6">
              <DatePicker
                selected={candidateDetails.tentativeJoiningDate}
                onChange={date => handleDateChange(date)}
                className="form-control-announce cursor-pointer"
                name="tentativeJoiningDate"
                placeholderText="Tentative Joining Date"
                dateFormat="yyyy-MM-dd"
                required
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
        </div>

          <button type="submit" className='btn btn-button' disabled={loading}>
            {loading ? (
                <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="">Sending...</span>
                </>
            ) : (
                <span>Submit</span>
            )}
          </button>
      </form>
    </>
  );
}

export default CandidateForm;
